export const locale = {
    lang: 'pt',
    data: {
        'GENERAL': {
            'TITLE' : 'Título',
            'GENERAL' : 'Geral',
            'REFRESH' : 'Atualizar',
            'SAVE' : 'Salvar',
            'CONFIRM' : 'Confirmar',
            'CANCEL' : 'Cancelar',
            'ALL' : 'Todos',
            'SELECT-ALL' : 'Selecionar todas',
            'DESELECT-ALL' : 'Desselecionar todas',
            'SELECTED' : 'Selecionada(s)',
            'BACK' : 'Voltar',
            'NAME' : 'Nome',
            'DESCRIPTION' : 'Descrição',
            'PHONE' : 'Telefone',
            'EMAIL' : 'E-mail',
            'STREET' : 'Rua',
            'NUMBER' : 'Número',
            'COMPLEMENT' : 'Complemento',
            'REMOVE' : 'Remover',
            'EDIT' : 'Editar',
            'CREATE' : 'Cadastrar',
            'DELETE-CONFIRMATION' : 'Deseja remover este registro ?',
            'CLOSE' : 'Fechar',
            'GUID' : 'ID',
            'UNEXPECTED_ERROR' : 'Erro inesperado. Favor contacte o suporte',
            'SELECT' : 'Selecionar',
            'ADD' : 'Adicionar',
            'ROLE' : 'Perfil',
            'PASSWORD' : 'Senha',
            'USER' : 'Usuário',
            'MENU' : 'Menu',
            'MANAGE-ACTIONS' : 'Gerenciar Ações',
            'ACCESS-DENIED' : 'Acesso Negado',
            'POSTAL-CODE' : 'CEP',
            'POSTALCODE' : 'CEP',
            'SELECT-FILE' : 'Selecionar Imagem',
            'CITY' : 'Cidade',
            'STATE' : 'Estado',
            'METER-SUFIX' : ' m²',
            'METER-AREA-MASK' : '000,00',
            'VALUE-PREFIX' : 'RS ',
            'VALUE-MASK' : 'comma_separator.2',
            'PHONE-PREFIX' : '+55 ',
            'PHONE-MASK' : '(00) 0000-0000',
            'CEL-PHONE-MASK' : '(00) 0 0000-0000',
            'PRIMARY-DOCUMENT-MASK' : '000.000.000-00',
            'COMPANY-DOCUMENT-MASK' : '00.000.000/0000-00',
            'FILTER' : 'Filtrar',
            'SUPPORTED-FORMATS': 'Formatos suportados',
            'SUPPORTED-FORMATS-SMALL': 'Formatos',
            'MY-PROFILE' : 'Perfil',
            'LOGOUT' : 'Sair',
            'INVALID-EXTENSION' : 'Extensão do Arquivo inválida',
            'DOWNLOAD' : 'Download',
            'SEND' : 'Enviar',
            'SEND-BUTTON' : 'ENVIAR',
            'UNITYOFFER' : 'Proposta',
            'UNITYOFFERSTATUS' : 'Status da Proposta',
            'SAVE-BUTTON' : 'Salvar',
            'NEW' : 'Novo',
            'EDIT-BUTTON' : 'EDITAR',
            'ADD-BUTTON' : 'ADICIONAR',
            'BIRTH-DATE' : 'Nascimento',
            'TODAY' : 'Hoje',
            'WEEK' : 'Semana',
            'MONTH' : 'Mês',
            'MONTHS' : 'Meses',
            'MILEAGE-MENU' : 'Programa de Milhas',
            'LEAD-MENU' : 'Incluir Cliente',
            'STARTDATE' : 'Data de Início',
            'DATES' : 'Datas',
            'DATE' : 'Data',
            'BALANCE' : 'Saldo',
            'YES' : 'Sim',
            'NO' : 'Não',
            'MORE' : 'Ver mais',
            'PRODUCT' : 'Produto',
            'PRIMARYDOCUMENT' : 'CPF',
            'UNITYTYPE' : 'Tipo de Unidade',
            'COMPANY': 'Imobiliária',
            'TOWER' : 'Torre',
            'FLOOR' : 'Pavimento',
            'UNITY' : 'Unidade',
            'ROLEREQUEST': 'Perfil',
            'MODULE' : 'Módulo',
            'BAD-CREDENTIALS': 'Usuário ou senha incorretos',
            'SEND-EMAIL' : 'Reenviar email de confirmação',
            'SEND-EMAIL-CONFIRMATION' : 'Deseja reenviar o email de confirmação?',
            'REACTIVATE': 'Reativar usuario',
            'REACTIVATE-CONFIRMATION' : 'Deseja reativar este usuário?',
            'OBS' : 'Observação',
            'SEARCH' : 'Pesquisar',
            'EMAIL-TOOLTIP' : 'Ao alterar este campo, você receberá em seu novo email um link para confirmação.',
            'OTHER' : 'Outro',
            'OTHERS' : 'Outros',
            'ORDER-BY' : 'Ordenação',
            'SHOW-COLUMNS' : 'Visualizar',
            'COMMISSION-TOOLTIP' : 'Este valor representa uma estimativa da comissão que será calculada no momento da venda.',
            'TOTAL' : 'Total',
            'RECONCILEDVALUE' : 'Valor a Conciliar',
            'BANKACCOUNT' : 'Conta Bancária',
            'OUTCOMINGCATEGORY' : 'Categoria',
            'SELL' : 'Venda',
            'INITIALVALUE' : 'Valor de Entrada',
            'PERSONALIZATION' : 'Personalização',
            'VIEW-COLUMNS' : 'Visualizar',
            'ACTIONS' : 'Ações',
            'FILE' : 'Arquivo',
            'OUTCOMINGPAYMENT' : 'Pagamento',
            'PARTICIPATION' : 'Participação',
            'BANK_LOAN' : 'Empréstimo',
            'NO-REGISTRIES-FOUND' : 'Nenhum Registro Encontrado',
            'DOCUMENT-SAVE-CONFIRMATION' : 'Deseja Salvar Este Documento?',
            'CUSTOMER' : 'Cliente',
            'SHARE' : 'Compartilhar',
            'UNAVAILABLE' : 'Indisponível',
            'HISTORY' : 'Histórico',
            'VIEW' : 'Visualizar',
            'SEARCH-BUTTON' : 'Buscar',
            'FILTERS' : 'Filtros',
            'LEAD' : 'Lead',
            'CLEAR-FILTER' : 'Limpar Filtros',
            'INPUTCODE' : 'Código do Insumo',
            'COMPOSITIONCODE' : 'Código da Composição',
            'ENGINEERINGINPUT' : 'Insumo',
            'ENGINEERINGCOMPOSITION' : 'Composição',
            'SCROLL-BOTTOM' : 'Rolar até o fim',
            'SKIP' : 'Pular',
            'NEXT' : 'Próximo',
            'PRINT' : 'Imprimir',
            'ENGINEERINGACTIVITY' : 'Atividade',
            'COMPANYIDENTIFIER' : 'CNPJ',
            'ENGINEERINGSERVICE' : 'Serviço',
            'SUPPLYINVOICE' : 'Nota Fiscal',
            'SUPPLIER' : 'Fornecedor',
            'DEVELOPMENTFINANCINGINDEXERTYPE' : 'Tipo do Cálculo de Índices de Correção',
            'ENGINEERINGBUDGETING' : 'Orçamento',
            'MEDIAFOLDER' : 'Diretório',
            'ENGINEERINGSERVICEGROUP' : 'Etapa',
            'SUPPLYLABORORDERMETERING' : 'Medição',
            'DEVELOPMENT' : 'Empreendimento',
            'IDENTIFIER' : 'Identificador',
            'ENGINEERINGBULLETIN' : 'Medição'
        },
        'INTEG' : {
            'TERM' : {
                'TITLE' : 'Termo de Uso',
                'ACCEPTANCE-TITLE' : 'Termo de Uso e Política de Privacidade',
                'ACCEPT-TERM' : 'Aceitar',
                'DECLINE-TERM' : 'Declinar',
                'USER-ACCEPTED-TERM' : 'Bem-vindo à Integ!',
                'USER-DECLINED-TERM' : 'É necessário aceitar o termo de uso para acessar a Plataforma',
            }
        },
        'ACCOUNTING-COST-CENTER' : {
            'ACCOUNTING-COST-CENTER' : 'Centro de Custos'
        },
        'ACCOUNTING' : {
            'ACCOUNTING-CODE' : 'Código Contábil'
        },
        'GENERAL-EDITOR' : {
            'TITLE' : 'Conteúdo'
        },
        'DURATION-TYPE' : {
            'DAILY' : 'Dias',
            'WEEKLY' : 'Semanas',
            'MONTHLY' : 'Meses'
        },

        'LEAD' : {
            'UPDATED' : 'Dados atualizados com sucesso',
            'REFRESH' : 'Atualizar',
            'HISTORY-DATE' : 'Data Status',
            'VIEW-UNITY' : 'Ir para Unidade',
            'DEVELOPMENT' : 'Empreendimento',
            'DISREGARD-DATE' : 'Desconsiderado',
            'DISREGARDED-BY' : 'Usuário',
            'DISREGARD-REASON' : 'Motivo',
            'ACCEPT-LEAD' : 'Aceito Corretor',
            'NOTE-CREATED' : 'Nota criada com sucesso',
            'CREATE-NOTE-DIALOG-TITLE' : 'Nova Nota',
            'HISTORY' : 'Histórico',
            'NOTE' : 'Nota',
            'NOTES' : 'Notas',
            'HISTORY-AND-NOTES' : 'Histórico',
            'EDIT' : 'Editar',
            'REDISTRIBUTE' : 'Redistribuir Cliente',
            'DISTRIBUTE' : 'Distribuir Cliente',
            'STANDBY-DIALOG-TITLE' : 'Atendimento Futuro',
            'DECLINE-DIALOG-TITLE' : 'Rejeitar Atendimento',
            'DISREGARD-DIALOG-TITLE' : 'Descartar Cliente',
            'SUCCESSFULLY-DISREGARDED' : 'Cliente descartado com sucesso',
            'STANDBY-SUCCESS' : 'Cliente enviado para Atendimento Futuro com sucesso',
            'LEAD-DECLINED' : 'Cliente rejeitado com sucesso',
            'RECEIVING-DATE' : 'Data Recebimento',
            'NEXT-ATTENDANCE-DATE' : 'Próximo Atendimento',
            'ACTIONS-MENU' : 'Ações',
            'DISREGARD-MENU' : 'Descartar Cliente',
            'STANDBY-MENU' : 'Atendimento Futuro',
            'ADDITIONAL-INFORMATION' : 'Informação Adicional',
            'NO-ATTENDANCE-LEADS' : 'Nenhum cliente em atendimento',
            'VIEW-DISTRIBUTION' : 'Ver mais',
            'VIEW-ATTENDANCE' : 'Ver mais',
            'LEAD-FORM-DIALOG-TITLE' : 'Inclusão de Cliente',
            'LEAD-FORM-UPDATE-DIALOG-TITLE' : 'Alteração de Dados',
            'LEAD-DISTRIBUTE-FORM-DIALOG-TITLE' : 'Distribuição de Cliente',
            'NAME' : 'Nome',
            'PRIMARY-DOCUMENT' : 'CPF',
            'PHONE' : 'Telefone',
            'EMAIL' : 'E-mail',
            'ENTRY-METHOD' : 'Entrada',
            'COMMENT' : 'Comentários adicionais',
            'INCLUDED' : 'Cliente incluído com sucesso',
            'MANAGEMENT-TITLE' : 'Gestão de Clientes',
            'MANAGEMENT-SUBTITLE' : 'Distribuição e Gerenciamento',
            'GENERAL' : 'Geral',
            'DISTRIBUTION' : 'Distribuição',
            'CONTROL' : 'Em Atendimento',
            'FUTURE' : 'Atendimento Futuro',
            'DISREGARDED' : 'Desconsiderados',
            'ALL' : 'Todos',
            'FUNNEL' : 'Funil de Vendas',
            'WAITING' : 'Aguardando',
            'ATTENDANCE' : 'Atendimento',
            'CLIENTS' : 'Clientes',
            'FUNNEL-WAITING-LEGEND' : 'Na Fila',
            'FUNNEL-ATTENDANCE-LEGEND' : 'Em Atendimento',
            'FUNNEL-RESERVED-LEGEND' : 'Reservas',
            'FUNNEL-NEGOTIATING-LEGEND' : 'Negociação',
            'FUNNEL-HIRING-LEGEND' : 'Contratação',
            'FUNNEL-SOLD-LEGEND' : 'Vendidos',
            'LAST-LEADS-TITLE' : 'Fila para Distribuição',
            'IN-ATTENDANCE' : 'Em Atendimento',
            'TODAY-TAB' : 'Hoje',
            'WEEK-TAB' : 'Semana',
            'MONTH-TAB' : 'Mês',
            'YEAR-TAB' : 'Ano',
            'LEADS' : 'Clientes',
            'LIFETIME-LEADS-SUBTITLE' : 'Clientes do empreendimento',
            'IN-THIS-MONTH' : 'No mês',
            'CREATION-DATE' : 'Data Inclusão',
            'STATUS' : 'Status',
            'DISTRIBUTED' : 'Cliente distribuído com sucesso',
            'ACCEPTED' : 'Cliente aceito com sucesso',
            'DECLINED' : 'Cliente rejeitado com sucesso',
            'REALTOR' : 'Corretor',
            'COMPANY' : 'Imobiliária',
            'ATTENDANCE-DATE' : 'Início Atendimento',
            'TYPOLOGY' : 'Tipologia',
            'ADDITIONAL-INFORMATION-PLACEHOLDER' : 'Informação Adicional',
            'NO-PHONE-NOR-EMAIL' : 'Obrigatório preencher Telefone ou E-mail',
            'REALTOR-MANDATORY' : 'Seleciona um corretor',
            'ESTIMATED-VALUE' : 'Valor aproximado',
            'DISTRIBUTION-DATE' : 'Distribuição',
            'ACCEPT' : 'Aceitar',
            'DECLINE' : 'Rejeitar',
            'PENDENT-ACCEPTANCE' : 'Aceitação Pendente',
            'DISREGARD' : 'Descartar',
            'DATE' : 'Data',
            'USER' : 'Usuário',
            'UNITY' : 'Unidade'
        },
        'SHARE' : {
            'EMAIL-REQUIRED' : 'Preencha o e-mail para envio',
            'DEVELOPMENT-CATALOG' : 'Tabela',
            'UNITY-INCOMING' : 'Recebimentos',
            'OFFER' : 'Proposta'
        },
        'TYPOLOGY' : {
            'ONE-BEDROOM' : 'Um dormitório',
            'TWO-BEDROOMS' : 'Dois dormitórios',
            'ONE-SUITE-ONE-BEDROOM' : 'Uma suíte e um dormitório',
            'TWO-SUITES' : 'Duas suítes',
            'ONE-SUITE-TWO-BEDROOMS' : 'Uma suíte e dois dormitórios',
            'TWO-SUITES-ONE-BEDROOM' : 'Duas suítes e um dormitório',
            'THREE-SUITES' : 'Três suítes',
            'FOUR-BEDROOMS' : 'Quatro dormitórios'
        },
        'CUSTOMER-ENTRY-METHOD' : {
            'FACEBOOK' : 'Facebook',
            'INSTAGRAM' : 'Instagram',
            'GOOGLE' : 'Google',
            'OUTDOOR' : 'Outdoor',
            'JOURNAL' : 'Jornal',
            'FLYER' : 'Panfleto',
            'BOOTH' : 'Estande',
            'REALTOR' : 'Indicação Corretor',
            'FAIRGROUND' : 'Feiras',
            'ON-DUTY' : 'Plantão',
            'HEAD-OFFICE' : 'Sede',
            'WHATSAPP' : 'WhatsApp',
            'WEBSITE' : 'Site'
        },
        'LEAD-INTEREST' : {
            'LOCATION' : 'Localização',
            'RECREATION-AREA' : 'Área de Lazer',
            'POOL' : 'Piscina',
            'FINANCING' : 'Financiamento'
        },
        'INCOMING-RANGE' : {
            'THREE-TO-FIVE' : 'Entre R$3.000 e R$5.000',
            'FIVE-TO-SEVEN' : 'Entre R$5.001 e R$7.000',
            'SEVEN-TO-TEN' : 'Entre R$7.001 e R$10.000',
            'ABOVE-TEN' : 'Acima de R$10.000'
        },
        'CUSTOMER-LOG-TYPE' : {
            'ASSIGNMENT' : 'Cessão de Direitos de Imóvel',
            'STAND-BY-REACTIVATION' : 'Atendimento Futuro Reativado',
            'NOTE' : 'Nota',
            'CONTACT' : 'Contato',
            'FORM-SENT' : 'Formulário Enviado',
            'FORM-VIEWED' : 'Formulário Visualizado',
            'FORM-FULFILLED' : 'Formulário Preenchido',
            'CONTACT-DISREGARDED' : 'Desqualificado',
            'INTERESTED' : 'Novo Interesse',
            'ATTENDANCE' : 'Enviado para Atendimento',
            'ACCEPTED' : 'Atendimento Aceito',
            'DECLINED' : 'Atendimento Rejeitado',
            'DISREGARD-PENDENT' : 'Enviado para Inativação',
            'DISQUALIFIED' : 'Inativado',
            'ATTENDANCE-SCHEDULED' : 'Visita Agendada',
            'ATTENDANCE-RESCHEDULED' : 'Visita Reagendada',
            'ATTENDANCE-REALIZED' : 'Visita Realizada',
            'ATTENDANCE-NOT-REALIZED' : 'Visita Cancelada',
            'ATTENDANCE-COMPLETED' : 'Atendimento Realizado',
            'STAND-BY' : 'Atendimento Futuro',
            'RESERVATION' : 'Nova Reserva',
            'RESERVATION-CANCEL' : 'Reserva Cancelada',
            'RESERVATION-EXPIRED' : 'Reserva Expirada',
            'NEGOTIATION' : 'Em Negociação',
            'NEGOTIATION-CANCEL' : 'Negociação Cancelada',
            'HIRING' : 'Em Contratação',
            'HIRING-CANCEL' : 'Contratação Cancelada',
            'SOLD' : 'Venda Concluída',
            'SELL-CANCEL' : 'Venda Distratada',
            'SELL-REMOVE' : 'Venda Cancelada'
        },
        'LEAD-STATUS' : {
            'CONTACT' : 'Aguardando Contato',
            'CONTACT-DISREGARDED' : 'Desqualificado',
            'FORM-FULFILLED' : 'Formulário Preenchido',
            'WAITING' : 'Interessado',
            'ATTENDANCE' : 'Em Atendimento',
            'PENDENT' : 'Pendente',
            'STAND-BY' : 'Stand By',
            'DECLINED' : 'Rejeitado',
            'CLIENT' : 'Ativo',
            'DISREGARDED' : 'Descartado',
            'DISREGARD-PENDENT' : 'Descarte Pendente',
            'DUPLICATED' : 'Duplicado',
            'DISQUALIFIED' : 'Inativado'
        },
        'LEAD-SUBSTATUS-CATEGORY' : {
            'FINANCIAL' : 'Financeiro',
            'PRODUCT' : 'Produto Interesse',
            'CLIENT' : 'Cliente',
            'OTHER' : 'Outros'
        },
        'LEAD-SUBSTATUS' : {
            'ATTENDANCE-PENDENT' : 'Atendimento Pendente',
            'ATTENDANCE-SCHEDULED' : 'Visita Agendada',
            'ATTENDANCE-RESCHEDULED' : 'Visita Reagendada',
            'ATTENDANCE-REALIZED' : 'Visita Realizada',
            'OTHER' : 'Outro',
            'FORM-SENT' : 'Formulário Enviado',
            'FORM-FULFILLED' : 'Formulário Preenchido',
            'DUPLICATED' : 'Duplicado',
            'ALREADY-BOUGHT' : 'Já comprou',
            'CHANGED-JOB' : 'Mudou emprego',
            'CHANGED-CITY' : 'Mudou de cidade',
            'LOST-JOB' : 'Perdeu emprego',
            'WRONG-NUMBER' : 'Telefone Incorreto',
            'DID-NOT-ANSWER' : 'Não atende às ligações',
            'NOT-SEARCHING' : 'Não está procurando imóvel',
            'IS-SUPPLIER' : 'Contato de Fornecedor',
            'IS-EMPLOYEE' : 'Contato de RH',
            'NO-ENTRY-MONEY' : 'Sem recursos para ato',
            'NO-FINANCING-MONEY' : 'Sem recursos para financiamento',
            'ANOTHER-DEVELOPMENT' : 'Optou por outro imóvel',
            'ATTENDANCE-QUALITY' : 'Qualidade de Atendimento',
            'SOCIAL-IRREGULAR' : 'SERASA comprometido',
            'DID-NOT-FIND-UNITY' : 'Não encontrou o imóvel desejado'
        },
        'LEAD-ATTENDANCE-LOCATION' : {
            'DECORATED' : 'Decorado',
            'DEVELOPMENT' : 'Obra',
            'HEAD-OFFICE' : 'Escritório',
            'REAL-ESTATE' : 'Imobiliária'
        },
        'LEAD-ATTENDANCE-STATUS' : {
            'SCHEDULED' : 'Agendada',
            'RESCHEDULED' : 'Reagendada',
            'REALIZED' : 'Realizada',
            'NOT-REALIZED' : 'Cancelada'
        },
        'CUSTOMER-STATUS' : {
            'WAITING' : 'Na Fila',
            'ATTENDANCE' : 'Em Atendimento',
            'PENDENT' : 'Pendente',
            'STAND-BY' : 'Stand By',
            'DECLINED' : 'Rejeitado',
            'CLIENT' : 'Ativo',
            'DISREGARDED' : 'Descartado',
            'DISREGARD-PENDENT' : 'Descarte Pendente',
            'DUPLICATED' : 'Duplicado'
        },
        'CUSTOMER-SUBSTATUS' : {
            'OTHER' : 'Outro',
            'DUPLICATED' : 'Duplicado',
            'ALREADY-BOUGHT' : 'Já comprou',
            'CHANGED-JOB' : 'Mudou emprego',
            'CHANGED-CITY' : 'Mudou de cidade',
            'LOST-JOB' : 'Perdeu emprego'
        },
        'CARDS' : {
            'SALES-TITLE' : 'Vendas',
            'SALES-SUBTITLE' : 'Acesse o sistema de vendas',
            'MEDIA-TITLE' : 'Mídia',
            'MEDIA-SUBTITLE' : 'Acesse mídias do empreendimento',
            'DOCUMENTS-TITLE' : 'Documentos',
            'DOCUMENTS-SUBTITLE' : 'Acesse documentos do empreendimento',
            'MILEAGE-TITLE' : 'Programa de Recompensas',
            'MILEAGE-SUBTITLE' : 'Programa de Recompensas',
            'COMMISSIONS' : {
                'TITLE' : 'Comissões',
                'PREVIEW' : 'Previsto',
                'RECEIVED' : 'Recebido',
                'TOTAL' : 'Total',
                'NEGOTIATING' : 'Previsão',
                'SOLD' : 'Vendidas'
            }
        },
        'CUSTOMER' : {
            'CUSTOMER' : 'Cliente',
            'CUSTOMERS' :'Clientes',
            'SELECT-CUSTOMERS' : 'Selecione um ou mais cliente(s)',
            'PRIMARY-DOCUMENT' : 'CPF/CNPJ',
            'NAME' : 'Nome',
            'SURNAME' : 'Sobrenome',
            'INVALID-PRIMARY-DOCUMENT' : 'CPF/CNPJ Inválido!',
            'DOCUMENTS' : 'Documentos',
            'DOCUMENT-DOWNLOAD' : 'DOWNLOAD',
            'DOCUMENT-UPLOAD' : 'ENVIAR',
            'DOC-SUCCESSFULLY-UPLOADED' : 'Documento enviado com sucesso',
            'PHONE' : 'Telefone',
            'EMAIL' : 'E-mail',
            'NEW-CUSTOMER' : 'Novo Cliente',
            'SEARCH' : 'Procurar',
            'BASIC-INFO' : 'Básico',
            'SPOUSE' : 'Cônjuge',
            'COMPANY'  : 'Empresa',
            'POSTAL-CODE' : 'CEP',
            'SECONDARY-DOCUMENT' : 'RG',
            'SHIPPING-AGENCY' : 'Órgão Expedidor',
            'ADDRESS' : 'Endereço',
            'ADDRESS-NUMBER' : 'Número',
            'NEIGHBORHOOD' : 'Bairro',
            'SCHOOLING' : 'Escolaridade',
            'JOB' : 'Profissão',
            'PHONE-NUMBER' : 'Telefone',
            'MOBILE-NUMBER' : 'Celular',
            'CEL-PHONE-NUMBER' : 'Celular',
            'OTHER-PHONE-NUMBER' : 'Outro',
            'CONTACT-PERSON' : 'Pessoa p/ Recados',
            'MARITAL-STATUS' : 'Estado Cívil',
            'SPOUSE-NAME' : 'Nome',
            'SPOUSE-SURNAME' : 'Sobrenome',
            'SPOUSE-PRIMARY-DOCUMENT' : 'CPF',
            'SPOUSE-SECONDARY-DOCUMENT' : 'RG',
            'SPOUSE-JOB' : 'Profissão',
            'SPOUSE-PHONE-NUMBER' : 'Telefone',
            'SPOUSE-MOBILE-NUMBER' : 'Celular',
            'SPOUSE-OTHER-PHONE-NUMBER' : 'Outro',
            'SPOUSE-CONTACT-PERSON' : 'Pessoa p/ Recados',
            'SPOUSE-EMAIL' : 'E-mail',
            'COMPANY-ADDRESS' : 'Endereço',
            'COMPANY-JOB' : 'Profissão',
            'COMPANY-PHONE-NUMBER' : 'Telefone',
            'INCOME' : 'Renda Bruta',
            'OTHER-INFO' : 'Outras Informações',
            'BIRTH-DATE' : 'Nascimento',
            'CITY' : 'Cidade',
            'STATE' : 'Estado',
            'COUNTRY' : 'País',
            'CUSTOMER-DETAIL' : 'Detalhes do Cliente',
            'SAVED' : 'Usuário salvo com sucesso',
            'UPDATED' : 'Usuário atualizado com sucesso',
            'CREATION-DATE' : 'Data Inclusão',
            'CUSTOMER-STATUS' : 'Status',
            'UNITY' : 'Unidade',
            'FLOOR' : 'Pavimento',
            'TOWER' : 'Torre',
            'DEVELOPMENT' : 'Empreendimento',
            'STATUS' : 'Status',
            'UNITIES' : 'Unidades',
            'DEVELOPMENTS' : 'Interesses',
            'DEVELOPMENT-IN-CUSTOMER' : 'Interesses',
            'DEVELOPMENT-NOT-IN-CUSTOMER' : 'Disponíveis'
        },
        'BANK-STATEMENT-FINANCING-INSTALLMENT-TYPE' : {
            'BANK-STATEMENT-FINANCING-INSTALLMENT-TYPES' : 'Tipo Pagamento',
            'INITIAL-VALUE' : 'Valor Entrada',
            'KEYS' : 'Financiamento Chaves',
            'CONSTRUCTION-COMPANY' : 'Financiamento Construtora',
            'BANK-INSTALLMENT' : 'Financiamento Bancário'
        },
        'BANK-STATEMENT-TYPE' : {
            'CREDIT' : 'Crédito',
            'DEBIT' : 'Débito'
        },
        'BANK-STATEMENT' : {
            'ACTIVATE' : 'Reativar Lançamento',
            'REMOVE' : 'Desativar Lançamento',
            'BANK-STATEMENT' : 'Lançamento',
            'DESCRIPTION' : 'Descrição',
            'TRANSACTION-AMOUNT' : 'Valor',
            'ACCOUNT-BALANCE' : 'Saldo',
            'DATE-POSTED' : 'Data',
            'BANK-STATEMENT-TYPE' : 'Tipo',
            'IMPORT-DIALOG-TITLE' : 'Importar Extrato',
            'STATEMENT-FILE' : 'Arquivo',
            'IMPORT-STATEMENT' : 'Importar Extrato',
            'IMPORT' : 'Importar',
            'ATTACH-STATEMENT-FILE' : 'Selecionar Arquivo',
            'RECONCILE' : 'Concilicar',
            'RECONCILE-DIALOG-TITLE' : 'Conciliar Extrato',
            'SELECT-OUTCOMING-PAYMENT' : 'Selecione o Pagamento',
            'SELECT-BANK-STATEMENT' : 'Selecione o Registro',
            'SELECT-UNITY-HISTORY-COMMISSION' : 'Selecione a Comissão',
            'SELECT-BANK-LOAN' : 'Selecione o Empréstimo',
            'SELECT-BANK-INVESTMENT' : 'Selecione a Aplicação',
            'OUTCOMING-PAYMENT-VALUE' : 'Valor',
            'OUTCOMING-PAYMENT-TO-RECONCILE-VALUE' : 'Valor a Conciliar',
            'RECONCILE-BUTTON' : 'Conciliar',
            'SEARCH-BUTTON' : 'Filtrar',
            'NEW-BUTTON' : 'Novo Lançamento',
            'FINANCING-INSTALLMENT-TO-RECONCILE-VALUE' : 'Valor a Conciliar',
            'SUCCESSFULLY-RECONCILED' : 'Conciliação Efetuada com Sucesso',
            'FINANCING-INSTALLMENT-REQUIRED' : 'Selecione a Parcela',
            'FINANCING-INSTALLMENT-BANK-TYPE-REQUIRED' : 'Selecione Financiamento ou FGTS',
            'UNITY-HISTORY-COMMISSION-REQUIRED' : 'Selecione a Comissão',
            'DELETED' : 'Registro removido com sucesso',
            'DUPLICATE' : 'Registro Duplicado',
            'RECONCILED' : "Conciliado",
            'OPEN' : 'Não Conciliado',
            'DUPLICATE-CONFIRM' : 'Deseja marcar o registro como duplicado ? Esta ação não poderá ser desfeita',
            'ERROR' : {
                'DUPLICATE-SAME-STATEMENT' : 'Tentativa de duplicar  o mesmo registro bancário',
                'DUPLICATE-DIFFERENT-VALUE' : 'Soma dos registros selecionados devem totalizar o valor do Registro Duplicado',
                'BANK-TRANSFER-SAME-ACCOUNT' : 'Tentativa de transferir valor para mesma conta bancária',
                'BANK-TRANSFER-DIFFERENT-VALUE' : 'Registros devem possuir o mesmo valor',
                'CHARGEBACK-NOT-CREDIT' : 'É preciso selecionar um registro de crédito para vincular ao estorno',
                'CHARGEBACK-DIFFERENT-VALUE' : 'Registros devem possuir o mesmo valor',
                'CHARGEBACK-BANK-STATEMENT-HAS-RECONCILED-VALUES' : 'Registro não deve possuir conciliações',
                'BANK-CONTRACT-CODE-NOT-FOUND' : 'Código do Contrato não encontrado'
            }
        },
        'BANK-BILL-STATUS' : {
            'NOT-GENERATED' : 'Não Registrado',
            'GENERATED' : 'Aberto',
            'PAID' : 'Pago',
            'ENDED' : 'Cancelado',
            'EXPIRED' : 'Vencido'
        },
        'BANK-BILL' : {
            'BANK-BILL' : 'Boleto',
            'BANK-BILLS' : 'Boletos',
            'GENERATE' : 'Gerar',
            'BAR-CODE' : 'Código de Barras',
            'OUR-NUMBER' : 'Nosso Número',
            'BILL-VALUE' : 'Valor',
            'EMISSION-DATE' : 'Data Emissão',
            'URL' : 'URL',
            'BANK-BILL-STATUS' : 'Status',
            'EXPIRED-ACTION' : 'Ação Vencimento',
            'EXPIRATION-DATE' : 'Data Vencimento',
            'VALUE' : 'Valor',
            'END' : 'Cancelar',
            'END-CONFIRMATION' : 'Confirma a baixa deste boleto ?',
            'END-SUCCESS' : 'Baixa confirmada com sucesso',
            'CHECK-STATUS' : 'Verificar Pagamento'
        },
        'EXPIRED-ACTION' : {
            'PROTEST' : 'Protestar',
            'GIVE-BACK' : 'Devolver'
        },
        'BANK-ACCOUNT' : {
            'BANK-AGENCY' : 'Agência',
            'BANK-ACCOUNT' : 'Conta',
            'BANK' : 'Banco',
            'SEARCH' : 'Procurar conta bancária',
            'NEW-BUTTON' : 'Adicionar Conta',
            'DESCRIPTION' : 'Descrição',
            'INITIAL-BALANCE' : 'Saldo Inicial',
            'INITIAL-BALANCE-DATE' : 'Data Saldo Inicial',
            'INITIAL-RECONCILIATION-DATE' : 'Data Inicial Conciliação',
            'ACCOUNT-BALANCE' : 'Saldo Conta',
            'BANK-ASSIGNORS' : 'Beneficiários',
            'DELETED' : 'Conta bancária removida',
            'NO-BANK-ASSIGNORS' : 'Nenhum Beneficiário cadastrado para esta conta'
        },
        'BANK-ASSIGNOR' : {
            'BANK-ASSIGNOR' : 'Beneficiário',
            'BANK-ASSIGNORS' : 'Beneficiários',
            'BANK-ASSIGNOR-CODE' : 'Código Beneficiário',
            'DESCRIPTION' : 'Descrição',
            'PAYEE-NAME' : 'Razão Social',
            'PAYEE-DOCUMENT' : 'CNPJ',
            'MAX-EXPIRED-DAYS' : 'Dias para Protesto/Devolução',
            'MAX-EXPIRED-DAYS-TOOLTIP' : 'Quantidade de dias para o Protesto ou Baixa por devolução do título não pago após o vencimento',
            'SEARCH' : 'Procurar Beneficiário',
            'DELETED' : 'Beneficiário removido'
        },
        'OUTCOMING-CATEGORY' : {
            'OUTCOMING-CATEGORIES' : 'Categorias',
            'OUTCOMING-CATEGORY' : 'Categoria',
            'DESCRIPTION' : 'Descrição',
            'NEW-BUTTON' : 'Nova Categoria',
            'SEARCH' : 'Procurar Categoria',
            'PAYMENTS-ALLOWED' : 'Registra Pagamentos',
            'REPORT-ACTION-REQUIRED' : 'Ação necessária para Visualizar em Relatórios',
            'PREVISION-TITLE' : 'Previsão de Valores',
            'PREVISION' : 'Previsão',
            'PREVISION-HISTORY' : 'Histórico de Previsão',
            'PREVISION-DATE' : 'Data Cadastrado',
            'DELETE-CONFIRMATION' : 'Deseja remover esta Categoria ?',
            'CREATED' : 'Categoria adicionada com sucesso',
            'DELETED' : 'Categoria removida com sucesso',
            'UPDATED' : 'Categoria atualizaada com sucesso',
            'INVALID-CATEGORY-SELECTED' : 'Categoria inválida para a operação'
        },
        'BANK-STATEMENT-INCOMING-PAYMENT-TYPE' : {
            'DEVELOPMENT' : 'Empreendimento',
            'BANK-LOAN' : 'Empréstimo',
            'BANK-INVESTMENT' : 'Aplicação',
            'UNITY_HISTORY_EXCHANGE' : 'Permuta',
            'INCOMING-PAYMENT' : 'Outros Recebimentos'
        },
        'INCOMING-PAYMENT-STATUS' : {
            'ACTIVE' : 'Ativo',
            'APPROVED' : 'Validado',
            'PARTIALLY-RECONCILED' : 'Parcial',
            'RECONCILED' : 'Recebido',
            'UNFORESEEN' : 'Imprevisto',
            'INACTIVE' : 'Inativo'
        },
        'INCOMING-PAYMENT' : {
            'INCOMING-PAYMENTS' : 'Outros Recebimentos',
            'MARK-AS-PAID' : 'Marcar como Recebido',
            'APPROVE' : 'Validar',
            'REMOVE-APPROVAL' : 'Remover Validação',
            'INCOMING-PAYMENT' : 'Recebimento',
            'DESCRIPTION' : 'Descrição',
            'IDENTIFIER' : 'Nota Fiscal',
            'PAYMENT-VALUE' : 'Valor',
            'PAYMENT-DATE' : 'Data',
            'NEW-OCCURRENCE' : 'Adicionar Ocorrência',
            'OCCURRENCE' : 'Ocorrência',
            'OCCURRENCES' : 'Ocorrências',
            'OBS' : 'Informação Adicional',
            'SEARCH' : 'Procurar Recebimento',
            'BALANCE' : 'Saldo',
            'TRANSACTION-AMOUNT' : 'Valor Recebido',
            'TRANSACTION-DATE' : 'Data Recebimento',
            'REMOVE-RECONCILE' : 'Remover',
            'DISCONCERT-SUCCESS' : 'Concilicação removida',
            'MARK-AS-PAID-CONFIRMATION' : 'Deseja mudar o status para Recebido ?',
            'APPROVE-PAYMENT-CONFIRMATION' : 'Deseja validar este recebimento ?',
            'REMOVE-APPROVAL-CONFIRMATION' : 'Deseja remover a validação ?',
            'NEW-UNFORESEEN-PAYMENT' : 'Imprevisto',
            'INCOMING-PAYMENT-STATUS' : 'Status',
            'BANK-STATEMENT-REQUIRED' : 'Selecione o Registro em outra conta Bancária',
            'UNCHECK-AS-PAID' : 'Desmarcar Pagamento',
            'UNCHECK-AS-PAID-CONFIRMATION' : 'Deseja desmarcar Recebimento ?'
        },
        'OUTCOMING-PAYMENT-STATUS' : {
            'ACTIVE' : 'Não Validado',
            'APPROVED' : 'Validado',
            'PARTIALLY-RECONCILED' : 'Parcial',
            'RECONCILED' : 'Pago',
            'UNFORESEEN' : 'Imprevisto',
            'INACTIVE' : 'Inativo'
        },
        'BANK-STATEMENT-OUTCOMING-PAYMENT-TYPE' : {
            'OUTCOMING-PAYMENT' : 'Pagamento de Conta',
            'BANK-TRANSFER' : 'Transferência Entre Contas',
            'CHARGEBACK' : 'Estorno',
            'COMMISSION' : 'Comissão',
            'BANK-LOAN' : 'Empréstimo',
            'BANK-INVESTMENT' : 'Aplicação'
        },
        'OUTCOMING-PAYMENT' : {
            'MARK-AS-PAID' : 'Marcar como Paga',
            'APPROVE' : 'Validar',
            'REMOVE-APPROVAL' : 'Remover Validação',
            'OUTCOMING-PAYMENT' : 'Pagamento',
            'DESCRIPTION' : 'Descrição',
            'IDENTIFIER' : 'Nota Fiscal',
            'PAYMENT-VALUE' : 'Valor',
            'PAYMENT-DATE' : 'Data',
            'OUTCOMING-CATEGORY' : 'Categoria',
            'SELECT-OUTCOMING-CATEGORY' : 'Categoria',
            'SUPPLIER' : 'Fornecedor',
            'SELECT-SUPPLIER' : 'Fornecedor',
            'NEW-OCCURRENCE' : 'Adicionar Ocorrência',
            'OCCURRENCE' : 'Ocorrência',
            'OCCURRENCES' : 'Ocorrências',
            'OBS' : 'Informação Adicional',
            'SEARCH' : 'Procurar pagamento',
            'BALANCE' : 'Saldo',
            'TRANSACTION-AMOUNT' : 'Valor Pago',
            'TRANSACTION-DATE' : 'Data Pgto',
            'REMOVE-RECONCILE' : 'Remover',
            'DISCONCERT-SUCCESS' : 'Concilicação removida',
            'MARK-AS-PAID-CONFIRMATION' : 'Deseja mudar o status para Pago ?',
            'APPROVE-PAYMENT-CONFIRMATION' : 'Deseja validar este pagamento ?',
            'REMOVE-APPROVAL-CONFIRMATION' : 'Deseja remover a validação ?',
            'NEW-UNFORESEEN-PAYMENT' : 'Imprevisto',
            'PREVISION' : 'Previsão',
            'ADVANCE-PAYMENT' : 'Adiantamento',
            'OUTCOMING-CATEGORY-REQUIRED' : 'Categoria é obrigatória',
            'OUTCOMING-PAYMENT-TYPE' : 'Tipo Pagamento',
            'OUTCOMING-PAYMENT-STATUS' : 'Status',
            'OUTCOMING-RECONCILE-TYPE-FORM' : 'Conciliar',
            'OUTCOMING-RECONCILE-TYPE' : 'Tipo Conciliação',
            'UNITY-HISTORY-COMMISSION' : 'Comissão',
            'OUTCOMING-PAYMENT-REQUIRED' : 'Selecione o Pagamento',
            'UNITY-HISTORY-COMMISSION-REQUIRED' : 'Selecione a Comissão',
            'BANK-LOAN' : 'Empréstimo',
            'BANK-LOAN-REQUIRED' : 'Selecione o Empréstimo',
            'BANK-INVESTMENT' : 'Aplicação',
            'BANK-INVESTMENT-REQUIRED' : 'Selecione a Aplicação',
            'BANK-STATEMENT-REQUIRED' : 'Selecione o Registro em outra conta Bancária',
            'UNCHECK-AS-PAID' : 'Desmarcar Pagamento',
            'UNCHECK-AS-PAID-CONFIRMATION' : 'Deseja desmarcar Pagamento ?',
            'RECONCILED-VALUE' : 'Valor Conciliado ',
            'SAVE-SUCCESS' : 'Documento Salvo com Suceso'
        },
        'FINANCING-INSTALLMENT-BANK-TYPE' : {
            'FINANCING-INSTALLMENT-BANK-TYPE' : 'Referência',
            'BANK-VALUE' : 'Financiamento',
            'ADDITIONAL-VALUE' : 'FGTS'
        },
        'UNITY-HISTORY-COMMISSION' : {
            'UNITY-HISTORY-COMMISSION' : 'Comissão'
        },
        'ASSIGNOR' : {
            'ASSIGNOR' : 'Cedente',
            'ASSIGNORS' : 'Cedentes',
            'NAME' : 'Nome',
            'PHONE-NUMBER' : 'Telefone',
            'EMAIL' : 'E-mail',
            'PRIMARY-IDENTIFIER' : 'CPF/CNPJ',
            'MANAGE' : 'Sócios',
            'PERSON-TYPE' : 'Tipo',
            'SEARCH' : 'Procurar',
            'NEW-BUTTON' : 'Novo'
        },
        'BANK-LOAN-STATUS' : {
            'ACTIVE' : 'Ativo',
            'RESCUED' : 'Resgatado',
            'PARTIALLY-RECONCILED' : 'Parcialmente Recebido',
            'RECONCILED' : 'Recebido',
            'PARTIALLY-RETURNED' : 'Parcialmente Pago',
            'RETURNED' : 'Pago',
            'INACTIVE' : 'Inativo'
        },
        'BANK-LOAN' : {
            'BANK-LOAN' : 'Empréstimo',
            'BANK-LOANS' : 'Empréstimos',
            'NEW-BANK-LOAN' : 'Novo Empréstimo',
            'LOAN-DATE' : 'Data',
            'LOAN-VALUE' : 'Valor',
            'DESCRIPTION' : 'Descrição',
            'TRANSACTION-AMOUNT' : 'Valor Recebido',
            'TRANSACTION-DATE' : 'Data Recebimento',
            'RETURNED-AMOUNT' : 'Valor Pago',
            'RETURN-DATE' : 'Data Pagamento',
            'BALANCE' : 'Saldo',
            'ASSIGNOR' : 'Cedente',
            'INTEREST-RATE' : 'Juros',
            'INTEREST-TYPE' : 'Tipo',
            'STRINGENCY-DATE' : 'Carência',
            'ASSIGNOR-REQUIRED' : 'Selecione o Cedente',
            'SEARCH' : 'Procurar Empréstimo',
            'RECONCILED-VALUE' : 'Valor Conciliado',
            'DATE-POSTED' : 'Data',
            'BANK-STATEMENT' : 'Registro',
            'BANK-STATEMENTS' : 'Registros',
            'PERIOD-RECIVED-VALUE' : 'Valor Recebido Período',
            'PERIOD-RETURNED-VALUE' : 'Valor Pago Período',
            'BANK-LOAN-STATUS' : 'Status' 
        },
        'BANK-INVESTMENT' : {
            'BANK-INVESTMENT' : 'Aplicação',
            'BANK-INVESTMENTs' : 'Aplicações',
            'NEW-BANK-INVESTMENT' : 'Nova Aplicação',
            'INITIAL-DATE' : 'Data Inicial',
            'INITIAL-VALUE' : 'Valor Inicial Período',
            'VALUE' : 'Valor',
            'FINAL-VALUE' : 'Valor Final Período',
            'DESCRIPTION' : 'Descrição',
            'CURRENT-BALANCE' : 'Saldo Atual',
            'ASSIGNOR' : 'Cedente',
            'ASSIGNOR-REQUIRED' : 'Selecione o Cedente',
            'SEARCH' : 'Procurar Empréstimo',
            'RECONCILED-VALUE' : 'Valor Conciliado',
            'BANK-STATEMENT' : 'Registro',
            'BANK-STATEMENTS' : 'Registros',
            'DATE-POSTED' : 'Data',
            'FINAL-DATE' : 'Data Final',
            'CREATE-YIELD' : 'Adicionar Rendimento',
            'SHOW-YIELD' : 'Mostrar Rendimentos',
            'PERIOD-YIELD-VALUE' : 'Rendimentos Período',
            'YIELD' : 'Rendimento',
            'YIELD-REMOVED' : 'Rendimento Removido',
            'YIELD-SAVED' : 'Rendimento Salvo',
            'TOTAL-FINAL-VALUE' : "Valor Final Total",
            'TRANSACTIONS' : 'Movimentações'
        },
        'INTEREST-TYPE' : {
            'DAILY' : 'Diário',
            'MONTHLY' : 'Mensal'
        },
        'FINANCIAL' : {
            'HOME' : {
                'TITLE' : 'Financeiro',
                'ACCOUNTS-SELECT' : 'Contas Bancárias',
                'MANAGE-BANK-ACCOUNTS' : 'Gerenciar Contas',
                'DASHBOARD-TAB' : {
                    'TITLE' : 'Dashboard'
                },
                'STATEMENT-TAB' : {
                    'TITLE' : 'Extrato'
                }
            },
            'BANK-LOAN' : {
                'TITLE' : 'Empréstimos',
                'LOANS-TAB' : {
                    'TITLE' : 'Empréstimos'
                },
                'APPLICATIONS-TAB' : {
                    'TITLE' : 'Aplicações'
                }
            },
            'BANK-INVESTMENT' : {
                  'TITLE' : 'Aplicações'
            },
            'STATEMENT' : {
                'TITLE' : 'Extrato',
                'DASHBOARD-TAB' : {
                    'TITLE' : 'Extrato'
                },
                'IMPORT-TAB' : {
                    'TITLE' : 'Conciliação Bancária'
                },
                'FINANCING-INSTALLMENT-RECONCILE-DIALOG' : {
                    'INCOMING-PAYMENT-TYPE' : 'Tipo do Recebimento',
                    'FINANCING-INSTALLMENT-TYPE' : 'Tipo do Financiamento',
                    'SELECT-DEVELOPMENT' : 'Selecione o Empreendimento',
                    'SELECT-TOWER' : 'Selecione a Torre',
                    'SELECT-UNITY' : 'Selecione a Unidade',
                    'SELECT-FINANCING-INSTALLMENT-TYPE' : 'Selecione o Tipo do Financiamento',
                    'SELECT-FINANCING-INSTALLMENT' : 'Selecione o Financiamento',
                    'SELECT-BANK-LOAN' : 'Selecione o Empréstimo',
                    'SELECT-BANK-INVESTMENT' : 'Selecione a Aplicação',
                    'SELECT-UNITY-HISTORY-EXCHANGE' : 'Selecione a Permuta',
                    'SELECT-INCOMING-PAYMENT' : 'Selecione o Recebimento'
                },
                'OUTCOMING-PAYMENT-RECONCILE-DIALOG' : {
                    'TITLE' : 'Procurar Comissão',
                    'SELECT-UNITY-HISTORY-COMMISSION' : 'Selecione a Comissão'
                },
                'RECONCILED-PAYMENT' : {
                      'DATE' : 'Data Vencimento',
                      'DEVELOPMENT' : 'Empreendimento',
                      'TOWER' : 'Torre',
                      'UNITY' : 'Unidade',
                      'REALTOR' : 'Corretor',
                      'TYPE' : 'Tipo',
                      'DESCRIPTION' : 'Descrição',
                      'CATEGORY' : 'Categoria',
                      'SUPPLIER' : 'Fornecedor',
                      'CUSTOMER' : 'Cliente',
                      'OBS' : 'Observação',
                      'RECONCILED-VALUE' : 'Valor Conciliado'
                },
            },
            'INCOMING' : {
                'TITLE' : 'Contas a Receber',
                'NEW-PAYMENT' : 'Novo Recebimento',
                'INSTALLMENTS-TAB' : {
                    'TITLE' : 'Empreendimento'
                },
                'INCOMING-PAYMENTS-TAB' : {
                    'TITLE' : 'Outros'
                },
                'FINANCING-INSTALLMENT' : {
                    'EXPIRATION-DATE' : 'Vencimento',
                    'FINANCING-INSTALLMENT-STATUS' : 'Status',
                    'FINANCING-INSTALLMENT-PAYMENTS' : 'Pagamentos',
                    'PAID-VALUE' : 'Valor Pago',
                    'INSTALLMENT-VALUE' : 'Valor',
                    'CURRENT-VALUE' : 'Valor Atual',
                    'FINE-AND-INTEREST-VALUE' : 'Juros e Multa',
                    'FINE-VALUE' : 'Multa',
                    'INTEREST-VALUE' : 'Juros'
                },
            },
            'OUTCOMING' : {
                'TITLE' : 'Contas a Pagar',
                'MANAGE-CATEGORIES' : 'Categorias',
                'NEW-PAYMENT' : 'Novo Pagamento',
                'DASHBOARD-TAB' : {
                    'TITLE' : 'Pagamentos'
                },
                'COMMISSIONS-TAB' : {
                    'TITLE' : 'Comissões',
                    'COMMISSIONS-TABLE' : {
                        'UNITY-HISTORY-DATE' : 'Data Venda',
                        'UNITY-HISTORY-COMMISSION-VALUE' : 'Valor Comissão',
                        'USER-NAME' : 'Usuário',
                        'COMPANY-NAME' : 'Empresa',
                        'UNITY-DESCRIPTION' : 'Unidade',
                        'TOWER-DESCRIPTION' : 'Torre',
                        'UNITY-HISTORY-COMMISSION-RECONCILED-VALUE' : 'Valor Pago',
                        'UNITY-HISTORY-RECONCILED-VALUE' : 'Recebido da Venda',
                        'UNITY-HISTORY-COMMISSION-STATUS' : 'Status',
                        'VALUE-LEFT' : 'Valor Restante'
                    }
                }
            },
            'DEVELOPMENT-ASSET': {
                'TABLE': {
                    'ENTRY-DATE': 'Data de Entrada',
                    'SUPPLIER': 'Fornecedor',
                    'DOCUMENT-NUMBER': 'Nota Fiscal',
                    'DESCRIPTION': 'Descrição',
                    'ACTIONS': 'Ações',
                    'ATTACHMENT': 'Anexo',
                    'DEPRECIATION': 'Depreciação',
                    'VALUE_DEVELOPMENT_ASSET': 'Valor',
                    'ACTIVE': 'Ativo',
                    'INACTIVE': 'Inativo',
                    'TOTAL': 'Total',
                    'YEARLY': 'Anual',
                    'MONTHLY': 'Mensal',
                    'QUARTERLY': 'Trimestral',
                    'SEMESTRAL': 'Semestral',
                    'EVERY_TEN_YEARS': 'A cada 10 anos',
                    'SOLD': 'Vendido',
                    'VEHICLE': 'Veículo',
                    'PROPERTY': 'Propriedade',
                    'OTHER': 'Outro',
                    'SELL-DATE': 'Data da Venda',
                    'DEVELOPMENT_ASSET_STATUS': 'Status',
                    'PROX-DEPRECIACAO': 'Prox. Depreciação',
                    'DEVELOPMENT-ASSET-DEPRECIATION-PERIOD':'Período Depreciação',
                    'SELL-VALUE': 'Valor da Venda',
                    'DEVELOPMENT-ASSET-TYPE': {
                        'DEVELOPMENT_ASSET_TYPE_KEY':'Tipo de Ativo'
                    },
                    'BUTTONS': {
                        'EDIT': 'Editar',
                        'REMOVE': 'Remover',
                        'HISTORY': 'Histórico',
                        'SALE': 'Vender'
                    },
                    'HISTORIC': {
                        'DEPRECIATION-START-DATE': 'Inicio Depreciação',
                        'DEPRECIATION-END-DATE': 'Fim Depreciação',
                        'VALUE-BEFORE-DEPRECIATION': 'Valor Antes',
                        'VALUE-AFTER-DEPRECIATION': 'Valor Depois',
                        'DEPRECIATION-PERCENTAGE': 'Depreciação',
                        'DEVELOPMENT-ASSET-DEPRECIATION-PERIOD': 'Período Depreciação',
                        'DEVELOPMENT-ASSET': 'Imobilizado'
                    },
                    
                },
                'DEVELOPMENT-ASSET-STATUS': {
                    'ACTIVE': 'Ativado',
                    'INACTIVE': 'Inativo',
                    'SOLD': 'Vendido'
                },

                'TITLE-DIALOG': {
                    'DETAILS': 'Detalhes',
                    'EDIT': 'Editar',
                    'NEW': 'Adicionar',
                    'DEPRECIATION-HISTORIC': 'Histórico Depreciação',
                    'SOLD': 'Venda'
                },
                'BUTTON-SEARCH' : 'Buscar',
                'INPUT-SEARCH' : 'Pesquisar',
                'BUTTON-NEW': 'Nova Aquisição',
                'FORM': {
                    'DESCRIPTION': 'Descrição',
                    'SUPPLIER': 'Fornecedor',
                    'DOCUMENT-NUMBER': 'Nota Fiscal',
                    'ENTRY-DATE': 'Data de Entrada',
                    'DEPRECIATION': 'Depreciação',
                    'SELECT-DEVELOPMENT-ASSET-TYPE': 'Tipo de Ativo',
                    'SELECT-STATUS_DEVELOPMENT_ASSET': 'Status',
                    'SELECT-DEVELOPMENT_ASSET_DEPRECIATION_PERIOD': 'Período Depreciação',
                    'VALUE_DEVELOPMENT_ASSET': 'Valor',
                    'BUYER': 'Compradora',
                    'SELL-DATE': 'Data da Venda',
                    'SELL-VALUE': 'Valor da Venda',
                },
                'ACTIVATE-CONFIRMATION': 'Deseja ativar o registro?',
                'SUCCESSFULLY-ACTIVATE': 'Registro ativado com sucesso',
                'DELETE-CONFIRMATION': 'Deseja remover esse registro?',
                'SUCCESSFULLY-DELETED': 'Registro removido com sucesso!',
                'STATUS-DEVELOPMENT-ASSET': 'Status',
                'MAT-SELECT-STATUS-DEVELOPMENT-ASSET-ACTIVE': 'Ativo',
                'MAT-SELECT-STATUS-DEVELOPMENT-ASSET-DISABLED': 'Desativado',
                'ACTION-ACTIVATE-DEVELOPMENT-ASSET': 'Ativar'

            },
            'PROPERTY' : {
                'TITLE' : 'Meus Imobilizados',
                'EXCHANGED-UNITY-DIALOG' : 'Vincular Unidade',
                'LINK-UNITY' : 'VINCULAR',
                'SUCCESSFULLY-LINKED' : 'Vinculado com Sucesso',
                'SUCCESSFULLY-DELETED' : 'Desvinculado Com Sucesso',
                'DELETE-CONFIRMATION' : 'Deseja Desnvicular Está Unidade?',
                'TABS' : {
                    'EXCHANGE' : 'Permutas',
                    'ACQUISITION' : 'Imobilizados',
                },
                'INPUT-SEARCH' : 'Pesquisar',
                'BUTTON-SEARCH' : 'Buscar',
                'TABLE' : {
                    'HISTORY-DATE' : 'Data',
                    'DESCRIPTION' : 'Decrição',
                    'TYPE' : 'Tipo',
                    'EXCHANGE-VALUE' : 'Valor da permuta',
                    'STATUS' : 'Status',
                    'RECEIVED-VALUE' : 'Valor Recebido',
                    'RECEIVED-DATE' : 'Data Recebimento',
                    'UNITY' : 'Unidade',
                    'DEVELOPMENT' : 'Empreendimento',
                    'UNITY-STATUS' : 'Status Unidade'
                }
            },            
            'FINANCING-INSTALLMENT-STATUS' : {
                'STATUS-DATE-INPUT' : 'Data',
                'OBS-INPUT' : 'Observação',
                'PAYMENT-VALUE-INPUT' : 'Valor Pago',
                'CHANGE-STATUS-BUTTON' : 'Registrar',
                'SUCCESFULLY-CHANGED' : 'Registrado com sucesso',
                'FINANCING-INSTALLMENT-PAYMENT-TYPE' : 'Tipo Recebimento',
                'FINANCING-INSTALLMENT-PAYMENT-METHOD' : 'Forma Recebimento',
                'CHANGE-TITLE' : {
                    'ISSUED-BILL' : 'Boleto Emitido',
                    'PAID' : 'Registrar Recebimento'
                }
            }
        },
        'PROCESS': {
            'TITLE' : 'Processos',
            'NEW_SUBCATEGORY': 'Criar nova subcategoria',
            'ADD_NEW_CATEGORY': 'Adicionar Categoria',
            'DAY_SINGULAR': 'Dia',
            'DAY_PLURAL': 'Dias',
            'SINGULAR': 'Processo',
            'PLURAL': 'Processos',
            'ADD_GROUPING': 'Adicionar Agrupamento',
            'ADD_PROCESS': 'Adicionar Processo',
            'ADD_SUB_PROCESS': 'Adicionar SubProcesso',
        },
        'PLANNING': {
            'TITLE': 'Planejamento',
            'ACTIONS': 'Ações',
            'CREATE_MEASUREMENT': 'Criar medição',
            'SERVICES': 'Serviços',
            'NEW_SERVICE': 'Novo Serviço',
            'PERCENT_DONE': 'Realizado',
            'REGISTERED_SERVICES': 'Atividades cadastradas',
            'EMPTY_SERVICES_LIST': 'Não há serviços cadastrados',
            'FLOOR': 'Pavimento',
            'TOWER': 'Torre',
            'UNITY': 'Unidade',
            'SERVICE': {
                'INITIALS' : 'Sigla',
                'NAME': 'Nome',
                'MEASURE': 'Medida',
                'COLOR': 'Cor',
                'DESCRIPTION': 'Descrição'
            },
            'ENGINEERING-SERVICE-MEASURE' : {
                'TOWER' : 'Torre',
                'FLOOR' : 'Pavimento',
                'UNITY' : 'Unidade'
            },
            'ACTIVITY': {
                'SERVICE': 'Serviço',
                'SERVICE-PLACEHOLDER': 'Selecione um serviço',
                'RESOURCE-TOWER-PLACEHOLDER': 'Selecione uma ou mais torres',
                'RESOURCE-FLOOR-PLACEHOLDER': 'Selecione um ou mais pavimentos',
                'RESOURCE-UNITY-PLACEHOLDER': 'Selecione uma ou mais unidades',
                'TOWER': 'Torres',
                'FLOOR': 'Pavimentos',
                'UNITY': 'Unidades',
                'START_DATE': 'Data de início',
                'START_DATE_PLACEHOLDER': 'Selecione a Data de início',
                'END_DATE': 'Data final',
                'END_DATE_PLACEHOLDER': 'Selecione a Data de início',
                'DURATION': 'Duração (dias)',
                'DURATION_PLACEHOLDER': 'Selecione a duração',
                'CREATE': 'Criar Atividade',
                'EDIT': 'Editar Atividade',
                'INTERVAL': 'Intervalo',
                'INTERVAL_PLACEHOLDER': 'Selecione o intervalo entre as atividades',
                'DEPENDENCIES': 'Dependências'
            },
            'METERING': {
                'PLURAL': 'Medições',
                'CREATE': 'Criar medição',
                'EMPTY_LIST': 'Não há medições cadastradas'
            },
            'COLORS': {
                'RED': 'Vermelho',
                'PINK': 'Rosa',
                'PURPLE': 'Roxo',
                'VIOLET': 'Violeta',
                'INDIGO': 'Índigo',
                'BLUE': 'Azul',
                'CYAN': 'Cíano',
                'TEAL': 'Azul-esverdeado',
                'GREEN': 'Verde',
                'LIME': 'Lima',
                'YELLOW': 'Amarelo',
                'ORANGE': 'Laranja',
                'DEEP-ORANGE': 'Laranja profundo',
                'GRAY': 'Cinza'
            }
        },
        'UNITY-HISTORY-COMMISSION-STATUS' : {
            'OPEN' : 'Aberto',
            'PARTIALLY-RECONCILED' : 'Parcial',
            'RECONCILED' : 'Pago'
        },
        'FINANCING-INSTALLMENT-PAYMENT-TYPE' : {
            'PARTIAL' : 'Parcial',
            'TOTAL' : 'Total',
            'TOTAL-VALUE-REACHED' : 'Valor Total da parcela foi atingido, deseja colocar como Pagamento Total ?'
        },
        'FINANCING-INSTALLMENT-PAYMENT-METHOD' : {
            'ISSUED-BILL' : 'Boleto',
            'BANK-TRANSFER' : 'Transferência Bancária',
            'MONEY' : 'Espécie',
            'CREDIT-CARD' : 'Cartão de Crédito',
            'BANK-DRAFT' : 'Cheque'
        },
        'SALES' : {
            'HOME' : {
                'HEADER' : 'Status das unidades',
                'SUBHEADER' : 'Selecione uma unidade para ver detalhes',
            },
            'UNITY' : {
                'HEADER' : 'Gestão da unidade',
                'TIMELINE' : {
                    'HEADER' : 'Histórico'
                },
                'DOCUMENTS-BUTTON' : 'Documentos',
                'EVALUATE-OFFER' : 'Avaliar',
                'EDIT-OFFER' : 'Editar'
            },
            'RESERVATION-FORM' : {
                'SELECT-CUSTOMER-TITLE' : 'Dados do Cliente'
            },
            'INSTALLMENTS-EDIT' : {
                'BANK-FINANCED-VALUE' : 'Valor Financiado',
                'TOTAL-BANK-FINANCED-VALUE' : 'Valor Total Financiado',
                'BANK-EFFECTIVE-VALUE' : 'Valor Financiado',
                'BANK-ADDITIONAL-VALUE' : 'Valor FGTS',
                'BANK-EFFECTIVE-DATE' : 'Data Financiamento',
                'BANK-CONTRACT-CODE' : 'Código do Contrato',
                'FINANCING-INSTALLMENT-STATUS' : 'Status',
                'OCCURRENCE' : 'Nº',
                'INSTALLMENT-VALUE' : 'Valor',
                'EXPIRATION-DATE' : 'Vencimento',
                'CURRENT-VALUE' : 'Valor Atual',
                'FINANCING-INSTALLMENT-INDEXERS' : 'Índice(s)'
            },
            'SALES' : 'Vendas',
            'SALES-MODULES' : 'Selecione um Módulo para ver suas Torres',
            'SALES-TOWERS' : 'Selecione uma Torre para ver suas Unidades',
            'NO-TOWERS' : 'Não existem Torres neste Módulo',
            'SALES-UNITIES' : 'Selecione uma Unidade para ver detalhes',
            'NO-UNITIES' : 'Não existem Unidades neste Pavimento',
            'AVAILABLE' : 'Disponível',
            'RESERVE' : 'Reservar',
            'EXCHANGE' : 'Permutar',
            'RESERVE-BUTTON' : 'Reservar',
            'CONTRACT' : 'Contratação',
            'SELL' : 'Vender',
            'RESERVATION-TOOLTIP-PREFIX' : 'A unidade será reservada por ',
            'RESERVATION-TOOLTIP-SUFIX' : ' dias',
            'RESERVATION-CONFIRMATION' : 'Reserva efetuada com sucesso',
            'RESERVATION-CANCEL' : 'Cancelar Reserva',
            'RESERVATION-CANCEL-SMALL' : 'CANCELAR',
            'RESERVATION-CANCEL-CONFIRM' : 'Deseja cancelar esta reserva?',
            'RESERVATION-CANCELLED' : 'Reserva cancelada com sucesso',
            'CONTRACT-CONFIRM' : 'Deseja alterar o status para Em Contratação?',
            'CONTRACT-SUCCESS' : 'Unidade alterada para Em Contratação com sucesso',
            'CONTRACT-CANCEL' : 'Cancelar Contratação',
            'CONTRACT-CANCEL-CONFIRM' : 'Deseja cancelar esta contratação?',
            'CONTRACT-CANCELLED' : 'Contratação cancelada com sucesso',
            'SELL-VALUE' : 'Valor de Venda',
            'INITIAL-VALUE' : 'Valor de Entrada',
            'FINANCED-VALUE' : 'Valor Financiado Construtora',
            'BANK-FINANCED-VALUE' : 'Valor Financiado Banco',
            'WRONG-SELL-VALUES' : 'Valor de Venda deve ser igual a somatória dos demais',
            'SELL-CONFIRM' : 'Confirma os valores e o cliente da venda ?',
            'SOLD' : 'Unidade vendida com sucesso',
            'SELL-CANCEL' : 'Efetuar Distrato',
            'SELL-CANCEL-CONFIRM' : 'Deseja cancelar esta venda ?',
            'SELL-CANCELLED' : 'Distrato efetuado com sucesso',
            'SELL-CANCEL-RETURNED-VALUE' : 'Valor Devolvido',
            'COMPANY' : 'Empresa',
            'REALTOR' : 'Corretor',
            'REALTOR-REQUIRED' : 'Você deve selecionar um Corretor',
            'CONFIGURE-SELL-INSTALLMENTS' : 'Configurar Venda',
            'VIEW-SELL-INSTALLMENTS' : 'Detalhes da Venda',
            'INSTALLMENT-VALUE' : 'Valor Parcela',
            'VALUE' : 'Valor',
            'VALUE-LEFT' : 'Valor Restante',
            'EXPIRATION-DATE' : 'Vencimento',
            'INSTALLMENTS' : 'Parcelas',
            'INSTALLMENTS-EXCEED-TOTAL' : 'Valor das parcelas excede o total',
            'INSTALLMENTS-SAVED' : 'Financiamento configurado com sucesso',
            'INSTALLMENT-VALUE-EMPTY' : 'Preencha os valores da parcela',
            'HISTORY-INFO' : 'Informações',
            'RESERVATION-DATE' : 'Data da Reserva',
            'CONTRACT-DATE' : 'Em contratação desde',
            'SELL-DATE' : 'Data da Venda',
            'CUSTOMER-DOCUMENT' : 'CPF',
            'CUSTOMER-NAME' : 'Cliente',
            'CUSTOMER-SURNAME' : 'Sobrenome',
            'RESERVATION' : 'Reserva',
            'SELL-INFO' : 'Venda',
            'CUSTOMERS' : 'Clientes',
            'SEND-OFFER' : 'Proposta',
            'REGISTER-OFFER' : 'Registrar Proposta',
            'EXCHANGE-UNITY' : 'Permutar',
            'EXCHANGE-CONFIRM' : 'Deseja permutar a unidade ?',
            'UNITY-EXCHANGED' : 'Unidade permutada com sucesso',
            'SEND-OFFER-BUTTON' : 'PROPOSTA',
            'SEND-COUNTER-OFFER' : 'Enviar Contraproposta',
            'SELL-BUTTON' : 'Efetuar Venda',
            'VIEW-OFFER-BUTTON' : 'Proposta',
            'CUSTOMER-REQUIRED' : 'Selecione um ou mais cliente(s)',
            'CUSTOMER' : 'Cliente(s)',
            'ADD-CUSTOMER-TOOLTIP' : 'Clique para adicionar um novo ou digite no campo para pesquisar um existente',
            'EXISTENT-CUSTOMER' : 'Cliente já existe. Deseja incluí-lo na venda ?',
            'WAITING-DOCS-MENU' : 'Aguardando Docs',
            'EVALUATION-MENU' : 'Financiamento em Avaliação',
            'EVALUATION-RETURN-MENU' : 'Retornado de Avaliação',
            'APPROVED-MENU' : 'Financiamento Aprovado',
            'CONTRACT-EMITTED-MENU' : 'Financiamento Assinado',
            'CHANGE-SUBSTATUS' : 'Alterar Status',
            'CHANGE-SUBSTATUS-BUTTON' : 'Alterar',
            'INNER-CONTRACT-EMITTED' : 'Contrato Emitido',
            'INNER-CONTRACT-EMITTED-MENU' : 'Minuta Emitida',
            'SUBSTATUS-CHANGED' : 'Alteração realizada com sucesso',
            'MAXIMUM-DISCOUNT-REACHED' : 'Valor de Venda está abaixo do desconto estipulado para o empreendimento. A venda ficará pendente até aceitação pelo responsável. Deseja prosseguir ?',
            'SELL-PENDING-SUCCESS' : 'Venda enviada para aprovação com sucesso',
            'EVALUATE-SELL-MENU' : 'Avaliar Venda',
            'VIEW-SELL' : 'Detalhes da Venda',
            'APPROVE-SELL-BUTTON' : 'APROVAR',
            'DECLINE-SELL-BUTTON' : 'REJEITAR',
            'APPROVE-CONFIRMATION' : 'Deseja aprovar a venda ?',
            'DECLINE-CONFIRMATION' : 'Deseja rejeitar a venda ?',
            'SUCCESSFULLY-APPROVED' : 'Venda aprovada com sucesso',
            'SUCCESSFULLY-DECLINED' : 'Venda rejeitada com sucesso',
            'CANCELLATION-DATE' : 'Data do Distrato',
            'CANCELLED-BY' : 'Cancelada por',
            'SELL-INITIAL-VALUE-TOOLTIP' :  'Adicionar Valor de Entrada',
            'INITIAL-VALUES' : 'Entrada/Permuta',
            'EXCHANGE-TYPE-FORM-TITLE' : 'Tipo',
            'SIMULATION-TYPE' : 'Modalidade da Venda',
            'INSTALLMENT-NOT-PROPERLY-CONFIGURED' : 'Configure corretamente o financiamento',
            'EXCHANGE-CANCEL' : 'Cancelar Permuta',
            'EXCHANGE-CANCEL-CONFIRM' : 'Deseja cancelar esta Permuta ?',
            'EXCHANGE-CANCELLED' : 'Permuta cancelada com sucesso',
            'SELL-REMOVE' : 'Cancelar Venda',
            'SELL-REMOVED' : 'Venda cancelada com sucesso',
            'PENDENT-ACCEPTANCE' : 'Aceitação Pendente',
            'ACCEPT' : 'Aceitar',
            'DECLINE' : 'Rejeitar',
            'SUBSTATUS-CANCEL' : 'Cancelar Substatus',
            'SUBSTATUS-CANCELLED' : 'Substatus cancelado com sucesso',
            'OBS' : 'Observação',
            'NEGOTIATING-CANCEL' : 'Cancelar Negociação',
            'NEGOTIATING-CANCEL-CONFIRM' : 'Deseja cancelar esta Negociação?',
            'NEGOTIATING-CANCELLED' : 'Negociação cancelada com sucesso',
            'NEGOTIATING-DATE' : 'Em negociação desde',
            'UNITY-PARKING-SPOT-WITHOUT-PARKING-SPOT' : 'Vincule uma vaga de garagem à vaga extra',
            'PERSONALIZATION-BUTTON' : 'Personalizações',
            'UNITY-PERSONALIZATIONS' : 'Personalizações e Vaga Extra',
            'UNITY-VALUE' : 'Valor de Venda da Unidade',
            'PERSONALIZATIONS' : 'Personalizações',
            'PARKING-SPOTS' : 'Vaga Extra',
            'TOTAL-SELL-VALUE' : 'Valor Total da Venda',
            'LISTED-PRICE' : 'Valor de Tabela',
            'CONTRACT-EMITTED-BUTTON' : 'Confirmar',
            'BANK-CONTRACT-APPROVED' : 'Financiamento configurado com Sucesso',
            'PARKING-SPOT-TOOLTIP' : 'Vaga(s) Extra(s)',
            'PERSONALIZATIONS-TOOLTIP' : 'Personalizações',
            'OTHERS' : 'Outros',
            'OTHERS-TOOLTIP' : 'Outros Valores da Venda (Ex. Adiantamento de custas)',
            'SELL-UPDATED' : 'Venda Atualizada com Sucesso',
            'CURRENT-OTHERS-VALUE' : 'Personalizações, Vagas e Outros',
            'CURRENT-TOTAL-VALUE' : 'Valor Total da Venda',
            'CURRENT-INITIAL-VALUE' : 'Valor de Entrada',
            'CURRENT-FINANCED-VALUE' : 'Financiamento Construtora',
            'CURRENT-BANK-FINANCED-VALUE' : 'Valor Atual Banco',
            'NOTES' : 'Notas',
            'NOTE' : 'Nota'
        },
        'UNITY-EXCHANGE' : {
            'COMMISSIONABLE' : 'Comissionado'
        },
        'OFFER' : {
            'ATTACH-DOCUMENTS' : 'Anexar documentos',
            'OFFER-TEXT-INPUT' : '...ou descreva',
            'ATTACH-LIMIT' : 'Limite de três arquivos',
            'ATTACH-LIMIT-REACHED' : 'Limite de três arquivos atingido',
            'REMOVE-ATTACH-BEFORE' : 'Limite de arquivos atingido',
            'NO-ATTACHMENT-NOR-TEXT' : 'Selecione um modelo proposta, arquivo(s) ou digite a proposta',
            'SUCCESSFULLY-SENT' : 'Proposta enviada com sucesso',
            'OFFER' : 'Proposta',
            'CANCEL' : 'Cancelar',
            'CANCEL-BUTTON' : 'Cancelar',
            'VIEW-BUTTON' : 'Visualizar',
            'CREATION-DATE' : 'Data da Proposta',
            'OFFER-TEXT' : 'Descrição',
            'ATTACHED-DOCUMENTS' : 'Arquivos',
            'ACCEPT' : 'Aceitar',
            'DECLINE' : 'Rejeitar',
            'COUNTER-OFFER' : 'Contraproposta',
            'CANCEL-CONFIRMATION' : 'Deseja cancelar esta proposta ?',
            'SUCCESSFULLY-CANCELLED' : 'Proposta cancelada com sucesso',
            'ALREADY-CANCELLED' : 'Proposta não está ativa',
            'ACCEPT-CONFIRMATION' : 'Deseja aceitar esta proposta ?',
            'DECLINE-CONFIRMATION' : 'Deseja rejeitar esta proposta ?',
            'SEND-COUNTER-OFFER' : 'Enviar',
            'SUCCESSFULLY-ACCEPTED' : 'Proposta aceita com sucesso',
            'SUCCESSFULLY-DECLINED' : 'Proposta rejeitada com sucesso',
            'SUCCESSFULLY-COUNTEROFFERED' : 'Contraproposta enviada com sucesso',
            'COUNTEROFFER-SUCCESSFULLY-ACCEPTED' : 'Contraproposta aceita com sucesso',
            'COUNTER-CREATION-DATE' : 'Data da Contraproposta',
            'NEW-OFFER-MENU' : 'Nova Proposta',
            'NEW-OFFER' : 'Nova Proposta',
            'NEW-OFFER-BUTTON' : 'NOVA PROPOSTA',
            'GIVE-UP' : 'Desistir',
            'EVALUATED-BY' : 'Avaliada por',
            'EVALUATION-DATE' : 'Avaliada em',
            'ENTRY-VALUE' : 'Valor Entrada',
            'BANK-FINANCED-VALUE' : 'Financimento Banco',
            'INSTALLMENTS' : 'Parcelas',
            'INSTALLMENT-VALUE' : 'Valor Parcela',
            'FINANCED-VALUE' : 'Valor Financiado',
            'ANNUAL-REINFORCEMENT' : 'Reforço Anual',
            'SEMIANNUAL-REINFORCEMENT' : 'Reforço Semestral',
            'QUARTERLY-REINFORCEMENT' : 'Reforço Trimestral',
            'REINFORCEMENT-QUANTITY' : 'Quantidade',
            'REINFORCEMENT-QUANTITY-SHORT' : 'Qtde',
            'REINFORCEMENT-VALUE' : 'Valor',
            'SIMULATION-TYPE' : 'Modelo da Proposta',
            'SIMULATION-TYPE-INFO-LABEL' : 'Você pode selecionar um modelo de financiamento',
            'DOCUMENTS-INFO-LABEL' : 'E/ou anexar documentos por aqui',
            'OFFER-TEXT-INFO-LABEL' : '...ou ainda descrever a proposta aqui',
            'COUNTER-OFFER-SIMULATION-TYPE' : 'Modelo da Contraproposta',
            'TOTAL-VALUE' : 'Valor Total',
            'COUNTEROFFER' : 'Contraproposta',
            'SUCCESSFULLY-UPDATED' : 'Proposta atualizada com sucesso',
            'UPDATE-TITLE' : 'Editar Proposta',
            'DOCUMENTS' : 'Anexos',
            'DOCUMENT' : 'Anexo',
            'INSTALLMENT-DATE-EXCEEDED' : 'Data da última parcela excede Data de Entrega da Unidade',
            'MAXIMUM-OFFER-DISCOUNT-EXCEEDED' : 'Desconto menor do que o permitido. Verifique a soma das parcelas'
        },
        'HUMAN-RESOURCES' : {
            'TITLE' : 'Recursos Humanos',
            'EMPLOYEE' : {
                'EMPLOYEE': 'Colaborador',
                'EMPLOYEES': 'Colaboradores',
                'TITLE': 'Colaboradores',
                'NEW' : 'Novo Colaborador',
                'NEW-DETAIL' : 'Detalhes do Colaborador',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-BIRTH-DATE' : 'Nascimento',
                'PLACEHOLDER-SEX' : 'Sexo',
                'PLACEHOLDER-MARITAL-STATUS' : 'Estado Civil',
                'PLACEHOLDER-BREED' : 'Raça',
                'PLACEHOLDER-NATIONALITY' : 'Naturalidade',
                'PLACEHOLDER-DEVELOPMENT' : 'Empreendimento',
                'PLACEHOLDER-NOCTURNAL' : 'Trabalha em horário noturno',
                'PLACEHOLDER-OCCUPATION' : 'Cargo',
                'PLACEHOLDER-ALLOCATION' : 'Alocação',
                'PLACEHOLDER-ADMISSION' : 'Admissão',
                'PLACEHOLDER-CONTRACT-END' : 'Fim do Contrato',
                'PLACEHOLDER-CONTRACT-TYPE' : 'Tipo de Contrato',
                'PLACEHOLDER-EDUCATION-LEVEL' : 'Grau de instrução',
                'PLACEHOLDER-POSTAL-CODE' : 'CEP',
                'PLACEHOLDER-STREET' : 'Rua',
                'PLACEHOLDER-NUMBER' : 'Número',
                'PLACEHOLDER-COMPLEMENT' : 'Complemento',
                'PLACEHOLDER-CITY' : 'Cidade',
                'PLACEHOLDER-STATE' : 'Estado',
                'PLACEHOLDER-CPF' : 'CPF',
                'PLACEHOLDER-CTPS' : 'CTPS',
                'PLACEHOLDER-PIS' : 'PIS',
                'PLACEHOLDER-RG-NUMBER' : 'RG Número',
                'PLACEHOLDER-RG-EXPEDITION' : 'RG Expedição',
                'PLACEHOLDER-RG-ORGAN' : 'RG Orgão',
                'PLACEHOLDER-CNH-NUMBER' : 'CNH Número',
                'PLACEHOLDER-CNH-VALIDATE' : 'CNH Validade',
                'PLACEHOLDER-CNH-CATEGORY' : 'CNH Categoria',
                'PLACEHOLDER-VOTER-NUMBER' : 'Título de Eleitor',
                'PLACEHOLDER-VOTER-ZONE' : 'Zona Eleitoral',
                'PLACEHOLDER-VOTER-SECTION' : 'Seção Eleitoral',
                'PLACEHOLDER-DESCRIPTION' : 'Descrição',
                'PLACEHOLDER-KNOWLEDGE' : 'Conhecimento',
                'PLACEHOLDER-SKILL' : 'Habilidade',
                'PLACEHOLDER-ON-ADMISSION' : 'Em Admissão',
                'PLACEHOLDER-EXPERIENCE-START' : 'Início da experiência',
                'PLACEHOLDER-EXPERIENCE-END' : 'Fim da experiência',
                'PLACEHOLDER-WORK-SCHEDULE' : 'Escala de Trabalho',
                'PLACEHOLDER-EMPLOYEE-STATUS' : 'Status',
                'PLACEHOLDER-EXPIRATION-DATE' : 'Validade',
                'DELETED' : 'Colaborador excluído com sucesso!',
                'TITLE-PERSONAL-DATA' : 'Dados Pessoais',
                'TITLE-ADDRESS' : 'Endereço Residencial',
                'TITLE-DOCUMENTS' : 'Documentos',
                'TITLE-RUBRICS' : 'Rúbricas',
                'TITLE-PROFILE' : 'Perfil',
                'OF-EMPLOYEE' : 'do Colaborador',
                'EMAIL' : 'E-mail'
            },
            'EMPLOYEE-LOG' : {
                'EVENT': 'Evento',
                'NEW-EVENT': 'Novo Evento',
                'NEW-COMMENT' : 'Novo Comentário',
                'TALENT-BANK-INGRESS': 'Banco de Talento ingresso',
                'ON-ADMISSION': 'Em Admissão',                
                'EXPERIENCE-START': 'Experiência início',                
                'EXPERIENCE-FINISH' : 'Experiência término',
                'CONTRACT-START' : 'Contrato início',
                'CONTRACT-FINISH' : 'Contrato término',
                'CONTRACT-TYPE-CHANGE' : 'Alteração de Tipo de Contrato',
                'OCCUPATION-CHANGE' : 'Alteração de Cargo',
                'ALOCATION-CHANGE' : 'Alteração de Alocação',
                'WORK-SCHEDULE-CHANGE' : 'Alteração de Escala de Trabalho',
                'PLACEHOLDER-TYPE' : 'Tipo de Alteração',
                'PLACEHOLDER-DATE' : 'Data de Alteração',
                'PLACEHOLDER-ID' : 'Alteração',
                'COMMENT' : 'Comentário'
            },        
            'EMPLOYEE-STATUS' : {
                'TALENT': 'Talento',
                'ON_ADMISSION': 'Em Admissão',
                'EXPERIENCE': 'Experiência',
                'ADMITTED': 'Admitido',                
                'OFF' : 'Desligado'
            },
            'EMPLOYEE-RECORD' : {
                'PRESENCE' : 'Presença',
                'MISSED' : 'Falta',
                'JUSTIFIED' : 'Abono',
                'VACATION' : 'Férias'
            },                           
            'EMPLOYEE-SALARY' : {
                'EMPLOYEE-SALARY': 'Salário do Colaborador',
                'EMPLOYEE-SALARYS': 'Salários do Colaborador',                
                'TITLE' : 'Salários do Colaborador',
                'NEW' : 'Novo Salário',
                'DETAIL' : 'Detalhes do Salário do Colaborador',
                'PLACEHOLDER-INITIAL' : 'Início',
                'PLACEHOLDER-FINISH' : 'Término',
                'PLACEHOLDER-REMUNERATION' : 'Remuneração',
                'PLACEHOLDER-VARIABLE' : 'Variável',
                'PLACEHOLDER-SALARY-TERM' : 'Termos Salariais',
                'PLACEHOLDER-PAY-UNIT' : 'Unidade de Pagamento',
                'PLACEHOLDER-OCCUPATION' : 'Colaborador',
                'DELETED' : 'Salário do Colaborador excluído'
            },    
            'EMPLOYEE-RUBRIC' : {
                'EMPLOYEE-RUBRIC': 'Rúbrica',
                'EMPLOYEE-RUBRICS': 'Rúbricas',                
                'TITLE' : 'Rúbricas do Colaborador',
                'NEW' : 'Nova Rúbrica',
                'DETAIL' : 'Detalhes da Rúbrica do Colaborador',
                'PLACEHOLDER-RUBRIC' : 'Rúbrica',
                'PLACEHOLDER-REMUNERATION' : 'Remuneração',
                'DELETED' : 'Rúbrica do Colaborador excluída'
            },                                  
            'OCCUPATION' : {
                'OCCUPATION': 'Cargo',
                'OCCUPATIONS': 'Cargos',                
                'TITLE' : 'Quadro de Cargos',
                'NEW' : 'Novo Cargo',
                'NEW-DETAIL' : 'Detalhes do Cargo',
                'SAVED' : 'Novo Cargo cadastrado com sucesso!',
                'UPDATED' : 'Cargo atualizado com sucesso!',
                'PLACEHOLDER-OCCUPATION' : 'Cargo',
                'PLACEHOLDER-WORKPLACE' : 'Setor',
                'PLACEHOLDER-DANGEROUSNESS' : 'Periculosidade',
                'PLACEHOLDER-UNHEALTHY' : 'Insalubridade',
                'PLACEHOLDER-CBO' : 'C.B.O.',
                'PLACEHOLDER-DESCRIPTION' : 'Descrição',
                'PLACEHOLDER-KNOWLEDGE' : 'Conhecimento',
                'PLACEHOLDER-SKILL' : 'Habilidade',
                'DELETED' : 'Cargo excluída com sucesso!',
                'TITLE-CONTRACTUAL-DATA' : 'Dados Contratuais',
                'TITLE-SALARY-HISTORY' : 'Histórico Salarial',                
                'TITLE-PROFILE' : 'Perfil Profissiográfico',    
            },
            'OCCUPATION-SALARY' : {
                'OCCUPATION-SALARY': 'Salário do cargo',
                'OCCUPATION-SALARYS': 'Salários do cargo',                
                'TITLE' : 'Salários do Cargo',
                'NEW' : 'Novo Salário',
                'DETAIL' : 'Detalhes do salário do cargo',
                'PLACEHOLDER-INITIAL' : 'Início',
                'PLACEHOLDER-FINISH' : 'Término',
                'PLACEHOLDER-REMUNERATION' : 'Remuneração',
                'PLACEHOLDER-PAY-UNIT' : 'Unidade de pagamento',
                'PLACEHOLDER-OCCUPATION' : 'Cargo',
                'DELETED' : 'Salário do cargo excluído com sucesso!'
            },            
            'TABLE' : {
                'EXPERIENCE-START' : 'Experiência Início',
                'EXPERIENCE-END' : 'Experiência Final',
                'ON-ADMISSION' : 'Em Admissão',
                'ADMISSION' : 'Admissão',
                'CONTRACT-END' : 'Desligamento',
                'NAME' : 'Nome',
                'OCCUPATION' : 'Cargo',
                'CONTRACT-TYPE' : 'Tipo de contrato',
                'EDUCATION-LEVEL' : 'Grau de instrução',
                'CBO' : 'C.B.O.',
                'DANGEROUSNESS' : 'Periculosidade',
                'UNHEALTHY' : 'Insalubridade',
                'WORKPLACE' : 'Setor',
                'WORK-SHIFT' : 'Turno',
                'NOCTURNAL' : 'Noturno',
                'DAYTIME' : 'Diurno',
                'INITIAL' : 'Início',
                'FINISH' : 'Término',
                'REMUNERATION' : 'Remuneração',
                'VARIABLE' : 'Variável',
                'PAY-UNIT' : 'Unidade de pagamento',
                'DOCUMENTS' : 'Documentos',
                'STATUS' : 'Status',
                'REQUIRED' : 'Obrigatório',
                'ATTACHMENT' : 'Anexo',
                'TRAINING' : 'Treinamento',
                'TRAININGS-REQUIRED' : 'Treinamentos Obrigatórios',
                'TRAININGS-OCCUPATION' : 'Treinamentos do Cargo',
                'FREQUENCY' : 'Periodicidade',
                'ESOCIAL' : 'eSocial',
                'ALARM-DAYS' : 'Dias de Alarme',
                'START' : 'Início',
                'END' : 'Término',
                'INSTITUTE' : 'Instituto',
                'INSTRUCTOR' : 'Instrutor',
                'WORKLOAD' : 'Carga Horária',
                'LAST' : 'Último',
                'NEXT' : 'Próximo',
                'EPI' : 'EPI',
                'MODEL' : 'Modelo',
                'FABRICATOR' : 'Fabricante',
                'CA' : 'CA',
                'EPIS-OCCUPATION' : 'EPIs do Cargo',
                'MEDICAL-COORDINATOR' : 'Médico Coordenador',
                'CPF' : 'CPF',
                'CRM' : 'CRM',
                'CLINIC': 'Clínica',
                'CNPJ': 'CNPJ',
                'ACCESS': 'Credenciamento',
                'SHUTDOWN': 'Desligamento',
                'DO-APPOINTMENT': 'Realiza Consulta',
                'DO-EXAM': 'Realiza Exames',
                'EXAM': 'Exame',
                'DATE': 'Data',
                'CONSULTATION-TYPE': 'Tipo de Consulta',
                'MEDIC-EXECUTOR': 'Médico',
                'MEDIC-REPORT': 'Parecer Médico',
                'BIRTH-DATE': 'Nascimento',
                'DEPENDENT-TYPE': 'Tipo',
                'EXAMS-OCCUPATION' : 'Exames do Cargo',
                'RUBRIC' : 'Rúbrica',
                'DESCRIPTION' : 'Descrição',
                'OCCUPATIONAL' : 'Ocupacional',
                'EMPLOYEE-LOG-TYPE' : 'Tipo de Alteração',
                'EMPLOYEE-LOG-DATE' : 'Data da Alteração',
                'EMPLOYEE-LOG-ID' : 'Alteração',
                'EXPIRATION-DATE' : 'Validade',
            },
            'TABS' : {
                'ADMITTED' : 'Ativos',
                'ADMISSION' : 'Admissão',
                'OFF' : 'Desligados',
                'SETTINGS' : 'Configurações',
                'INFO' : 'Informações',
                'CONTRACTUAL' : 'Contratual',
                'REMUNERATION' : 'Remuneração',
                'DOCUMENTS' : 'Documentos',
                'ATTACHMENTS' : 'Anexos',
                'PAYS' : 'Salários',
                'TRAINING' : 'Treinamentos',
                'EXAMS' : 'Exames',
                'EPI' : 'EPI',
                'MEDICAL-COORDINATOR' : 'Médico Coordenador',
                'CLINIC': 'Clínica',
                'APPOINTMENT': 'Consulta Médica',
                'APPOINTMENTS': 'Consultas Médicas',
                'DEPENDENT': 'Dependente',
                'DEPENDENTS': 'Dependentes',
                'RUBRIC': 'Rúbrica',
                'RUBRICS': 'Rúbricas',
                'OCCUPATIONAL' : 'Ocupacional',
                'SECURITY-WORK' : 'Segurança do Trabalho',
            },
            'FILTER' : {
                'STATUS' : 'Status',
                'ALL' : 'Todos',
                'ON-ADMISSION' : 'Em Admissão',
                'ON' : 'Admitidos',
                'OFF' : 'Desligados',
                'EXPERIENCE' : 'Experiência',
                'TALENT' : 'Talentos',
            },            
            'DOCUMENT-TYPE' : {
                'DOCUMENT' : 'Documento',
                'DOCUMENTS' : 'Documentos',
                'ATTACHED-DOCUMENT' : 'Documento Anexo',                
                'RG' : 'RG',
                'CPF' : 'CPF',
                'CTPS' : 'CTPS',
                'VOTER-REGISTRATION' : 'Título de Eleitor',
                'MILITARY-DRAFT' : 'Alistamento Militar',
                'PIS-PASEP' : 'PIS/PASEP',
                'ASO' : 'ASO',
                'PROOF_RESIDENCE' : 'Comprovante de Residência',
                'CHILD-BIRTH-CERTIFICATE' : 'Certidão de Filhos',
                'VACCINATION_CARD' : 'Cartão de Vacina',
                'PHOTO' : 'Foto',
                'DOWNLOAD' : 'Download',
                'UPLOAD' : 'Upload',
                'ADMISSION-CONTRACT' : 'Contrato Admissional',
                'RESIGNATION-CONTRACT' : 'Contrato Demissional'
            },
            'SETTINGS' : {
                'TITLE' : 'Configurações do R.H.',
            },
            'PENDENCIES' : {
                'PENDENCIES' : 'Pendências',
            },            
            'RUBRIC' : {
                'RUBRIC' : 'Rúbrica',
                'RUBRICS' : 'Rúbricas',
                'NEW' : 'Nova Rúbrica',
                'DELETED' : 'Rúbrica excluída!',
                'REMUNERATION' : 'Remuneração',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-DESCRIPTION' : 'Descrição',
                'PLACEHOLDER-REMUNERATION' : 'Remuneração',
                'PLACEHOLDER-ESOCIAL' : 'eSocial',
            },      
            'TRAINING' : {
                'TRAINING' : 'Treinamento',
                'TRAININGS' : 'Treinamentos',
                'NEW' : 'Novo Treinamento',
                'MONTHS' : 'meses',
                'HOURS' : 'horas',
                'DAYS' : 'dias',
                'FREQUENCY' : 'Periodicidade',
                'REQUIRED' : 'Obrigatório',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-DESCRIPTION' : 'Descrição',
                'PLACEHOLDER-ALARM-DAYS' : 'Dias de Alarme',
                'PLACEHOLDER-ESOCIAL' : 'eSocial',
                'INSTITUTE' : 'Instituto',
                'INSTRUCTOR' : 'Instrutor',
                'START' : 'Início',
                'END' : 'Término',
                'WORKLOAD' : 'Carga Horária',
            },      
            'STATUS' : {
                'CURRENT': 'Atualizado',
                'UNDEFINED': 'Indefinido',
                'OVERDUE': 'Vencido',
                'OPTIONAL': 'Opcional',
                'ENDING': 'A Vencer',
                'ON-ADMISSION': 'Em Admissão',
                'ON': 'Admitido',
                'OFF': 'Desligado',
                'EXPERIENCE': 'Experiência',
                'TALENT': 'Talento',
            },                        
            'EPI' : {
                'NEW': 'Novo EPI',
                'EPI': 'EPI',
                'EPIS': 'EPIs',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-MODEL' : 'Modelo',
                'PLACEHOLDER-FABRICATOR' : 'Fabricante',
                'PLACEHOLDER-CA' : 'CA',
                'PLACEHOLDER-ALARM-DAYS' : 'Dias de alarme',
                'START': 'Início',
                'END': 'Término',
            },             
            'MEDICAL-COORDINATOR' : {
                'MEDICAL-COORDINATOR' : 'Coordenador Médico',
                'PLACEHOLDER-NAME' : 'Nome do médico',
                'PLACEHOLDER-CRM' : 'CRM',
                'PLACEHOLDER-CPF' : 'CPF',
                'PLACEHOLDER-START' : 'Início',
                'PLACEHOLDER-END' : 'Término',
                'NEW' : 'Novo Coordenador',
                'DELETED' : 'Médico Coordenador excluído com sucesso!',
            },
            'CLINIC' : {
                'NEW': 'Nova Clínica',
                'DELETED': 'Clínica excluida',
                'CLINIC': 'Clínica',
                'CLINICS': 'Clínicas',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-CNPJ' : 'CNPJ',
                'PLACEHOLDER-DO-APPOINTMENT' : 'Realiza Consulta',
                'PLACEHOLDER-DO-EXAM' : 'Realiza Exames',
                'PLACEHOLDER-ACCESS' : 'Credenciamento',
                'PLACEHOLDER-SHUTDOWN' : 'Desligamento',
            },         
            'EXAM' : {
                'NEW': 'Novo Exame',
                'DELETED': 'Exame excluido',
                'EXAM': 'Exame',
                'EXAMS': 'Exames',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-ALARM-DAYS' : 'Dias de alarme',
                'START': 'Início',
                'END': 'Término',
                'FREQUENCY': 'Periodicidade',
                'REQUIRED': 'Obrigatório',
            },             
            'APPOINTMENT' : {
                'NEW': 'Nova consulta médica',
                'DELETED': 'Consulta médica excluida',                
                'APPOINTMENT': 'Consulta Médica',
                'PLACEHOLDER-CONSULTATION-TYPE' : 'Tipo de Consulta',
                'PLACEHOLDER-DATE' : 'Data',
                'PLACEHOLDER-CLINIC' : 'Clinica',
                'PLACEHOLDER-CRM-EXECUTOR' : 'CRM',
                'PLACEHOLDER-MEDIC-EXECUTOR' : 'Médico',
                'PLACEHOLDER-MEDICAL-REPORT' : 'Parecer Médico',
                'PLACEHOLDER-MEDICAL-COORDINATOR' : 'Médico Coordenador',
            },            
            'EXAMINED' : {
                'NEW': 'Novo Exame realizado',
                'DELETED': 'Exame realizado excluido',
                'EXAMINED': 'Exame realizado',
                'EXAMINEDS': 'Exames realizados',
                'PLACEHOLDER-CLINIC' : 'Clinic',
                'PLACEHOLDER-DATE' : 'Data',
                'PLACEHOLDER-MEDICAL-REPORT' : 'Parecer médico',
            }, 
            'DEPENDENT' : {
                'NEW': 'Novo Dependente',
                'DELETED': 'Dependente excluido',
                'DEPENDENT': 'Dependente',
                'DEPENDENTS': 'Dependentes',
                'PLACEHOLDER-NAME' : 'Nome',
                'PLACEHOLDER-DEPENDENT-TYPE' : 'Tipo',
                'PLACEHOLDER-CPF' : 'CPF',
                'PLACEHOLDER-SEX' : 'Sexo',
                'PLACEHOLDER-BIRTH-DATE' : 'Nascimento',
                'PLACEHOLDER-IRRF' : 'IRRF',
                'PLACEHOLDER-FAMILY-SALARY' : 'Salário Família',
                'PLACEHOLDER-HANDICAPPED' : 'Deficiente',
            },             
        },
        'FINANCING-INSTALLMENT' : {
            'FINANCING-INSTALLMENT-TYPE' : 'Recebimento',
            'FINANCING-INSTALLMENT' : 'Parcela',
            'FINANCING-INSTALLMENTS' : 'Parcelas',
            'FINANCING-INSTALLMENT-STATUS' : 'Situação',
            'INSTALLMENT-INDEX' : 'Nº',
            'VALUE' : 'Valor',
            'DELETE' : 'Remover',
            'MOVE-UP' : 'Mover para cima',
            'MOVE-DOWN' : 'Mover para baixo',
            'ADD-FINANCING-INDEXER' : 'Índice de Correção',
            'IMMEDIATE-FINANCING' : 'Imediato',
            'EXPIRATION-DATE' : 'Vencimento',
            'PREVISION-DATE' : 'Previsão',
            'REPLICATE' : 'Replicar',
            'REPLICATE-QUANTITY' : 'Quantidade',
            'DEVELOPMENT' : 'Empreendimento',
            'UNITY' : 'Unidade',
            'CUSTOMER' : 'Cliente',
            'TYPE' : 'Tipo',
            'EXPIRATION' : 'Vencimento',
            'PAID' : 'Ṕago',
            'INDEX' : 'Índice',
            'RATE' : 'Correção',
            'INSTALLMENTS' : 'Recebimentos',
            'INSTALLMENT' : 'Recebimento',
            'PAYMENT-OBS' : 'Observação',
            'INSTALLMENT-VALUE' : 'Valor',
            'PAYMENT-VALUE' : 'Valor Pago',
            'CURRENT-VALUE' : 'Valor Atual',
            'RECONCILED-VALUE' : 'Valor Conciliado',
            'DISCONCERT-SUCCESS' : 'Concilicação removida',
            'NO-FINANCING-INDEXERS-SELECTED' : 'Nenhum Índice adicionado'
        },
        'FINANCING-INSTALLMENT-STATUS' : {
            'OPEN' : 'Aberta',
            'EXPIRED' : 'Vencida',
            'ISSUED-BILL' : 'Boleto Emitido',
            'PAID' : 'Paga',
            'PARTIALLY-PAID' : 'Pagamento Parcial',
            'CANCELLED' : 'Distratada',
            'NEW' : 'Nova',
            'ADDED' : 'Adicionada',
            'REMOVED' : 'Removida'
        },
        'FINANCING-INSTALLMENT-TYPE' : {
            'CONSTRUCTION-COMPANY' : 'Construtora',
            'BANK' : 'Associativo',
            'KEYS' : 'Chaves',
            'INITIAL-VALUE' : 'Entrada',
            'BANK-INSTALLMENT' : 'Banco'
        },
        'FINANCING-INSTALLMENT-INDEXER' : {
            'FINANCING-INSTALLMENT-INDEXER' : 'Índice de Correção',
            'FINANCING-INSTALLMENT-INDEXERS' : 'Índices de Correção',
            'SEARCH-DIALOG-TITLE' : 'Período de Vigência',
            'START-DATE' : 'Base de Cálculo',
            'START-DATE-SHORT' : 'Base',
            'END-DATE' : 'Fim da Vigência',
            'END-DATE-SHORT' : 'Vigência',
            'TIL-EXPIRE-DATE' : 'Até Vencimento',
            'TIL-EXPIRATION-DATE' : 'Vencimento',
            'TIL-EXPIRE-DATE-TOOLTIP' : 'Desmarque para selecionar o período de fim da vigência',
            'INDEXER-VALUE' : 'Valor (%)'
        },
        'TIMELINE' : {
            'RESERVATION' : 'Reserva',
            'OFFER' : 'Proposta',
            'COUNTEROFFER' : 'Contraproposta',
            'ACCEPTANCE' : 'Aceite',
            'VIEW-HISTORY' : 'Visualizar Histórico',
            'WAITING-DOCS' : 'Aguardando Docs',
            'EVALUATION' : 'Em Avaliação',
            'EVALUATION-RETURN' : 'Retorno de Avaliação',
            'APPROVED' : 'Contrato Aprovado',
            'CONTRACT-EMITTED' : 'Financiamento Aprovado',
            'INNER-CONTRACT-EMITTED' : 'Contrato Emitido',
            'SELL_REMOVED' : 'Venda Cancelada',
            'SELL_CANCELLATION': 'Distrato',
            'SOLD' : 'Vendida'
        },
        'EXCHANGE-TYPE' : {
            'EXCHANGE-TYPE' : 'Tipo',
            'CASH' : 'Espécie',
            'PROPERTY' : 'Propriedade',
            'VEHICLE' : 'Veículo',
            'MOTORCYCLE' : 'Moto',
            'MATERIAL' : 'Material',
            'SERVICE' : 'Serviço',
            'OTHER' : 'Outro'
        },
        'UNITY-HISTORY-EXCHANGE-STATUS' : {
            'UNITY-HISTORY-EXCHANGE-STATUS' : 'Status',
            'OPEN' : 'Aberto',
            'RECEIVED' : 'Recebido',
            'SOLD' : 'Vendido',
            'PARTIALLY-RECONCILED' : 'Parcialmente Recebido',
            'RECONCILED' : 'Recebido',
            'PARTIALLY-DELIVERED' : 'Parcialmente Entrege',
            'DELIVERED' : 'Entrege',
            'CANCELLED' : 'Distratado'
        },
        'NOTIFICATION' : {
            'NEW-COUNTEROFFER' : 'Nova Contraoferta',
            'NEW-OFFER' : 'Nova Oferta',
            'NEW-SELL' : 'Nova Venda',
            'NEW-RESERVATION' : 'Nova Reserva',
            'NEW-EXCHANGE' : 'Nova Permuta',
            'SELL-PENDING' : 'Venda Pendente',
            'SELL-APPROVED' : 'Venda Aprovada',
            'SELL-DECLINED' : 'Venda Declinada',
            'USER-PENDING' : 'Cadastro de Usuário Pendente',
            'USER-APPROVED' : 'Cadastro de Usuário Aprovado',
            'USER-REJECTED' : 'Cadastro de Usuário Rejeitado',
            'VIEWED' : 'LIDA',
            'NOT-VIEWED' : 'NÃO LIDA',
            'NEW-RESERVATION-TITLE' : 'Nova Reserva',
            'NEW-RESERVATION-MESSAGE' : 'Uma unidade foi reservada',
            'NEW-SELL-TITLE' : 'Nova Venda',
            'NEW-SELL-MESSAGE' : 'Uma unidade foi vendida',
            'NEW-OFFER-TITLE' : 'Nova Proposta',
            'NEW-OFFER-MESSAGE' : 'Uma nova proposta foi recebida',
            'MILEAGE-ACCUMULATION' : 'Acúmulo de milhas',
            'MILEAGE-RESCUE' : 'Novo Resgate Pendente',
            'MILEAGE-RESCUE-STATUS-CHANGE' : 'Alteração de Status',
            'PAGE' : 'Página',
            'VIEWED-NO-UPPERCASE' : 'Lida',
            'NOT-VIEWED-NO-UPPERCASE' : 'Não Lida',
            'STATUS' : 'Status',
            'MESSAGE' : 'Mensagem',
            'TITLE' : 'Título',
            'DATE' : 'Data',
            'MARK-AS-READ' : 'Marcar como lida(s)',
            'MARK-ALL-AS-READ' : 'Ler todas',
            'MARK-SELECTED-AS-VIEWED-CONFIRMATION' : 'Deseja marcar as notificações selecionadas como lidas?',
            'MARK-ALL-AS-VIEWED-CONFIRMATION' : 'Deseja marcar todas as notificações como lidas?',
            'LEAD-DISTRIBUTED' : 'Gestão de Leads - Lead distribuído',
            'LEAD-CONTACT-CREATED' : 'Gestão de Leads - Novo Contato',
            'LEAD-FORM-FULFILLED' : 'Gestão de Leads - Formulário Preenchido',
            'LEAD-NEW-INTEREST' : 'Gestão de Leads - Novo Interesse',
            'LEAD-ATTENDANCE-CREATED' : 'Gestão de Leads - Atendimento Agendado',
            'LEAD-DISREGARD-PENDENT' : 'Gestão de Leads - Inativação Pendente',
            'SUPPLY-NEW-SUPPLY-REQUEST' : 'Suprimentos - Nova Solicitação',
            'SUPPLY-NEW-SUPPLY-BUDGET' : 'Suprimentos - Nova Cotação',
            'SUPPLY-BUDGET-APPROVED' : 'Suprimentos - Cotação Aprovada',
            'SUPPLY-BUDGET-FINISHED' : 'Suprimentos - Cotação Finalizada',
            'SUPPLY-INVENTORY-MINIMUM-QUANTITY' : 'Suprimentos - Estoque Mínimo'
        },
        'MEDIA' : {
            'ALL-DEVELOPMENTS' : 'Todos Empreendimentos',
            'ALL-DEVELOPMENTS-TOOLTIP' : 'Mídia será visível para todos os empreendimentos',
            'MEDIAS' : 'Mídia',
            'MEDIA' : 'Mídia',
            'VIDEOS' : 'Vídeos',
            'PHOTOS' : 'Fotos',
            'IMAGES' : 'Imagens',
            'DOCUMENTS' : 'Documentos',
            'DESCRIPTION' : 'Descrição',
            'URL' : 'URL',
            'UPLOAD' : 'Upload',
            'DOWNLOAD' : 'Download',
            'PREVIEW' : 'Visualizar',
            'FILE' : 'Arquivo',
            'FILENAME' : 'Nome do Arquivo',
            'INVALID-EXTENSION' : 'Extensão do Arquivo é inválida',
            'INAVLID-FILE-SIZE' : 'O tamanho do arquivo não deve ultrapassar 4MB',
            'CREATED' : 'Mídia adicionada com sucesso',
            'THUMBNAIL' : 'Imagem de Pré-Visualização',
            'DELETED' : 'Arquivo removido com sucesso'
        },
        'DOCUMENTS' : {
            'DOCUMENTS' : 'Documentos',
            'DOCUMENT' : 'Documento'
        },
        'DOCUMENT' : {
            'DOCUMENTS' : 'Documentos',
            'DOCUMENT' : 'Documento',
            'DELETED' : 'Documento excluído com sucesso'
        },
        'SIMULATION' : {
            'SIMULATION-VALUE' : 'Valor Total',
            'DELIVERY-DATE' : 'Data Entrega',
            'SIMULATION' : 'Simulação',
            'SUGGESTION' : 'Sugestão',
            'ENTRY-VALUE' : 'Entrada',
            'INSTALLMENTS' : 'Parcelas',
            'INSTALLMENT-VALUE' : 'Valor Parcela',
            'BANK-FINANCED-VALUE' : 'Financimento Banco',
            'FINANCED-VALUE' : 'Valor Financiado',
            'ANNUAL-REINFORCEMENT' : 'Reforço Anual',
            'SEMIANNUAL-REINFORCEMENT' : 'Reforço Semestral',
            'QUARTERLY-REINFORCEMENT' : 'Reforço Trimestral',
            'REINFORCEMENT-QUANTITY' : 'Quantidade',
            'REINFORCEMENT-VALUE' : 'Valor',
            'SIMULATE' : 'Simular',
            'WRONG-VALUES' : 'Valores de entrada e financiados devem ser igual ao valor da unidade',
            'SEND-OFFER' : 'Enviar Proposta',
            'VALUES' : 'Valores'
        },
        'SIMULATION-OFFER' : {
            'SIMULATION-INCLUDED' : 'Os dados da simulação serão enviados junto à proposta',
            'CUSTOMER-PRIMARY-DOCUMENT' : 'CPF do Cliente',
            'CUSTOMER-NAME' : 'Nome do Cliente',
            'OFFER-TEXT-INPUT' : 'Comentários',
        },
        'SUPPLY-INVOICE-TYPE' : {
            'NFS' : 'Nota Fiscal de Serviço',
            'MANUAL' : 'Nota Fiscal Manual',
            'NFE' : 'Nota Fiscal Eletrônica',
            'CTE' : 'Conhecimento de Transporte Eletrônico',
            'MANUAL-SHIPPING' : 'Nota Manual de Frete',
            'NFE-DEVOLUTION' : 'NFE de Devolução',
            'IMPORT' : 'Importação'
        },
        'SUPPLY-INVOICE-ITEM-TYPE' : {
            'DATABASE' : 'Base de Dados',
            'SUPPLY-ORDER' : 'Ordem de Compra',
            'SUPPLY-TRANSACTION' : 'Entrada'
        },
        'ENGINEERING-BASELINE-TYPE' : {
            'BASELINE' : 'Linha Base',
            'SCENARIO' : 'Programado',
            'CURRENT' : 'Realizado'
        },
        'ENGINEERING-BUDGETING-SECTION-INPUT-ACTIVITY-STATUS' : {
            'LINKED' : 'Vínculo configurado',
            'NOT-LINKED' : 'Vínculo não configurado',
            'PARTIALLY-LINKED' : 'Vínculo parcial'
        },
        'ENGINEERING-BUDGETING-SECTION-ACTIVITY-STATUS' : {
            'DOWN-LEVEL-LINKED' : 'Vinculado em nível inferior',
            'UPPER-LEVEL-LINKED' : 'Vinculado em nível superior',
            'NOT-LINKED' : 'Vínculado não configurado',
            'LINKED' : 'Vínculo configurado'
        },
        'ENGINEERING-BUDGETING-SECTION-ACTIVITY' : {
            'SUCCESSFULLY-CREATED' : 'Vínculo criado com sucesso',
            'SUCCESSFULLY-UPDATED' : 'Vínculo editado com sucesso',
            'SUCCESSFULLY-DELETED' : 'Vínculo removido com sucesso',
            'TOTAL-UNREACHED' : 'Total da ponderação deve ser 100%',
            'DELETE-CONFIRMATION' : 'Deseja remover o(s) vínculo(s) ?'
        },
        'ENGINEERING-BUDGETING-SECTION-OUTCOMING-CATEGORY-STATUS' : {
            'DOWN-LEVEL-LINKED' : 'Vinculado em nível inferior',
            'UPPER-LEVEL-LINKED' : 'Vinculado em nível superior',
            'NOT-LINKED' : 'Vínculado não configurado',
            'LINKED' : 'Vínculo configurado'
        },
        'ENGINEERING-BUDGETING-SECTION-OUTCOMING-CATEGORY' : {
            'SUCCESSFULLY-CREATED' : 'Vínculo criado com sucesso',
            'SUCCESSFULLY-UPDATED' : 'Vínculo editado com sucesso',
            'SUCCESSFULLY-DELETED' : 'Vínculo removido com sucesso',
            'TOTAL-UNREACHED' : 'Total da ponderação deve ser 100%',
            'DELETE-CONFIRMATION' : 'Deseja remover o(s) vínculo(s) ?'
        },
        'ENGINEERING-CALENDAR-DAY-OFF-TYPE' : {
            'DAY-OFF' : 'Folga',
            'HOLIDAY' : 'Feriado',
            'VACATION' : 'Férias'
        },
        'ENGINEERING-ACTIVITY-DELAY-REASON' : {
            'OUT-OF-EQUIPMENT' : 'Sem equipamento',
            'LOW-PRODUCTIVITY': 'Baixa Produtividade',
            'PROJECT-MODIFICATION': 'Modificação do Projeto',
            'LACK-OF-WORKFRONT-CLEARANCE': 'Falta de Liberação da Frente de Serviço',
            'CHANGE-OF-PRIORITY': 'Mudança de Prioridade',
            'OTHER' : 'Outro'
        },
        'ENGINEERING-ACTIVITY-LOG-TYPE' : {
            'IMAGE' : 'Imagem',
            'DOC' : 'Documento',
            'DELAY' : 'Motivo de Atraso',
            'COMMENT' : 'Comentário'
        },
        'ENGINEERING-BULLETIN-STATUS' : {
            'APPROVAL' : 'Aguardando Aprovação',
            'APPROVED' : 'Aprovado',
            'CANCELLED': 'Cancelada'
        },
        'ENGINEERING-METERING' : {
            'ENGINEERING-METERING' : 'Avanço Físico',
            'ENGINEERING-METERINGS' : 'Avanços Físicos',
            'METERING-DATE' : 'Data Medição'
        },
        'ENGINEERING-METERING-STATUS' : {
            'INITIATED' : 'Iniciada',
            'FINISHED' : 'Concluída',
            'CANCELLED' : 'Cancelada'
        },
        'ENGINEERING-SERVICE-GROUP' : {
            'ENGINEERING-SERVICE-GROUP' : 'Etapa',
            'ENGINEERING-SERVICE-GROUPS' : 'Etapas'
        },
        'ENGINEERING-SERVICE-MEASURE' : {
            'ENGINEERING-SERVICE-MEASURE' : 'Tipo',
            'TOWER' : '1° Nível',
            'FLOOR' : '2° Nível',
            'UNITY' : '3° Nível'
        },
        'ENGINEERING-PROJECT' : {
            'ENGINEERING-PROJECT' : 'Projeto',
            'TITLE' : 'Cadastro de Projeto',
            'NEW-TOWER' : 'Adicionar Torre',
            'NEW-FLOOR' : 'Adicionar Pavimento',
            'NEW-UNITY' : 'Adicionar Unidade',
            'FLOOR-DRAG-TOOLTIP' : 'Clique para ver as Unidades da Torre.&#13;Arraste para ordenar o Pavimento.'
        },
        'ENGINEERING-BUDGETING-SECTION-COMPOSITION' : {
            'CREATED' : 'Composição adicionada com sucesso',
            'UPDATED' : 'Composição atualizada com sucesso',
            'COEFFICIENT' : 'Quantidade',
            'UNITY-VALUE' : 'Valor'
        },
        'ENGINEERING-BUDGETING-SECTION-INPUT' : {
            'CREATED' : 'Insumo adicionado com sucesso',
            'UPDATED' : 'Insumo atualizado com sucesso',
            'COEFFICIENT' : 'Quantidade',
            'UNITY-VALUE' : 'Valor Unitário'
        },
        'ENGINEERING-BUDGETING-SECTION' : {
            'ENGINEERING-BUDGETING-SECTION' : 'Etapa',
            'SECTION-VALUE' : 'Valor Etapa',
            'BUDGET-DIFFERENCE-INCOMING' : 'BDI',
            'BDI' : 'BDI',
            'CREATED' : 'Etapa adicionada com sucesso',
            'UPDATED' : 'Etapa atualizada com sucesso',
            'DELETED' : 'Etapa removida com sucesso'
        },
        'ENGINEERING-BUDGETING' : {
            'ENGINEERING-BUDGETING' : 'Orçamento',
            'ENGINEERING-BUDGETINGS' : 'Orçamentos',
            'DESCRIPTION' : 'Descrição',
            'START-DATE' : 'Início',
            'END-DATE' : 'Fim',
            'BDI' : 'BDI',
            'BDI-TOOLTIP' : 'BDI por Orçamento será calculado com base no valor preenchido no campo ao lado. Por Etapas o BDI terá que ser configurado posteriormente ao adicionar Etapas.',
            'CREATED-BY' : 'Criado Por',
            'CREATION-DATE' : 'Criação',
            'NEW-ENGINEERING-BUDGETING' : 'Novo Orçamento',
            'CREATED' : 'Orçamento criado com sucesso',
            'UPDATED' : 'Orçamento atualizado com sucesso',
            'DELETED' : 'Orçamento removido com sucesso'
        },
        'ENGINEERING-BUDGETING-LEVEL' : {
            'ENGINEERING-BUDGETING-LEVEL' : 'Nível',
            'ENGINEERING-BUDGETING-LEVELS' : 'Níveis',
            'FIRST-LEVEL' : 'Primeiro Nível',
            'SECOND-LEVEL' : 'Segundo Nível',
            'THIRD-LEVEL' : 'Terceiro Nível',
            'FOURTH-LEVEL' : 'Quarto Nível'
        },
        'ENGINEERING-DATABASE' : {
            'DATABASE' : 'Base de Dados',
            'DATABASE-SHORT' : 'Base',
            'OWN' : 'Própria',
            'SINAPI' : 'Sinapi'
        },
        'ENGINEERING-INPUT' : {
            'ENGINEERING-INPUT' : 'Insumo',
            'ENGINEERING-INPUTS' : 'Insumos',
            'DESCRIPTION' : 'Descrição',
            'ENGINEERING-INPUT-TYPE' : 'Tipo',
            'MEASURE' : 'Unidade de Medida',
            'REFERENCE' : 'Referência',
            'NEW-ENGINEERING-INPUT' : 'Adicionar Insumo',
            'SEARCH' : 'Procurar Insumos',
            'INPUT-VALUE' : 'Preço Unitário',
            'INPUT-VALUE-DATE' : 'Data Preço',
            'ENGINEERING-INPUT-GROUP' : 'Família',
            'INPUT-CODE' : 'Código',
            'INPUT-TYPE-REQUIRED' : 'Selecione o Tipo do Insumo',
            'CREATED' : 'Insumo adicionado com sucesso',
            'UPDATED' : 'Insumo atualizado com sucesso',
            'DELETED' : 'Insumo removido com sucesso'
        },
        'ENGINEERING-INPUT-TYPE' : {
            'DESCRIPTION' : 'Descrição',
            'MATERIAL' : 'Material',
            'LABOR' : 'Mão de Obra',
            'EQUIPMENT' : 'Equipamento'
        },
        'ENGINEERING-INPUT-GROUP' : {
            'ENGINEERING-INPUT-GROUP' : 'Família',
            'ENGINEERING-INPUT-GROUPS' : 'Famílias',
            'DESCRIPTION' : 'Descrição'
        },
        'ENGINEERING-COMPOSITION' : {
            'ENGINEERING-COMPOSITION' : 'Composição',
            'ENGINEERING-COMPOSITIONS' : 'Composições',
            'NAME' : 'Nome',
            'DESCRIPTION' : 'Descrição',
            'COEFFICIENT' : 'Coeficiente',
            'REFERENCE' : 'Referência',
            'SEARCH' : 'Procurar Composição',
            'ENGINEERING-COMPOSITION-TYPE' : 'Tipo',
            'IMPORT-ENGINEERING-COMPOSITION' : 'Importar SINAPI',
            'NEW-ENGINEERING-COMPOSITION' : 'Adicionar Composição',
            'VALUE' : 'Valor Unitário',
            'TOTAL-PRICE' : 'Preço Total',
            'TOTAL-VALUE' : 'Valor Total',
            'UNIT-VALUE' : 'Valor Unitário',
            'ENGINEERING-MEASURE' : 'Unidade de Medida',
            'COMPOSITION-CODE' : 'Código',
            'ENGINEERING-COMPOSITION-GROUP' : 'Grupo',
            'NO-COMPOSITION-NOR-INPUT-SELECTED' : 'Nenhum Insumo ou Composição adicionado(s)',
            'CREATED' : 'Composição adicionada com sucesso',
            'UPDATED' : 'Composição atualizada com sucesso',
            'DELETED' : 'Composição removida com sucesso',
        },
        'ENGINEERING-RESTRICTION-STATUS' : {
            'NOT-INITIATED' : 'Cadastradas',
            'FINISHED' : 'Concluídas'
        },
        'ENGINEERING-RESTRICTION-TYPE' : {
            'PROJECT' : 'Projeto'
        },
        'ENGINEERING-COMPOSITION-GROUP' : {
            'ENGINEERING-COMPOSITION-GROUP' : 'Grupo',
            'DESCRIPTION' : 'Descrição'
        },
        'ENGINEERING-MEASURE' : {
            'ENGINEERING-MEASURE' : 'Unidade de Medida',
            'ENGINEERING-MEASURES' : 'Unidades de Medida',
            'DESCRIPTION' : 'Descrição',
            'UNITY' : 'Unidade',
            'SET' : 'Conjunto',
            'PAIR' : 'Par',
            'BUDGET' : 'Verba',
            'CUBIC-DECIMETER' : 'Decímetro Cúbico',
            'LINEAR-METER' : 'Metro Linear',
            'LINEAR-CENTIMETER' : 'Centímetro Linear',
            'GLOBAL-VALUE' : 'Valor Global',
            'SQUARE-METER' : 'Metro Quadrado',
            'CUBIC-METER' : 'Metro Cúbico',
            'LINEAR-METER-PER-MONTH' : 'Metro Linear por Mês',
            'SQUARE-METER-PER-MONTH' : 'Metro Quadrado por Mês',
            'TONNE' : 'Tonelada',
            'KILOGRAM' : 'Kg',
            'GRAM' : 'Grama',
            'HOUR' : 'Hora',
            'PRODUCTIVE-HOUR' : 'Hora Produtiva',
            'IMPRODUCTIVE-HOUR' : 'Hora Improdutiva',
            'DAY' : 'Dia',
            'MONTH' : 'Mês',
            'LITER' : 'Litro',
            'MILILITER' : 'Mililitro',
            '310-MILILITER' : '310 Mililitros',
            '100-LINEAR-METERS' : '100 metros lineares',
            'RENT-UNITY-DAY' : 'Aluguel diário por unidade',
            'RENT-UNITY-HOUR' : 'Aluguel por unidade por hora',
            'RENT-METER-DAY' : 'Aluguel diário por metro',
            'PERCENT' : 'Porcentagem',
            'KILOWATT' : 'Kkilowatt',
            'KILOWATT-PER-HOUR' : 'Kilowatt por Hora',
            'DOZEN' : 'Dúzia',
            'PERIOD' : 'Período',
            'ROLL' : 'Rolo',
            'HUNDRED' : 'Cento',
            'THOUSAND' : 'Mil',
            'JG' : 'Jogo',
            'GL' : 'Galão',
            'SC25KG' : 'Saco 25 Kg',
            'MXKM' : 'Metro Linear por Kilometro',
            'M2XKM' : 'Metro Quadrado por Kilometro',
            'M3XKM' : 'Metro Cúbico por Kilometro',
            'TXKM' : 'Tonelada por Kilometro',
            'KGXKM' : 'Kilograma por Kilometro',
            'UNXKM' : 'Unidade por Kilometro',
            'LXKM' : 'Litro por Kilometro'
        },
        'MENU-MODULE' : {
            'SALES' : 'Vendas',
            'HOME' : 'Home',
            'CONFIGURATIONS' : 'Configurações',
            'FINANCIAL' : 'Financeiro',
            'MILEAGE' : 'Recompensas',
            'REPORTS' : 'Relatórios',
            'HUMAN-RESOURCES' : 'Recursos Humanos',
            'ENGINEERING' : 'Engenharia',
            'LEAD' : 'Gestão de Clientes',
            'SUPPLY' : 'Suprimentos',
            'ACCOUNTING' : 'Contabilidade',
            'POST-CONSTRUCTION' : 'Pós-Obra'
        },
        'MILEAGE' : {
            'MILEAGE' : 'Programa de Recompensas',
            'USER' : 'Corretor',
            'COMPANY' : 'Imobiliária',
            'BALANCE' : 'Saldo',
            'MILEAGE-ADMIN-TITLE' : 'Programa de Recompensas',
            'MILEAGE-ADMIN-SUBTITLE' : 'Área de Administração',
            'CURRENT-BALANCE' : 'Saldo atual',
            'SEE-STATEMENT' : 'Ver extrato',
            'POINTS-TITLE' : 'pontos',
            'LATEST-ACCUMULATIONS' : 'Últimos Acúmulos',
            'LATEST-RESCUES' : 'Últimos Resgates',
            'UPCOMING-CAMPAIGNS' : 'Próximas Campanhas',
            'UPCOMING-RACES' : 'Próximas Corridas',
            'NO-UPCOMING-RACES' : 'Não há próximas corridas',
            'PREVIOUS-RACES' : 'Últimas corridas',
            'NO-PREVIOUS-RACES' : 'Não há próximas corridas',
            'CURRENT-CAMPAIGN' : 'Campanha Atual',
            'CURRENT-RACE' : 'Corrida Atual',
            'POINTS-STATEMENT' : 'Extrato de Pontos',
            'NO-REGISTRIES' : 'Não há lançamentos',
            'OVERVIEW' : 'Overview',
            'CAMPAIGNS' : 'Campanhas',
            'RACES' : 'Corridas',
            'PRODUCTS' : 'Produtos',
            'TOP-BALANCES' : 'Maiores Pontuações Acumuladas',
            'PENDENT-RESCUES' : 'Resgates Pendentes',
            'GENERAL' : 'Geral',
            'TOTAL-AMOUNT' : 'Pontos',
            'REGISTRY-TYPE' : 'Tipo',
            'REGISTRY-DATE' : 'Data',
            'LATEST-REGISTRIES' : 'Últimos Lançamentos',
            'VIEW-REGISTRIES' : 'VER TODOS',
            'VIEW-CAMPAIGNS' : 'VER TODAS',
            'NO-RESCUES' : 'Não há resgates',
            'NO-UPCOMING-CAMPAIGNS' : 'Nenhuma campanha futura',
            'ACTIVE-CAMPAIGN' : 'CAMPANHA ATIVA',
            'CAMPAIGN-BETWEEN' : 'e',
            'ACTIVE-CAMPAIGN-START-TEXT' : 'Os pontos recebidos entre',
            'ACTIVE-CAMPAIGN-END-TEXT' : 'serão multiplicados! Multiplicador:',
            'RESCUE' : 'Resgatar',
            'PRODUCT-ADDED-TO-CART' : 'Unidade do Produto adicionada ao carrinho',
            'PRODUCT-REMOVED-FROM-CART' : 'Unidade do Produto removida do carrinho',
            'FINISH-SHOPPING' : 'RESGATAR',
            'TOTAL' : 'Total',
            'CART' : 'Carrinho',
            'NO-PRODUCTS-IN-CART' : 'Nenhum produto no carrinho',
            'SHOP-FINISHED' : 'Pedido enviado! Acompanhe o andamento através do sistema',
            'RESCUE-CONFIRMATION-START' : 'Você utilizará',
            'RESCUE-CONFIRMATION-END' : 'pontos do seu saldo. Deseja prosseguir ?',
            'INSUFFICIENT-BALANCE' : 'SALDO INSUFICIENTE',
            'PRODUCT-OUT-OF-STOCK' : 'Produto sem estoque suficiente para resgate',
            'REGISTRY-STATUS' : 'Status',
            'REGISTRY-TITLE' : 'Resgate',
            'REGISTRY-SUBTITLE' : 'Status',
            'LATEST-PENDENT-RESCUES' : 'Resgastes Pendentes',
            'RESCUE-PENDENT' : 'O seu resgate está na fila para compra',
            'RESCUE-IN-PROGRESS' : 'O seu resgate está em andamento e em breve estará pronto para retirada',
            'RESCUE-READY' : 'Você já pode buscar seus produtos ! Seu resgate está pronto para retirada',
            'RESCUE-DELIVERED' : 'Resgate concluído ! Produtos entregues',
            'STATUS' : 'Status',
            'STATEMENT' : 'Extrato',
            'RESCUES' : 'Resgates',
            'SELECT-REGISTRY-TYPE' : 'Filtrar',
            'VIEW-SELL-BUTTON' : 'ACESSAR VENDA',
            'TOP-USERS' : 'Ranking Corretores',
            'TOP-COMPANIES' : 'Ranking Imobiliárias',
            'TOP-PRODUCTS' : 'Ranking Produtos',
            'ACCEPTANCE-TERM-TITLE' : 'Regulamento Programa de Recompensas',
            'ACCEPT-TERM' : 'Aceitar',
            'DECLINE-TERM' : 'Recusar',
            'USER-ACCEPTED-TERM' : 'Bem-vindo ao Programa de Recompensas!',
            'USER-DECLINED-TERM' : 'É necessário aceitar o regulamento para participar do programa Programa de Recompensas',
            'UPCOMING' : 'Próximas',
            'FACTOR' : 'Multiplicador',
            'EXPECTED-DATE' : 'Previsão de Entrega',
            'MILEAGE-STATEMENT' : 'Extrato de Pontos',
            'HELLO' : 'Olá',
            'POINTS-RESUMED' : 'pts',
            'RESCUE-POINTS-OFFER' : 'Que tal trocar por ',
            'RESCUE-POINTS-MESSAGE' : 'Resgate hoje mesmo no Programa de Recompensas',
            'RESCUE-POINTS-BUTTON' : 'QUERO RESGATAR '
        },
        'MILEAGE-PRODUCT' : {
            'PRODUCT' : 'Produto',
            'NEW-PRODUCT' : 'Novo Produto',
            'IMAGE' : 'Imagem',
            'DESCRIPTION' : 'Descrição',
            'POINTS' : 'Pontos',
            'QUANTITY' : 'Quantidade',
            'UNITIES' : 'Qtde',
            'STOCK' : 'Quantidade',
            'ILIMITED' : 'Ilimitado',
            'FEATURED' : 'Destaque',
            'DELETED' : 'Produto removido com sucesso',
            'TOTAL-POINTS' : 'Total',
            'FEATURED-TOOLTIP' : 'Produtos em destaque aparecem no topo da lista',
            'ILIMITED-TOOLTIP' : 'Produto estará sempre disponível para resgate',
            'RESCUES' : 'Resgates'
        },
        'MILEAGE-CAMPAIGN' : {
            'CAMPAIGN' : 'Campanha',
            'NEW-CAMPAIGN' : 'Nova Campanha',
            'DESCRIPTION' : 'Descrição',
            'FACTOR' : 'Multiplicador',
            'START-DATE' : 'Data de Início',
            'START-DATE-SHORT' : 'Início',
            'END-DATE' : 'Data do Fim',
            'CANCELLED' : 'Campanha cancelada com sucesso',
            'VISIBLE-TOOLTIP' : 'Se a campanha será visível aos corretor antes de seu início',
            'VISIBLE' : 'Visível',
            'PERIOD' : 'Período',
            'IMAGE' : 'Arte',
            'ALL-DEVELOPMENTS' : 'Todos Empreendimentos',
            'ALL-DEVELOPMENTS-TOOLTIP' : 'Se a campanha será para as vendas de todos os empreendimentos'
        },
        'MILEAGE-RACE' : {
            'RACE' : 'Corrida',
            'NEW-RACE' : 'Nova Corrida',
            'DESCRIPTION' : 'Descrição',
            'START-DATE' : 'Data de Início',
            'START-DATE-SHORT' : 'Início',
            'END-DATE' : 'Data do Fim',
            'END-DATE-SHORT' : 'Fim',
            'CANCELLED' : 'Corrida cancelada com sucesso',
            'VISIBLE-TOOLTIP' : 'Se a corrida será visível aos corretor antes de seu início',
            'VISIBLE' : 'Visível',
            'PRIZES' : 'Premiações',
            'PRIZE' : 'Premiação',
            'FIRST-PRIZE' : 'Primeira Colocação',
            'PLACE' : 'Colocação',
            'NO-PRIZES' : 'Adicione premiações à corrida',
            'NAME' : 'Nome',
            'TOTAL-AMOUNT' : 'Pontuação',
            'CURRENT-RACE-SUBTITLE' : 'Termina',
            'PERIOD' : 'Período',
            'IMAGE' : 'Arte',
            'ALL-DEVELOPMENTS' : 'Todos Empreendimentos',
            'ALL-DEVELOPMENTS-TOOLTIP' : 'Se a corrida será para as vendas de todos os empreendimentos',
            'ACTIVE' : 'Ativas',
            'INACTIVE' : 'Encerradas',
            'POSITION' : 'Colocação',
            'NO-SCORE' : 'Não pontuou',
            'NO-SCORE-YET' : 'Ainda não há pontuação',
            'RANKING' : 'Ranking',
            'DELETE-CONFIRMATION' : 'Deseja remover este registro de pontuação?',
            'SUCCESSFULLY-DELETED' : 'Pontuação removida com sucesso.',
            'COMPANY' : 'Imobiliária',
            'IS-COMPANY' : 'Corrida entre Imobiliárias',
            'IS-COMPANY-TITLE' : 'Esta é uma corrida por Imobiliárias',
            'COMPANY-ROW' : 'Sim',
            'NOT-COMPANY-ROW' : 'Não',
            'WINNER': 'Vencedor',
            'YOU': 'Você',
        },
        'MILEAGE-RACE-PRIZE' : {
            'DESCRIPTION' : 'Descrição',
            'PLACE' : 'Nº',
            'IMAGE' : 'Imagem'
        },
        'MILEAGE-REGISTRY-TYPE' : {
            'ACCUMULATION' : 'Acúmulo',
            'RESCUE' : 'Resgate',
            'CHARGEBACK' : 'Estorno',
            'ALL' : 'Todos'
        },
        'MILEAGE-REGISTRY-STATUS' : {
            'MILEAGE-REGISTRY-STATUS-KEY' : 'Status',
            'CREATION-DATE' : 'Data',
            'PENDENT' : 'Pendente',
            'IN-PROGRESS' : 'Andamento',
            'READY' : 'Pronto',
            'DELIVERED' : 'Entregue',
            'CHANGE-CONFIRMATION' : 'Deseja alterar o status?',
            'UPDATED' : 'Status atualizado',
            'CANCELLED' : 'Cancelado'
        },
        'ACTION-HINT' : {
            'ENGINEERING-RESTRICTION-VIEW' : 'Usuários sem esta ação poderão visualizar apenas restrições nas quais foram cadastrados como responsáveis.',
            'ENGINEERING-METERING-LIST' : 'Usuários sem esta ação poderão visualizar apenas avanços nos quais foram cadastrados como responsáveis.',
            'ENGINEERING-METERING-CANCEL' : 'Apenas usuários com esta ação poderão cancelar avanços.',
            'ENGINEERING-BULLETIN-CANCEL' : 'Apenas usuários com esta ação poderão cancelar medições.',
            'ENGINEERING-BULLETIN-APPROVAL' : 'Usuários com esta permissão poderão Aprovar e Remover aprovações de medições.',
            'ENGINEERING-BULLETIN-CREATE-PAYMENT' : 'Apenas usuários com esta permissão poderão visualizar e criar Pagamentos para as Medições',
        },
        'ACTION' : {
            'ENGINEERING-BULLETIN-CREATE-PAYMENT' : 'Criar Pagamentos',
            'ENGINEERING-BULLETIN-CANCEL' : 'Cancelar Boletim de Medição',
            'ENGINEERING-BULLETIN-APPROVAL' : 'Aprovar/Remover Aprovação de Boletins',
            'ENGINEERING-METERING-CANCEL' : 'Gestão de Avanço Físico -> Cancelar Avanço',
            'ENGINEERING-METERING-LIST' : 'Gestão de Avanço Físico -> Visualizar Todos Avanços',
            'ENGINEERING-RESTRICTION-VIEW' : 'Restrição -> Visualizar Todas Restrições',
            'ENGINEERING-RESTRICTION-FORWARD' : 'Restrição -> Avançar Coluna',
            'ENGINEERING-RESTRICTION-BACKWARD' : 'Restrição -> Retroceder Coluna',
            'ENGINEERING-RESTRICTION-FINISH' : 'Restrição -> Finalizar',
            'MANAGE-PLANNING' : 'Gerenciar Serviços e Ativididades',
            'MANAGE-MEDIAS' : 'Gerenciar Mídias e Documentos',
            'MANAGE-DOCUMENTS' : 'Gerenciar Documentos',
            'OUTCOMING-CATEGORY-ANALYTIC-REPORT' : 'Previsão -> Visualizar Categorias Restritas',
            'ENGINEERING-RECEIPT-CANCEL-BUTTON' : 'Cancelar Pagamento',
            'VIEW-RELATED-LEAD' : 'Visualizar Cliente relacionado',
            'VIEW-SOLD-UNITIES-IN-LEAD-JOURNEY' : 'Visualizar Unidades Vendidas na Jornada do Cliente',
            'VIEW-LEAD-AUDIT' : 'Ver histórico cadastral do Lead',
            'UPDATE-LEAD' : 'Alterar dados cadastrais do Lead',
            'VIEW-LEAD-JOURNEY-HISTORY' : 'Visualizar Jornada do Lead Completa',
            'NEW-BANK-BILL' : 'Gerar Boletos',
            'APPROVE-OUTCOMING-PAYMENT' : 'Validar Pagamento',
            'MARK-AS-PAID' : 'Marcar como Paga',
            'REMOVE-APPROVAL' : 'Remover Validação',
            'FINANCING-INSTALLMENT-UPDATE' : 'Editar Parcela',
            'EXCHANGE-CANCEL-MENU' : 'Cancelar Permuta',
            'REALTOR-RESERVE-BUTTON' : 'Corretor -> Botão Reservar Unidade',
            'REALTOR-OFFER-BUTTON' : 'Corretor -> Botão Oferta',
            'REALTOR-CANCEL-OFFER-BUTTON' : 'Corretor -> Botão Cancelar Oferta',
            'ACCEPT-LEAD' : 'Aceitar Lead Pendente de aceite Corretor',
            'VIEW-LEAD-HISTORY' : 'Visualizar Histórico',
            'EDIT-LEAD' : 'Editar Cliente',
            'DELETE-POST-COMMENT' : 'Deletar Comentário de Postagens',
            'UPDATE-POST' : 'Atualizar Postagem',
            'DELETE-POST' : 'Remover Postagem',
            'CREATE-CUSTOMER' : 'Novo Cliente',
            'EDIT-CUSTOMER' : 'Editar Cliente',
            'DELETE-CUSTOMER' : 'Remover Cliente',
            'DISTRIBUTE-LEAD' : 'Distribuir Cliente',
            'DISREGARD-LEAD' : 'Descartar Cliente',
            'DELETE-DEVELOPMENT' : 'Remover Empreendimento',
            'CREATE-LEAD' : 'Incluir Cliente - Administração',
            'NEW-USER' : 'Novo Usuário',
            'EDIT-USER' : 'Editar Usuário',
            'DELETE-USER' : 'Remover Usuário',
            'NEW-ROLE' : 'Novo Perfil de Acesso',
            'EDIT-ROLE' : 'Editar Perfil de Acesso',
            'DELETE-ROLE' : 'Remover Perfil de Acesso',
            'MANAGE-ACTIONS' : 'Gerenciar Ações',
            'MANAGE-IMAGES' : 'Gerenciar Imagens',
            'MANAGE-VIDEOS' : 'Gerenciar Vídeos',
            'VIEW-TIMELINE-HISTORY' : 'Visualizar Histórico na Timeline',
            'RESERVATION-CANCEL' : 'Botão Cancelar Reserva',
            'COMPANY-RESERVATION-CANCEL' : 'Cancelar Reservas da Imobiliária',
            'SALES-MENU' : 'Menu de Venda da Unidade',
            'SALES-MENU-CONTRACT' : 'Menu do card "Em Contratação"',
            'SALES-MENU-SELL-CANCEL' : 'Menu de Venda - Cancelar Venda',
            'SALES-MENU-CONFIGURE-INSTALLMENTS' : 'Menu de Venda - Configurar Venda',
            'SALES-MENU-RESERVATION-CANCEL' : 'Menu de Venda - Cancelar Reserva',
            'VIEW-CONTRACT-CARD' : 'Visualizar card "Em Contratação"',
            'SALES-MENU-CONTRACT-CANCEL' : 'Menu de Venda - Cancelar Contratação',
            'SALES-MENU-RESERVE' : 'Menu de Venda - Reservar Unidade',
            'SALES-MENU-EXCHANGE' : 'Menu de Venda - Permutar Unidade',
            'SALES-MENU-OFFER-BUTTON' : 'Menu da Unidade - Registrar Proposta',
            'SALES-MENU-SELL' : 'Menu de Venda - Vender Unidade',
            'WAITING-DOCS' : 'Alterar Substatus da Venda para Aguardando Documentos',
            'EVALUATION' : 'Alterar Substatus da Venda para Avaliação',
            'EVALUATION-RETURN' : 'Alterar Substatus da Venda para Retorno da Avaliação',
            'APPROVED' : 'Alterar Substatus da Venda para Aprovada',
            'CONTRACT-EMITTED' : 'Alterar Substatus da Venda para Financiamento Aprovado',
            'MAXIMUM-DISCOUNT' : 'Aprovar venda com desconto acima do estipulado',
            'USER-CONFIG-MENU' : 'Menu Configuração -> Usuários',
            'USER-CREATION' : 'Avaliação de cadastros de Usuários',
            'VIEW-BIRTHDAY' : 'Ver Aniversariante',
            'COMPANY-CONFIG-MENU' : 'Menu Configuração -> Imobiliáris',
            'SUPPLIER-CONFIG-MENU' : 'Menu Configuração -> Fornecedores',
            'ROLE-CONFIG-MENU' : 'Menu Configuração -> Perfis',
            'DEVELOPMENT-CONFIG-MENU' : 'Menu Configuração -> Empreendimentos',
            'DEVELOPMENT-COMPANY-CONFIG-MENU' : 'Menu Configuração -> Empresas',
            'ADD-POST' : 'Adicionar Post ao Feed',
            'VIEW-COMMENTS' : 'Visualizar Comentários de Posts no Feed',
            'REPLY-COMMENTS' : 'Responder Comentários de Posts no Feed',
            'CHANGE-MILEAGE-REGISTRY-STATUS' : 'Alterar andamento de resgates',
            'VIEW-UNAVAILABLE-UNITIES' : 'Ver unidades não disponíveis',
            'COMPANY-UNITIES-VIEW' : 'Corretor Chefe -> Ver unidades da Imobiliária',
            'REALTOR-SEND-SIMULATION-BUTTON' : 'Corretor -> Botão Enviar Proposta na Simulação',
            'MANAGER-SEND-SIMULATION-BUTTON' : 'Gerente -> Botão Enviar Proposta na Simulação',
            'VIEW-BIRTHDAY-NOTIFICATION' :  'Ver aba de notificação de aniversariantes',
            'UPLOAD-DEVELOPMENT-DOCUMENT' : 'Documentos -> Botão Upload', 
            'UPLOAD-DEVELOPMENT-VIDEO' : 'Mídia -> Upload Vídeo',
            'UPLOAD-DEVELOPMENT-IMAGE' : 'Mídia -> Upload Imagem',
            'MANAGE-DEVELOPMENT-BUTTON' : 'Botão Gerenciar Empreendimento',
            'OFFER-MENU' :  'Vendas -> Menu Proposta', 
            'COUNTER-OFFER-MENU' : 'Corretor -> Menu Contraproposta',
            'CREATE-CAMPAIGN-BUTTON' :  'Botão Criar Campanha',
            'CREATE-RACE-BUTTON' : 'Botão Criar Corrida',
            'ADD-PRODUCT-BUTTON' : 'Botão Adicionar Produto',
            'EDIT-COMISSION-TAB' : 'Aba Gerenciar Comissões',
            'REALTOR-CREATE-LEAD' : 'Incluir Cliente - Corretor',
            'REALTOR-COMPANY-UNITIES' : 'Unidades Imobliária -> Corretor Chefe',
            'UNITIES-PRICE-MANAGEMENT' : 'Unidade -> Alterar Valor',
            'MANAGER-COUNTER-OFFER-MENU' : 'Gerente -> Menu Contraproposta',
            'UNITY-SUBSTATUS-CANCEL' : 'Gerente -> Cancelar Substatus da Venda',
            'USER-REMOVE' : 'Remover Usuário',
            'IMPORT-STATEMENT' : 'Botão Importar Extrato Bancário',
            'RECONCILE-BUTTON' : 'Botão Conciliar',
            'NEW-BANK-STATEMENT' : 'Botão Novo Lançamento',
            'DISCONCERT-INSTALLMENT' : "Remover Conciliação",
            'RENEGOTIATE-BUTTON' : "Botão Renegociar",
            'NEW-PAYMENT' : "Botão Novo Pagamento",
            'MANAGE-CATEGORIES' : "Botão Gerenciar Categorias",
            'EDIT-SELECTED-INSTALLMENT' : "Botão Editar Parcelas Selecionadas",
            'EDIT-STATEMENT-BUTTON' : 'Botão Remover Lançamento',
            'DELETE-STATEMENT-BUTTON' : 'Botão Editar Lançamento',
            'ACTIVATE-DEVELOPMENT-ASSET': 'Imobilizado -> Ativar Imobilizado',
            'INSTALLMENT-DISCOUNT' : 'Sem limite para desconto ao marcar Parcelas como Pagas',
            'DISCONCERT-OUTCOMING' : 'Remover Conciliação de Pagamento',
            'DELETE-INDEX-PERIOD' : 'Remover índice de Correção do Período',
            'VIEW-PRICE-HISTORY' : "Ver Histórico de Preços da Unidade",
            'ADD-EMPLOYEE-RECORD-DOCUMENT' : 'Adicionar Documento no Registro de Ponto',
            'EDIT-EMPLOYEE-RECORD' : 'Editar Registro de Ponto',
            'DELETE-EMPLOYEE-RECORD' : 'Deletar Registro de Ponto',
            'EDIT-EMPLOYEE' : 'Editar Colaborador',
            'EDIT-EMPLOYEE-EPI' : 'Editar EPI do Colaborador',
            'VIEW-INFORMATIONS-TAB' : 'Visualizar Aba Informações',
            'VIEW-REMUNERATION-TAB' : 'Visualizar Aba Remunerações',
            'VIEW-DOCUMENTS-TAB' : 'Visualizar Aba Documentações',
            'VIEW-DEPENDENTS-TAB' : 'Visualizar Aba Dependentes',
            'VIEW-SECURITY-WORK-TAB' : 'Visualizar Aba Segurança do Trabalho',
            'VIEW-APPOINTMENTS-TAB' : 'Visualizar Aba Consultas Médicas',
            'UPDATE-EMPLOYEE' : 'Menu Ações -> Atualizar Colaborador',
            'VIEW-EMPLOYEE-HISTORY' : 'Menu Ações -> Histórico do Colaborador',
            'VIEW-EMPLOYEE-PENDENCIES' : 'Menu Ações -> Pendências do Colaborador',
            'EMPLOYEE-NEW-SALARY' : 'Menu Ações -> Novo Salário',
            'EMPLOYEE-NEW-RUBRIC' : 'Menu Ações -> Nova Rúbrica',
            'EMPLOYEE-NEW-DEPENDENT' : 'Menu Ações -> Novo Dependente',
            'EMPLOYEE-NEW-TRAINING' : 'Menu Ações -> Novo Treinamento',
            'EMPLOYEE-NEW-EPI' : 'Menu Ações -> Novo EPI',
            'EMPLOYEE-NEW-APPOINTMENT' : 'Menu Ações -> Nova Consulta',
            'UPDATE-SUPPLY-REQUEST' : 'Atualizar Solicitação',
            'REJECT-SUPPLY-REQUEST' : 'Rejeitar Solicitação',
            'DELETE-SUPPLY-REQUEST' : 'Deletar Solicitação',
            'UPDATE-SUPPLY-ORDER' : 'Atualizar Ordem de Compra',
            'REJECT-SUPPLY-ORDER' : 'Rejeitar Ordem de Compra',
            'UPDATE-SUPPLY-BUDGET' : 'Alterar Status da Cotação',
            'UPDATE-SUPPLY-LABOR' : 'Atualizar Contrato de Serviço',
            'DELETE-SUPPLY-LABOR' : 'Deletar Contrato de Serviço',
            'UPDATE-SUPPLY-RENT' : 'Atualizar Locação de equipamento',
            'DELETE-SUPPLY-RENT' : 'Deletar Locação de Equipamento',
            'FINISH-SUPPLY-REQUEST' : 'Finalizar Solicitação',
            'UPDATE-BUDGET-TIMELINE' : 'Atualizar Timeline da Cotação',
            'FINISH-SUPPLY-BUDGET' : 'Finalizar Cotação',
            'CHANGE-BUDGET-DOCUMENT-STATUS' : 'Aprovar ou Rejeitar Documento da Cotação',
            'CHANGE-LABOR-STATUS' : 'Alterar status da ordem de serviço',
            'CHANGE-RENT-STATUS' : 'Alterar status da locação',
            'CANCEL-PURCHASE' : 'Cancelar Entrada de Insumo',
            'CANCEL-WITHDRAWAL' : 'Cancelar Retirada de Insumo',
            'APPROVE-ORDER' : 'Aprovar Ordem de Compra',
            'FINISH-SUPPLY-ORDER' : 'Finalizar Ordem de Compra', 
            'CHANGE-LABOR-ORDER-STATUS' : 'Alterar Status da Order de Serviço',
            'ADD-LABOR-ORDER-PAYMENT' : 'Adicionar Conta a Pagar na Ordem de Serviço',
            'DELETE-LABOR-ORDER' : 'Deletar Ordem se Serviço',
            'REJECT-EMPLOYEE-REQUEST' : 'Rejeitar Solicitação de Mão de Obra',
            'FINISH-EMPLOYEE-REQUEST' :'Finalizar Solicitação de Mão de Obra',
            'UPDATE-INTERVIEW' : 'Atualizar status da entrevista',
            'SCHEDULE-INTERVIEW' : 'Agendar entrevista',
            'FINISH_SUPPLY_INVOICE' : 'Finalizar Nota Fiscal',
            'APPROVE-SUPPLY-BUDGET' : 'Aprovar/Rejeitar Cotação',
            'SEND-TO-APPROVE-SUPPLY-BUDGET' : 'Enviar Cotação para Aprovação',
            'EDIT-SUPPLY-BUDGET' : 'Editar Cotação',
            'ADD-WARRANTY' : 'Adicionar Garantia',
            'EDIT-WARRANTY' : 'Editar Garantia',
            'DELETE-WARRANTY' : 'Deletar Garantia',
            'ADD-MATERIAL' : 'Adicionar Material',
            'EDIT-MATERIAL' : 'Editar Material',
            'DELETE-MATERIAL' : 'Deletar Material',
            'ADD-PREVENTIVE-MAINTENANCE' : 'Adicionar Manutenção Preventiva',
            'EDIT-PREVENTIVE-MAINTENANCE' : 'Editar Manutenção Preventiva',
            'DELETE-PREVENTIVE-MAINTENANCE' : 'Deletar Manutenção Preventiva',
            'VIEW-ALL-ACTIVITIES' : 'Visualizar todas as Atividades',
            'LABOR-ORDER-ADDENDUM' : 'Aidiconar Aditivo no Contrato Simplificado',
            'VIEW-PC-REVIEW' : 'Visualizar Avaliação do Chamado',
            'ADD-CONDOMINIUM-MAINTENANCE' : 'Adicionar Manutenção do Condomínio',
            'CANCEL-APPROVED-BUDGET' : 'Cancelar Aprovação de Cotação',
            'DELETE-SUPPLY-BUDGET' : 'Deletar Cotação',
            'CHECKLIST-EDIT' : 'Editar Checklist',
            'SHOW-UNITY-SOLD-PRICE' : 'Exibir Valor de Unidade Vendida'
        },
        'CUSTOMER-DOCUMENT-TYPE' : {
            'PRIMARY-DOCUMENT' : 'Documento de Identificação',
            'SECONDARY-DOCUMENT' : 'Certidão de Nascimento ou Casamento',
            'PROOF-OF-ADDRESS' : 'Comprovante de Endereço',
            'PROOF-OF-INCOME' : 'Comprovante de Renda',
            'INCOME-TAX' : 'Imposto de Renda',
            'PERSONAL' : 'Pessoais',
            'SPOUSE' : 'Cônjuge',
            'WORK-PERMIT' : 'Carteira de Trabalho',
            'SEVERANCE-INDEMNITY' : 'FGTS',
            'DEPENDENTS-STATEMENT' : 'Comprovante de Dependente',
            'FINANCING-SURVEY' : 'Questionário Padrão'
        },
        'LOGIN': {
            'NO-INTEG-CUSTOMER' : 'Você não possui nenhuma Empresa associada ao seu Usuário',
            'WELCOME-MESSAGE' : 'Efetue autenticação no formulário ao lado para ter acesso ao sistema',
            'WELCOME-TITLE' : 'Bem-vindo à Integ',
            'CREATE-ACCOUNT' : 'Criar uma conta',
            'NO-ACCOUNT' : 'Não tem uma conta?',
            'LOGIN' : 'ENTRAR',
            'REMEMBER-ME' : 'Lembrar-me',
            'PASSWORD-REQUIRED' : 'Senha é obrigatória',
            'EMAIL-REQUIRED' : 'E-mail é obrigatório',
            'EMAIL-VALID' : 'Digite um endereço de e-mail válido',
            'LOGIN-TITLE' : 'Autentique para acesso',
            'FORGOT-PASSWORD' : 'Esqueci minha senha',
            'FORGOT-PASSWORD-TITLE' : 'Recuperação de senha',
            'RETURN' : 'Voltar',
            'RECOVER' : 'Recuperar',
            'RECOVERED' : 'Você receberá um e-mail com instruções para cadastrar uma nova senha'
        },
        'USER-STATUS' : {
            'ACTIVE' : 'Ativo',
            'INACTIVE' : 'Inativo',
            'PENDING' : 'Pendente',
            'REJECTED' : 'Rejeitado'
        },
        'CONFIRMATION' : {
            'SUCCESS-MESSAGE' : 'Uma senha temporária foi enviada para seu e-mail. Efetue a alteração no formulário ao lado',
            'SUCCESS-MESSAGE-XS' : 'Uma senha temporária foi enviada para seu e-mail. Efetue a alteração no formulário abaixo',
            'SUCCESS-MESSAGE-TITLE' : 'E-mail validado com sucesso',
            'KEY-NOT-FOUND-TITLE' : 'Erro ao validar e-mail',
            'KEY-NOT-FOUND' : 'Favor contactar o suporte',
            'CREATE-ACCOUNT' : 'Criar uma conta',
            'CHANGE' : 'ALTERAR',
            'REMEMBER-ME' : 'Lembrar-me',
            'PASSWORD-REQUIRED' : 'Senha é obrigatória',
            'NEW-PASSWORD-REQUIRED' : 'Nova senha é obrigatória',
            'NEW-PASSWORD-CONFIRMATION-REQUIRED' : 'Confirme a nova senha',
            'FORGOT-PASSWORD' : 'Esqueci minha senha',
            'NEW-PASSWORD' : 'Nova senha',
            'CONFIRMATION-PASSWORD' : 'Confirme nova senha',
            'DIFFERENT-PASSWORDS' : 'Senhas não conferem',
            'MINIMUM-LENGTH' : 'Senha precisa de 5 ou mais caracteres',
            'CHANGE-PASSWORD-TITLE' : 'Altere sua senha',
            'PASSWORD-SUCCESSFULLY-CHANGED' : 'Senha alterada com sucesso',
            'MAIL-NOT-CONFIRMED' : 'E-mail não confirmado',
            'IS-FIRST-ACCESS' : 'Necessária alteração de senha temporária',
            'EMAIL-SENT' : 'Email reenviado com sucesso',
            'USER-REACTIVATED' : 'Usuário reativado com sucesso'
        },
        'HOME' : {
            'HELLO' : 'Utilize uma das opções através do menu.'
        },
        'USER': {
            'USER': 'Usuário',
            'USERS': 'Usuários',
            'SEARCH' : 'Procurar usuário',
            'DELETE-ALL-SELECTED' : 'Deseja remover todos usuários selecionados ?',
            'BASIC-INFO' : 'Básico',
            'PRIMARY-DOCUMENT' : 'CPF',
            'SECONDARY-DOCUMENT' : 'RG',
            'BUSINESS-IDENTIFIER' : 'Creci',
            'AUTH-INFO' : 'Informações de Autenticação',
            'PERSONAL-DATA' : 'Dados Pessoais',
            'USER-DETAIL' : 'Detalhes do Usuário',
            'NEW-USER' : 'Novo Usuário',
            'EMAIL' : 'E-mail',
            'PASSWORD' : 'Senha',
            'SAVED' : 'Usuário salvo com sucesso',
            'UPDATED' : 'Usuário atualizado com sucesso',
            'PHONE' : 'Telefone',
            'NAME' : 'Nome',
            'USER-DELETED' : 'Usuário removido com sucesso',
            'ROLE' : 'Perfil',
            'SELECT-ROLE' : 'Selecione um perfil',
            'COMPANY' : 'Imobiliária',
            'SELECT-COMPANY' : 'Selecione uma empresa',
            'ROLE-REQUIRED' : 'Perfil é obrigatório',
            'COMPANY-REQUIRED' : 'Empresa obrigatório p/ o Perfil',
            'INVALID-PRIMARY-DOCUMENT' : 'CPF/CNPJ Inválido!',
            'USER-STATUS' : 'Status',
            'DEPENDENT' : 'Dependente',
            'DEPENDENTS' : 'Dependentes',
            'ADITIONAL' : 'Adicional',
            'OBSERVATION' : 'Observação',
            'NO-DEPENDENTS' : 'Nenhum dependente',
            'EVALUATE-USER' : 'Avaliar Cadastro de Usuário',
            'DECLINE' : 'REJEITAR',
            'APPROVE' : 'APROVAR',
            'DECLINE-OBSERVATION' : 'Observação',
            'SUCCESSFULLY-EVALUATED' : 'Avaliação realizada com sucesso',
            'EMAIL-CONFIRMATION' : 'Confirmação E-mail',
            'COMPANY-HISTORY' : 'Histórico de Imobiliárias',
            'BIRTH-DATE' : 'Data Nascimento'
        },
        'USER-COMPANY-HISTORY': {
            'TITLE' : 'Histórico de Imobiliárias',
            'NAME' : 'Nome',
            'CURRENT-COMPANY' : 'Imobiliária Atual',
            'ROLE' : 'Perfil',
            'COMPANY-UNCHANGED' : 'Sem Histórico de Alteração',
            'LAST-COMPANY' : 'Imobiliária', 
            'LAST-ROLE' : 'Perfil',
            'CHANGE-DATE' : 'Data Alteração' 
        },
        'DEPENDENT' : {
            'DEPENDENT' : 'Dependente',
            'KINSHIP' : 'Parentesco',
            'NAME' : 'Nome',
            'BIRTH-DATE' : 'Nascimento',
            'OTHER-KINSHIP-INPUT' : 'Info. Adicional',
            'OTHER-KINSHIP-COLUMN' : 'Info. Adicional',
            'OTHER-KINSHIP' : 'Informação Adicional'
        },
        'PERSON-TYPE' : {
            'PRIVATE-INDIVIDUAL' : 'Pessoa Física',
            'LEGAL-ENTITY' : 'Pessoa Jurídica'
        },
        'PARTNER' : {
            'PARTNER' : 'Sócio',
            'PARTNERS' : 'Sócios',
            'NAME' : 'Nome',
            'PHONE-NUMBER' : 'Telefone',
            'EMAIL' : 'E-mail',
            'PRIMARY-IDENTIFIER' : 'CPF/CNPJ',
            'MANAGE' : 'Sócios',
            'PERSON-TYPE' : 'Tipo',
            'SEARCH' : 'Procurar',
            'NEW-BUTTON' : 'Novo'
        },
        'DEVELOPMENT-TYPE': {
            'DEVELOPMENT' : 'Empreendimento',
            'COMPANY' : 'Empresa'
        },
        'DEVELOPMENT-FINANCING-INDEXER-TYPE' : {
            'TYPE-1' : 'Juros Não Cumulativos',
            'TYPE-2' : 'Juros Cumulativos',
        },
        'DEVELOPMENT': {
            'DEVELOPMENT' : 'Empreendimento',
            'DEVELOPMENTS' : 'Empreendimentos',
            'PARTNER' : 'Sócio',
            'PARTNERS' : 'Sócios',
            'COMPANY' : 'Empresa',
            'COMPANIES' : 'Empresas',
            'PARTICIPATION' : 'Participação',
            'DEVELOPMENT-TYPE' : 'Empreendimento/Empresa',
            'SEARCH' : 'Procurar Empreendimento',
            'SEARCH-COMPANY' : 'Procurar Empresa',
            'PRIMARY-IDENTIFICATION' : 'CEI',
            'SECONDARY-IDENTIFICATION' : 'CNPJ',
            'NEW-DEVELOPMENT' : 'Novo Empreendimento',
            'NEW-COMPANY' : 'Nova Empresa',
            'POSTAL-CODE' : 'CEP',
            'STREET' : 'Rua',
            'NUMBER' : 'Número',
            'COMPLEMENT' : 'Complemento',
            'CITY' : 'Cidade',
            'STATE' : 'Estado',
            'DEVELOPMENT-DELETED' : 'Empreendimento removido com sucesso',
            'NAME' : 'Nome',
            'FANTASY-NAME' : 'Nome Fantasia',
            'PHONE-NUMBER' : 'Telefone',
            'BASIC-INFO' : 'Informações básicas',
            'DEVELOPMENT-DETAIL' : 'Detalhes do Empreendimento',
            'COMPANY-DETAIL' : 'Detalhes da Empresa',
            'SAVED' : 'Empreendimento salvo com sucesso',
            'UPDATED' : 'Empreendimento atualizado com sucesso',
            'IMPLANTATION' : 'Implantação',
            'LOGO' : 'Logo',
            'COVER' : 'Capa',
            'SELECT-DEVELOPMENT' : 'Selecione um Empreendimento para ver detalhes',
            'DELETED' : 'Empreendimento removido com sucesso',
            'TOWERS' : 'Torres',
            'FLOORS' : 'Pavimentos',
            'UNITIES' : 'Unidades',
            'UNITY-TYPES' : 'Tipos de Unidade',
            'MODULES' : 'Módulos',
            'AVAILABLE-UNITIES' : 'Disponíveis',
            'EXPIRATION-DAYS-FORM' : 'Duração da reserva das unidades',
            'EXPIRATION-DAYS-FORM-TOOLTIP' : 'Quantidade de dias que uma unidade será reservada à um cliente antes do processo de contratação.',
            'MAXIMUM-DISCOUNT-FORM' : 'Desconto para Venda sem necessidade de aprovação (%)',
            'MAXIMUM-DISCOUNT-FORM-TOOLTIP' : 'Excedida a porcentagem indicada, uma venda ficará pendente até aprovação do responsável',
            'MAXIMUM-INSTALLMENT-DISCOUNT-FORM' : 'Desconto máximo para baixa de Parcelas (%)',
            'MAXIMUM-INSTALLMENT-DISCOUNT-FORM-TOOLTIP' : 'Excedida a porcentagem indicada, será necessário Ação para marcar Parcela como Paga',
            'FINE-AND-INTEREST' : 'Multa e Juros',
            'FINE-MONEY-VALUE-FORM' : 'Multa (R$)',
            'FINE-PERCENTAGE-FORM' : 'Multa (%)',
            'INTEREST-MONEY-VALUE-FORM' : 'Juros (R$)',
            'INTEREST-PERCENTAGE-FORM' : 'Juros (%)',
            'SIMULATIONS' : 'Simulações',
            'SIMULATION-VALUES' : 'Entrada e Financiamento',
            'SIMULATION-VALUES-NOT-MATCH' : 'Somatória dos valores deve ser igual a 100%',
            'SIMULATION-FINANCED-VALUES' : 'Valores do Financiamento',
            'SIMULATION-FINANCED-VALUES-NOT-MATCH' : 'Somatória dos valores financiados deve ser igual a 100%',
            'SIMULATION-TYPE-CONFIGURED' : 'Simulação configurada com sucesso',
            'FINANCING' : 'Financiamento',
            'INDEXERS' : 'Índices de Correção',
            'NO-FINANCING-INDEXERS' : 'Não há Índices de Correção cadastrados',
            'COMMISSIONS' : 'Comissões',
            'NO-COMMISSIONS' : 'Não há Comissões cadastradas',
            'CATALOG' : 'Tabela',
            'GENERATE-CATALOG-BUTTON' : 'Gerar Tabela',
            'NO-UNITIES-TO-CATALOG' : 'Selecione as unidades para gerar a Tabela',
            'COMMISSION-START-DATE' : 'Data Inicial das Comissões',
            'MAXIMUM-OFFER-DISCOUNT' : 'Desconto Máximo da Proposta',
            'UNITY': 'unidades',
            'DETAILS': 'Detalhes',
            'SEE-ON-THE-MAP': 'Veja no mapa'
        },
        'COMMISSION' : {
            'COMMISSION' : 'Comissão',
            'COMMISSIONS' : 'Comissões',
            'COMMISSION-TYPE' : 'Tipo',
            'COMMISSIONED' : 'Comissionado',
            'REFERENCE' : 'Perfil/Usuário',
            'DEFAULT-VALUE' : 'Valor Padrão (%)',
            'MONEY-VALUE' : 'Valor Padrão (R$)',
            'ROLE-BASED' : 'Por perfil',
            'ALL-SELLS' : 'Todas Vendas',
            'EDITABLE-ON-SELL' : 'Editável na Venda',
            'ROLE' : 'Perfil',
            'COMPANY' : 'Imobiliária',
            'USER' : 'Usuário',
            'DISTRIBUTION-TYPE' : 'Distribuição Por',
            'DISTRIBUTION-TYPE-USER' : 'Usuário',
            'DISTRIBUTION-TYPE-ROLE' : 'Perfil',
            'COMMISSION-VALUE' : 'Valor (%)',
            'COMMISSION-MONEY-VALUE' : 'Valor (R$)',
            'COMMISSION-TOTAL-VALUE' : 'Valor Total',
            'COMMISSION-DELETED' : 'Comissão removida com sucesso',
            'SELECT-A-ROLE' : 'Selecione um Perfil',
            'SELECT-AN-USER' : 'Selecione um Usuário'
        },
        'COMMISSION-TYPE' : {
            'INTERNAL' : 'Interna',
            'REALTOR' : 'Corretor',
            'EXTERNAL' : 'Externa',
            'SALESMAN' : 'Vendedor'
        },
        'SIMULATION-TYPE' : {
            'ASSOCIATIVE' : {
                'TITLE' : 'Associativo',
                'BANK-FINANCEMENT' : 'Financiamento Banco',
                'INFORMATION' : 'Valor a ser financiado com o banco de forma imediata.'
            },
            'KEYS' : {
                'TITLE' : 'Chaves',
                'BANK-FINANCEMENT' : 'Financiamento Chaves',
                'INFORMATION' : 'Valor a ser quitado integralmente com a construtora através de financiamento bancário ou depósito em conta da construtora até a data prevista da entrega das chaves deste módulo.'
            },
            'CONSTRUCTION-COMPANY' : {
                'TITLE' : 'Construtora',
                'INFORMATION' : 'Valores a serem quitados com a construtora durante o período de construção da obra.'
            },
            'ENTRY-VALUE' : 'Valor Entrada Sugerido (%)',
            'FINANCED-VALUE' : 'Valor Financiado Sugerido (%)',
            'INSTALLMENT-VALUE' : 'Valor Parcelas Mensais Sugerido (%)',
            'ANNUAL-REINFORCEMENT-VALUE' : 'Valor Reforço Anual Sugerido (%)',
            'SEMIANNUAL-REINFORCEMENT-VALUE' : 'Valor Reforço Semestral Sugerido (%)',
            'QUARTERLY-REINFORCEMENT-VALUE' : 'Valor Reforço Trimestral Sugerido (%)',
            'DELETED' : 'Tipo de Financiamento removido com sucesso',
        },
        'FINANCING-INDEXER' : {
            'FINANCING-INDEXER' : 'Índice de Correção',
            'DESCRIPTION' : 'Descrição',
            'DEFAULT-VALUE' : 'Valor',
            'ALL-DEVELOPMENTS' : 'Todos Empreendimentos',
            'EMPTY-VALUE' : 'Valor não pode ser vazio',
            'VIEW-PERIODS' : 'Períodos',
            'FINANCING-INDEXER-PERIOD' : 'Período',
            'FINANCING-INDEXER-TYPE' : 'Tipo'
        },
        'FINANCING-INDEXER-TYPE' : {
            'MULTI-PERIOD' : 'Multi Períodos',
            'UNIQUE-VALUE' : 'Valor Único'
        },
        'FINANCING-INDEXER-PERIOD' : {
            'PERIOD' : 'Período',
            'INDEXER-VALUE' : 'Valor (R$)',
            'DEFAULT-INDEXER-VALUE' : 'Valor (%)',
            'CURRENT' : 'Vigente',
            'CURRENT-TOOLTIP' : 'Indica se o período será utilizado como vigente para este Índiced, ',
            'DELETED' : 'Índice do período deletado com sucesso'
        },
        'REAL-ESTATE': {
            'REAL-ESTATE': 'Imobiliária',
            'REAL-ESTATES': 'Imobiliárias',
            'SEARCH' : 'Procurar imobiliárias',
            'DELETE-ALL-SELECTED' : 'Deseja remover todas as imobiliárias selecionadas ?'
        },
        'CARD' : {
            'SALES' : {
                'TITLE' : 'Vendas',
                'SUBTITLE' : 'Acesse o sistema de vendas'
            },
            'MILEAGE' : {
                'REALTOR' : {
                    'TITLE' : 'Corretor - Programa de Recompensas',
                    'SUBTITLE' : 'Programa de Milhagem'
                },
                'TITLE' : 'Programa de Recompensas',
                'SUBTITLE' : 'Programa de Milhagem'
            },
            'DOCUMENTS' : {
                'TITLE' : 'Documentos',
                'SUBTITLE' : 'Acesse documentos do empreendimento'
            },
            'MEDIA' : {
                'TITLE' : 'Mídia',
                'SUBTITLE' : 'Veja mídias do empreendimento'
            },
            'COMMISSION' : {
                'REALTOR' : {
                    'TITLE' : 'Corretor - Comissões'
                }
            },
            'DEVELOPMENT-CATALOG' : {
                'TITLE' : 'Tabela do Empreendimento'
            }
        },
        'SHORTCUT' : {
            'SALES' : {
                'TITLE' : 'Vendas',
                'SUBTITLE' : 'Acesse o sistema de vendas'
            },
            'MILEAGE' : {
                'TITLE' : 'Programa de Recompensas',
                'SUBTITLE' : 'Programa de Milhagem'
            },
            'DOCUMENTS' : {
                'TITLE' : 'Documentos',
                'SUBTITLE' : 'Acesse documentos do empreendimento'
            },
            'MEDIA' : {
                'TITLE' : 'Mídia',
                'SUBTITLE' : 'Veja mídias do empreendimento'
            },
            'LEAD-ATTENDANCE' : {
                'TITLE' : 'Atendimento'
            }
        },
        'ROLE': {
            'ROLE': 'Perfil',
            'ROLES': 'Perfis',
            'SEARCH' : 'Procurar por nome ou descrição',
            'DELETE-ALL-SELECTED' : 'Deseja remover todas perfis selecionados ?',
            'SELECT-ROLE' : 'Selecione um perfil',
            'DESCRIPTION' : 'Descrição',
            'MANAGE-PERMISSIONS' : 'Gerenciar Permissões',
            'MANAGE-ACTIONS' : 'Gerenciar Ações',
            'MANAGE-DEVELOPMENTS' : 'Gerenciar Empreendimentos',
            'MANAGE-SHORTCUTS' : 'Gerenciar Atalhos',
            'MANAGE-CARDS' : 'Gerenciar Cards',
            'MANAGE-EMAILS' : 'Gerenciar E-mails',
            'MANAGE-NOTIFICATIONS' : 'Gerenciar Notificações',
            'ROLE-MENUS-WITH-PERMISSION' : 'Menus com permissão',
            'ROLE-MENUS-WITHOUT-PERMISSION' : 'Menus disponíveis',
            'ROLE-CARDS-WITH-PERMISSION' : 'Atalhos visíveis para o Perfil',
            'ROLE-CARDS-WITHOUT-PERMISSION' : 'Atalhos disponíveis',
            'ROLE-EMAILS-WITH-PERMISSION' : 'E-mails enviados para este Perfil',
            'ROLE-EMAILS-WITHOUT-PERMISSION' : 'E-mails disponíveis para envio',
            'ROLE-NOTIFICATIONS-WITH-PERMISSION' : 'Notificações enviados para este Perfil',
            'ROLE-NOTIFICATIONS-WITHOUT-PERMISSION' : 'Notificações disponíveis para envio',
            'ROLE-ACTIONS-WITH-PERMISSION' : 'Ações com permissão',
            'ROLE-ACTIONS-WITHOUT-PERMISSION' : 'Ações disponíveis',
            'ROLE-ACTION-SELECT-MENU-PLACEHOLDER' : 'Selecione um Menu',
            'ROLE-ACTION-SELECT-MENU-REQUIRED' : 'Selecione um Menu',
            'ROLE-DEVELOPMENTS-WITH-PERMISSION' : 'Empreendimentos visíveis',
            'ROLE-DEVELOPMENTS-WITHOUT-PERMISSION' : 'Empreendimentos não visíveis',
            'COMPANY-TYPE' : 'Tipo de Empresa',
            'COMPANY-REQUIRED' : 'Obrigatório selecionar empresa',
            'COMPANY-REQUIRED-TOOLTIP' : 'Indica que ao cadastrar um Usuário, este terá que selecionar uma Empresa',
            'NEW-ROLE' : 'Adicionar Perfil'
        },
        'EMAIL': {
            'RESERVATION-CONFIRMATION' : 'Confirmação de Reserva',
            'RESERVATION-CANCEL' : 'Cancelamento de Reserva',
            'SELL-CONFIRMATION' : 'Confirmação de Venda',
            'SELL-CANCEL' : 'Cancelamento de Venda',
            'UNITY-SUBSTATUS-CHANGE' : 'Mudança de Substatus Financiamento Bancário',
            'BANK-UNITIES-COMMENT' : 'Novo Comentário Financiamento Bancário'
        },
        'SUPPLIER-REPRESENTER' : {
            'SUPPLIER-REPRESENTER' : 'Representante',
            'SUPPLIER-REPRESENTERS' : 'Representantes',
            'NAME' : 'Nome',
            'EMAIL' : 'E-mail',
            'PHONE' : 'Telefone',
            'SUPPLIER' : 'Fornecedor',
            'SEARCH' : 'Procurar Representante',
            'NEW-BUTTON' : 'Adicionar Representante'
        },
        'SUPPLIER' : {
            'SUPPLIERS' : 'Terceiros',
            'SUPPLIER' : 'Terceiro',
            'NAME' : 'Nome',
            'POSTAL-CODE' : 'CEP',
            'STREET' : 'Rua',
            'NUMBER' : 'Número',
            'COMPLEMENT' : 'Complemento',
            'CITY' : 'Cidade',
            'STATE' : 'UF',
            'PHONE' : 'Telefone',
            'EMAIL' : 'E-mail',
            'IDENTIFIER' : 'CNPJ',
            'NEW-SUPPLIER' : 'Novo Terceiro',
            'SEARCH' : 'Buscar Terceiro',
            'DELETED' : 'Terceiro removido com sucesso',
            'VIEW-REPRESENTERS' : 'Representantes',
            'EMPLOYEE' : 'Colaboradores',
            'SUPPLIER-TYPE' : {
                'MATERIAL' : 'Material',
                'LABOR' : 'Mão de Obra',
                'MATERIAL-AND-LABOR' : 'Material e Mão de Obra',
                'SHIPPING' : 'Transportadora',
                'CUSTOMER' : 'Cliente'
            }
        },
        'SUPPLY-LABOR-DEDUCTION-TYPE' : {
            'DIRECT-BILLING' : 'Faturamento Direto',
            'TAX' : 'Imposto',
            'RETENTION' : 'Retenção Contratual',
            'OTHER' : 'Outro'
        },
        'SUPPLY-LABOR-ORDER-LOG-TYPE' : {
            'COMMENT' : 'Comentário',
            'ADDITIVE' : 'Aditivo'
        },
        'SUPPLY-LABOR-TYPE' : {
            'OUTCOMING' : 'Pagamento',
            'INCOMING' : 'Recebimento'
        },
        'SUPPLIER-DOCUMENT-TYPE' : {
            'SOCIAL-CONTRACT' : 'Contrato Social',
            'PPRA' : 'PPRA',
            'PCMSO' : 'PCMSO',
            'GFIP' : 'GFIP',
            'FGTS' : 'FGTS',
            'INSS' : 'INSS',
            'PAY-STUB' : 'Holerite'
        },
        'COMPANY' : {
            'COMPANIES' : 'Imobiliárias',
            'COMPANY' : 'Imobiliária',
            'NAME' : 'Nome',
            'POSTAL-CODE' : 'CEP',
            'STREET' : 'Rua',
            'NUMBER' : 'Número',
            'COMPLEMENT' : 'Complemento',
            'CITY' : 'Cidade',
            'STATE' : 'UF',
            'PHONE' : 'Telefone',
            'EMAIL' : 'E-mail',
            'IDENTIFIER' : 'CNPJ',
            'COMPANY-TYPE' : 'Tipo de Empresa',
            'NEW-COMPANY' : 'Nova Imobiliária',
            'SEARCH' : 'Buscar Imobiliária',
            'DELETED' : 'Imobiliária removida com sucesso'
        },
        'NEWS' : {
            'LATEST' : 'Últimas novidades',
            'COMMENT-TIMELINE' : 'Comentários'
        },
        'POST' : {
            'ALL-DEVELOPMENTS' : 'Todos Empreendimentos',
            'ALL-DEVELOPMENTS-TOOLTIP' : 'Post será visível para todos os empreendimentos',
            'NEW' : 'Novo',
            'EDIT' : 'Editar',
            'DELETE' : 'Remover',
            'NO-TITLE' : 'Adicionou um novo post',
            'NO-ATTACHMENT-NOR-MESSAGE' : 'Selecione imagens(s) ou digite um conteúdo',
            'IMAGES' : 'Imagens',
            'MESSAGE' : 'Conteúdo',
            'TITLE' : 'Título',
            'COMMENTS' : 'Comentários',
            'NO-LIKES' : 'Nenhum curtida',
            'LIKES' : 'curtidas',
            'LIKE' : 'Curtir',
            'ADD-POST-BUTTON' : 'ADICIONAR POST',
            'ADD-COMMENT' : 'Adicionar comentário...',
            'POST-COMMENT' : 'Postar Comentário',
            'COMMENT-DELETE' : 'Remover',
            'VIEW' : 'Ver',
            'COMMENT' : 'Comentar'
        },
        'TOWER' : {
            'TOWERS' : 'Torres',
            'TOWER' : 'Torre',
            'DESCRIPTION' : 'Descrição',
            'TOTAL-AREA' : 'Area Total',
            'MODULE' : 'Módulo',
            'NEW-TOWER' : 'Adicionar Torre',
            'SEARCH' : 'Buscar Torre',
            'DELETED' : 'Torre removida com sucesso',
            'MANAGE-FLOORS' : 'Gerenciar pavimentos',
            'PLAN' : 'Planta',
            'ADD-PLAN' : 'Anexar Planta',
            'NEW-TOWER-DETAIL' : 'Adicionar detalhe'
        },
        'MODULE' : {
            'MODULES' : 'Módulos',
            'MODULE' : 'Módulo',
            'DESCRIPTION' : 'Descrição',
            'NEW-MODULE' : 'Novo Módulo',
            'SEARCH' : 'Buscar Módulo',
            'DELETED' : 'Módulo removida com sucesso',
            'MANAGE-TOWERS' : 'Gerenciar torres',
            'DELIVERY-DATE' : 'Data Entrega',
            'DELIVERY-DATE-SALES-HOME' : 'Entrega',
            'VIEW-IMPLANTATION' : 'Implantação',
            'PLAN' : 'Implantação'
        },
        'UNITY-TYPE-DETAIL-TYPE' : {
            'UNITY-TYPE-DETAIL-TYPE' : 'Tipo',
            'BEDROOM' : 'Dormitório',
            'SUITE' : 'Suíte',
            'LIVING-ROOM' : 'Sala de Estar',
            'DINING-ROOM' : 'Sala de Jantar',
            'BATHROOM' : 'Banheiro',
            'TOILET' : 'Lavabo',
            'BALCONY' : 'Sacada'
        },
        'UNITY-TYPE' : {
            'UNITY-TYPES' : 'Tipos de Unidade',
            'UNITY-TYPE' : 'Tipo de Unidade',
            'DESCRIPTION' : 'Descrição',
            'NEW-UNITY-TYPE' : 'Novo Tipo',
            'SEARCH' : 'Buscar Tipo de Unidade',
            'PRIVATE-AREA' : 'Área Privada (m²)',
            'TOTAL-AREA' : 'Área Total (m²)',
            'PRODUCT' : 'Produto',
            'IS-PRODUCT-TOOLTIP' : 'Indica se as unidades deste tipo serão disponibilizadas para venda',
            'DELETED' : 'Tipo de Unidade removido com sucesso',
            'PLAN' : 'Planta',
            'GENERAL-INFO' : 'Ficha Técnica',
            'NEW-UNITY-TYPE-DETAIL' : 'Novo detalhe'
        },
        'FLOOR' : {
            'FLOORS' : 'Pavimentos',
            'FLOOR' : 'Pavimento',
            'DESCRIPTION' : 'Descrição',
            'TOWER' : 'Torre',
            'NEW-FLOOR' : 'Novo Pavimento',
            'SEARCH' : 'Buscar Pavimento',
            'DELETED' : 'Pavimento removido com sucesso',
            'ORDER' : 'Ordenar',
            'ORDER-FORM-TITLE' : 'Ordenar Pavimentos',
            'ORDER-BUTTON' : 'Ordenar',
            'SELECT-TOWER' : 'Selecione a Torre',
            'PLAN' : 'Planta',
            'VIEW-PLAN' : 'Ver planta',
            'NEW-FLOOR-DETAIL' : 'Adicionar detalhe'
        },
        'PARKING-SPOT' : {
            'PARKING-SPOTS' : 'Vagas de Garagem',
            'PARKING-SPOT' : 'Vaga de Garagem',
            'PARKING-SPOT-TYPE' : 'Tipo da Vaga',
            'PARKING-SPOT-TYPE-SHORT' : 'Tipo',
            'DESCRIPTION' : 'Descrição',
            'NEW-PARKING-SPOT' : 'Nova Vaga',
            'SEARCH' : 'Buscar Vaga de Garagem',
            'DELETED' : 'Vaga de Garagem removido com sucesso',
            'PRIVATE-AREA' : 'Área Privativa',
            'TOTAL-AREA' : 'Área Total',
            'ROOFING' : 'Coberta',
            'UNITY' : 'Unidade'
        },
        'UNITY' : {
            'UNITIES' : 'Unidades',
            'UNITY' : 'Unidade',
            'DESCRIPTION' : 'Descrição',
            'PRICE' : 'Valor',
            'FLOOR' : 'Pavimento',
            'UNITY-TYPE' : 'Tipo da Unidade',
            'UNITY-STATUS' : 'Status',
            'NEW-UNITY' : 'Nova Unidade',
            'SEARCH' : 'Buscar Unidade',
            'DELETED' : 'Unidade removida com sucesso',
            'SELECT-UNITY-TYPE' : 'Selecione o Tipo da Unidade',
            'SELECT-FLOOR' : 'Selecione o pavimento',
            'FLOOR-REQUIRED' : 'Pavimento é obrigatório',
            'VIEW' : 'Ver detalhes',
            'RESERVE' : 'Reservar Unidade',
            'LOCATION' : 'Localização',
            'SIMULATE' : 'Efetuar Simulação',
            'TOWER' : 'Torre',
            'PARKING-SPOTS' : 'Vaga(s) de Estacionamento',
            'NUMBER' : 'Numeração',
            'CURRENT-PRICE' : 'Valor Atual',
            'PRICE-UNCHANGED' : 'Valor Sem Alteração',
            'PRICE-HISTORY' : 'Histórico de Preços',
            'CHANGE-DATE' : 'Data Alteração',
            'CHANGED-BY' : 'Alterado Por',
            'NEW-UNITY-DETAIL' : 'Adicionar detalhe',
            'SEQUENTIAL-CODE' : 'Código Sequencial CAIXA'
        },
        'UNITY-STATUS' : {
            'AVAILABLE' : 'Disponível',
            'RESERVED' : 'Reservada',
            'EXCHANGE' : 'Permutada',
            'IN-HIRING' : 'Contratação',
            'SOLD' : 'Vendida',
            'APPROVED-FINANCING' : 'Financiamento Aprovado',
            'NEGOTIATING' : 'Negociação',
            'RETURNED' : 'Devolvida'
        },
        'UNITY-SUBSTATUS' : {
            'NEGOTIATING' : 'Contraproposta',
            'OFFER-ACCEPTED' : 'Proposta Aceita',
            'WAITING-DOCUMENTS' : 'Aguardando Documentos',
            'PREVISION' : 'Previsto',
            'EVALUATION' : 'Em Avaliação',
            'EVALUATION-RETURN' : 'Retorno de Avaliação',
            'APPROVED' : 'Financiamento Aprovado',
            'CONTRACT-EMITTED' : 'Financiamento Assinado',
            'INNER-CONTRACT-EMITTED' : 'Minuta Emitida',
            'SELL-PENDING' : 'Venda Pendente',
            'RESERVATION-EXPIRED' : 'Reserva Expirada',
            'SELL-CANCELLED' : 'Venda distratada',
            'SELL-REMOVED' : 'Venda Cancelada',
            'OFFER' : 'Proposta',
            'WAITING-DOCS-RESTRICTION' : 'Aguardando Documentos - Em Restrição',
            'WAITING-DOCS-UNDER-ANALYSIS' : 'Aguardando Documentos - Em Análise',
            'WAITING-DOCS-NO-CONTACT' : 'Aguardando Documentos - Sem Contato',
            'WAITING-DOCS-IN-PROCESS' : 'Aguardando Documentos - Em Processo',
            'APPROVED-WITH-CORRESPONDENT' : 'Financiamento Aprovado - Correspondente',
            'APPROVED-WITH-AGENCY' : 'Financiamento Aprovado - Agência'
        },
        'REALTOR' : {
            'REALTOR' : 'Corretor'
        },
        'UNITIES' : {
            'MY-UNITIES' : 'Minhas Unidades',
            'DEVELOPMENT' : 'Empreendimento',
            'TOWER' : 'Torre',
            'UNITY' : 'Unidade',
            'FLOOR' : 'Pavimento',
            'CUSTOMER' : 'Cliente',
            'STATUS-DATE' : 'Data',
            'STATUS' : 'Status',
            'COMMISSION' : 'Comissão Estimada',
            'COMPANY-UNITIES' : 'Unidades Imobliária',
            'REALTOR' : 'Corretor',
            'UNITIES' : 'Unidades',
            'COMPANY' : 'Imobliária',
            'USER' : 'Usuário',
            'SEARCH' : 'Buscar'
        }, 
        'OFFER-STATUS' : {
            'ACCEPTED' : 'Aceita',
            'OPEN' : 'Aberta',
            'COUNTER-OFFER' : 'Contraproposta',
            'CANCELLED' : 'Cancelada',
            'DECLINED' : 'Rejeitada'
        },
        'UNITY-REGISTRY' : {
            'TITLE' : 'Registro de Unidades',
            'SELL-DATE' : 'Data da Venda',
            'SELL-VALUE' : 'Valor da Venda'
        },
        'UNITY-REGISTRY-TIMELINE' : {
            'ABLE' : 'Apto Para Registrar',
            'IN-PROGRESS' : 'Andamento No Registro',
            'REGISTERED' : 'Registrado',
            'TRANSFER' : 'Transferências'
        },
        'UNITY-REGISTRY-STATUS' : {
            'ABLE' : 'Apto',
            'IN-PROGRESS' : 'Andamento',
            'REGISTERED' : 'Registrado',
            'TRANSFER' : 'Transferências',
            'ASSIGNMENT' : 'Cessão de Direitos de Imóvel'
        },
        'KINSHIP' : {
            'SPOUSE' : 'Cônjuge',
            'CHILD' : 'Filho(a)',
            'OTHER' : 'Outro'
        },
        'PROFILE' : {
            'BASIC-INFO' : 'Informações Básicas',
            'CHANGE-PASSWORD' : 'Alterar Senha',
            'AVATAR' : 'Foto',
            'UPDATED' : 'Perfil atualizado com sucesso',
            'PROFILE-DETAIL' : 'Editar Perfil'
        },
        'PERSONALIZATION' : {
            'PERSONALIZATION' : 'Personalização',
            'PERSONALIZATIONS' : 'Personalizações',
            'NEW-PERSONALIZATION' : 'Nova Personalização',
            'DELETED' : 'Personalização removida com sucesso',
            'MODULES' : 'Módulos',
            'DEADLINE' : 'Data limite',
            'PRICE' : 'Valor',
            'DESCRIPTION' : 'Descrição',
            'CANCEL-CONFIRMATION' : 'Deseja cancelar está personalização?',
            'SUCCESSFULLY-CANCELLED' : 'Personalização cancelada com sucesso',
            'EXISTING-PERSONALIZATION' : 'Personalização já existe na unidade',
            'PARKING-SPOT' : 'Vaga',
            'PARKING-SPOTS' : 'Vagas',
            'VALUE' : 'Valor',
            'UNIQUE' : 'Única por unidade',
            'IMAGE' : 'Imagem',
            'UNITY-PERSONALIZATIONS' : 'Personalizações e Vaga Extra',
            'OBS' : 'Observação'
        },
        'PERSONALIZATION-STATUS' : { 
              'NOT-STARTED' : 'Não Iniciado',
              'IN-PROGRESS' : 'Em Progresso',
              'CONCLUDED' : 'Concluído'
        },
        'UNITY-HISTORY-EXCHANGE-INCOMING' : {
            'UNITY-HISTORY-EXCHANGE-INCOMING' : 'Recebimento',
            'UNITY-HISTORY-EXCHANGE-INCOMINGS' : 'Recebimentos',
            'DESCRIPTION' : 'Descrição',
            'INCOMING-DATE' : 'Data',
            'INCOMING-VALUE' : 'Valor'
        },
        'UNITY-HISTORY-EXCHANGE-RECEIVE' : {
            'TITLE' : 'Recebimento da Permuta',
            'DESCRIPTION' : 'Descrição',
            'DEVELOPMENT' : 'Empreendimento',
            'DATE' : 'Data',
            'DATE-TITLE' : 'Data de Recebimento',
            'VALUE-TITLE' : 'Valor a receber',
            'SAVE' : 'Salvar Recebimento'
        },        
        'UNITY-HISTORY-EXCHANGE' : {
            'UNITY-HISTORY-EXCHANGE' : 'Permuta',
            'UNITY-HISTORY-EXCHANGES' : 'Permutas',
            'DESCRIPTION' : 'Descrição',
            'UNITY-HISTORY-EXCHANGE-TYPE' : 'Tipo',
            'EXCHANGE-VALUE' : 'Valor',
            'HISTORY-DATE' : 'Data Venda',
            'RECEIVED-VALUE' : 'Valor Recebido',
            'RECEIVED-DATE' : 'Data Recebimento',
            'RECEIVED-DEVELOPMENT' : 'Imobilizado',
            'RECEIVE-BUTTON' : 'Adicionar Recebimento',
            'DEVELOPMENT-UNITY' : 'Empreendimento/Unidade'
        },
        'OVERDUE-COMMENT' : {
            'COMMENT-SUCCESSFULLY-CREATED' : 'Adicionado com Sucesso',
            'ALERT-DATE' : 'Data Alerta',
            'MESSAGE' : 'Comentário',
            'COMMENT' : 'Comentário'
        },
        'OVERDUE-COMMENT-RESOLUTION' : {
            'OPEN' : 'Em Andamento',
            'EXPIRED' : 'Inadimplente',
            'NEW-ALERT' : 'Novo Alerta',
            'PAID' : 'Pago',
            'NO-CONTACT' : 'Não Contactado',
            'CREDIT-EVALUATION' : 'Serasa',
            'JUDICIARY' : 'Judicializado'
        },
        'SUPPLY-REQUEST-STATUS' : {
            'REQUESTED' : 'Solicitado',
            'IN-BUDGET' : 'Em Cotação',
            'APPROVED' : 'Aprovado',
            'REJECTED' : 'Rejeitado',
            'FINISHED' : 'Finalizada'
        },
        'SUPPLY-BUDGET-STATUS': {
            'IN-BUDGET' : 'Em Cotação',
            'RECIEVED' : 'Recebido',
            'REJECTED' : 'Rejeitado',
            'APPROVED' : 'Aprovado',
            'FINISHED' : 'Finalizada',
            'WAITING-FOR-APPROVAL': 'Aguardando Aprovação',
            'REVIEW' : 'Em Revisão'
        },
        'SUPPLY-BUDGET-ORDER-STATUS': {
            'NOT-ISSUED': 'Sem Ordens Emitidas',
            'PARTIALLY-ISSUED': 'Parcialmente Emitido',
            'FULLY-ISSUED': 'Emitido',
            'EXCEEDED': 'Excedido'
        },
        'SUPPLY-BUDGET-SUPPLY-REQUEST-STATUS': {
            'OPEN' : 'Aberto',
            'PARTIALLY_RECIEVED' : 'Parcialmente Recebido',
            'RECIEVED' : 'Recebido',
            'FINISHED' : 'Finalizado'
        },    
        'SUPPLY-BUDGET-TYPE' : {
            'PARTIAL' : 'Parcial',
            'TOTAL' : 'Total'
        },
        'SUPPLY-BUDGET-LOG-DOCUMENT-STATUS' : {
            'WAITING' : 'Aguardando',
            'REJECTED' : 'Rejeitado',
            'APPROVED' : 'Aprovado'
        },
        'SUPPLY-BUDGET-LOG-TYPE' : {
            'COMMENT' : 'Comentário',
            'DOCUMENT' : 'Documento',
            'STATUS' : 'Status',
            'SUPPLIER-DOCUMENT' : 'Documento do Fornecedor (Via Formulário)',
            'SUPPLIER-COMMENT' : 'Comentário do Fornecedor (Via Formulário)',
        },
        'SUPPLY-ORDER-STATUS' : {
            'ORDER-MADE' : 'Ordem Efetuada',
            'PARTIALLY-RECEIVED' : 'Parcialmente Recebida',
            'RECEIVED' : 'Recebida',
            'CANCELLED' : 'Cancelada',
            'FINISHED' : 'Finalizada'
        },
        'SUPPLY-INVOICE-STATUS' : {
            'INVOICE-ISSUED' : 'Emitida',
            'PARTIALLY-RECEIVED' : 'Parcialmente Recebida',
            'RECEIVED' : 'Recebida',
            'CANCELLED' : 'Cancelada',
            'RENT' : 'Locação',
            'FINISHED' : 'Finalizada'
        },
        'SUPPLY-PURCHASE-STATUS' : {
            'INVOICE-PENDING' : 'Pendente de Nota',
            'INVOICE-ADDED' : 'Nota Adicionada',
            'CANCELLED' : 'Cancelado'
        },
        'SUPPLY-WITHDRAWAL-STATUS' : {
            'CANCELLED' : 'Cancelado',
            'WITHDRAWN' : 'Retirado'
        },
        'SUPPLY-LABOR-DOCUMENT-TYPE' : {
            'ART' : 'ART',
            'CONTRACT' : 'Contrato'
        },
        'SUPPLY-LABOR-STATUS' : {
            'HIRED' : 'Contratado',
            'EXECUTION' : 'Em Execução',
            'FINISHED' : 'Finalizado',
            'ADDENDUM' : 'Aditivo',
            'AMENDED' : 'Aditivado'
        },
        'SUPPLY-LABOR-SUBSTATUS' : {
            'ORIGINAL' : 'Original',
            'ADDENDUM' : 'Aditivo',
            'AMENDED' : 'Aditivado'
        },
        'SUPPLY-RENT-STATUS' : {
            'WAITING-EQUIPMENT' : 'Aguardando Equipamentos',
            'RENTED' : 'Locado',
            'RETURNED' : 'Devolvido'
        },
        'SUPPLY-TRANSACTION-TYPE' : {
            'INCOMING' : 'Entrada',
            'OUTCOMING' : 'Saída',
            'INCOMING-CANCELLED' : 'Entrada Cancelada',
            'OUTCOMING-CANCELLED' : 'Saída Cancelada',
            'INCOMING-RETURNED' : 'Devolvido',
        },
        'EMPLOYEE-REQUEST-LOG-TYPE' : {
            'COMMENT' : 'Comentário',
            'DOCUMENT' : 'Documento',
            'INTERVIEW' : 'Entrevista'
        },
        'EMPLOYEE-REQUEST-LOG-STATUS' : {
            'WAITING' : 'Aguardando',
            'APPROVED' : 'Aprovado',
            'REJECTED' : 'Rejeitado'
        },
        'EMPLOYEE-REQUEST-STATUS' : {
            'REQUESTED' : 'Solicitado',
            'INTERVIEW' : 'Entrevista',
            'REJECTED' : 'Rejeitado',
            'FINISHED' : 'Finalizada'
        },
        'RATING-TYPE' : {
            'DEADLINE' : 'Prazo',
            'QUALITY' : 'Qualidade',
            'ATTENDANCE' : 'Atendimento'
        },
        'REPORTS' : {
            'FINANCING-INSTALLMENT' : {
                'SEARCH-FORM-START-DATE' : 'Início',
                'SEARCH-FORM-END-DATE' : 'Fim'
            },
            'FINANCIAL' : {
                'OVERDUE' : {
                    'DEVELOPMENTS-SELECT-REQUIRED' : 'Selecione um ou mais Empreendimentos',
                    'TITLE' : 'Inadimplência',
                    'EXPIREDS' : 'Vencimentos',
                    'TIMELINE' : 'Timeline',
                    'HISTORY' : 'Histórico',
                    'ALERT-DATE' : 'Data Alerta',
                    'MESSAGE' : 'Comentário',
                    'FIRST-EXPIRED' : 'Primeiro Vencimento',
                    'CURRENT-VALUE' : 'Valor Atual',
                    'SITUATION' : 'Situação',
                    'FORM' : {
                        'ADD-COMMENT-LABEL' : 'Adicionar Comentário',
                        'ADD-ALERT-LABEL' : 'Adicionar Alerta',
                        'ADD-CREDIT-EVALUATION-LABEL' : 'Enviado ao Serasa',
                        'ADD-JUDICIARY-LABEL' : 'Cliente Judicializado',
                        'ALERT-DATE-PLACEHOLDER' : 'Data Alerta',
                        'CREDIT-EVALUATION-DATE-PLACEHOLDER' : 'Data Envio',
                        'JUDICIARY-DATE-PLACEHOLDER' : 'Judicialização'
                    },
                    'SEARCH-FORM' : {
                        'OVERDUE-COMMENT-RESOLUTION' : 'Situação'
                    }
                },
                'OUTCOMING-CATEGORY' : {
                    'TITLE' : 'Previsão Orçamentária',
                    'TOTAL-RECONCILED-VALUE' : 'Valor Reconciliado Total',
                    'TOTAL-PREVISION-VALUE' : 'Valor Previsto Total',
                    'TOTAL-DIFFERENCE' : 'Diferença Total(%)',
                    'PAYMENTS-ALLOWED' : 'Registra Pagamento',
                    'DESCRIPTION' : 'Descrição',
                    'RECONCILED-VALUE' : 'Valor Reconciliado',
                    'PREVISION-VALUE' : 'Previsão',
                    'DIFFERENCE' : 'Diferença',
                    'DATE' : 'Data',
                    'EXPAND': 'Expandir',
                    'ANALYTICS' : 'Previsão Orçamentária Analítica'
                },
                'INCOMING' : {
                    'TITLE' : 'Contas Recebidas',
                    'FILTER' : 'Filtros',
                    'UNITY-AND-TOWER' : 'Unidade/Torre',
                    'BANK-ACCOUNT' : 'Conta Bancária',
                    'INCOME' : 'Recebido',
                    'CHART-TITLE' : 'Recebido',
                    'DEVELOPMENTS' : 'Empreendimentos',
                    'REPORT' : 'Relatório',
                    'UNITY' : 'Unidade',
                    'TOWER' : 'Torre',
                    'CUSTOMER' : 'Cliente',
                    'OCCURRENCE' : 'Ocorrência',
                    'STATUS' : 'Status',
                    'CURRENT-VALUE' : 'Valor Atual',
                    'PAYMENT-VALUE' : 'Valor Recebido',
                    'TOTAL-CURRENT-VALUE' : 'Valor Total a Receber',
                    'INSTALLMENT-TYPE' : 'Tipo',
                    'RECEIVED' : 'Recebido',
                    'RECEIVABLE' : 'A Receber',
                    'PREVISION-DATE' : 'Data Previsão',
                    'PAYMENT-DATE' : 'Data Pagamento',
                    'TOTAL-PAID-VALUE' : 'Valor Total Recebido',
                    'REGISTRIES' : 'Registros',
                },
                'OPEN-INCOMING' : {
                    'TITLE' : 'Contas a Receber',
                    'FILTER' : 'Filtros',
                    'UNITY-AND-TOWER' : 'Unidade/Torre',
                    'CHART-TITLE' : 'A Receber',
                    'DEVELOPMENTS' : 'Empreendimentos',
                    'EXPIRED' : 'Vencido',
                    'OPEN' : 'Aberto',
                    'REPORT' : 'Relatório',
                    'UNITY' : 'Unidade',
                    'TOWER' : 'Torre',
                    'CUSTOMER' : 'Cliente',
                    'OCCURRENCE' : 'Ocorrência',
                    'STATUS' : 'Status',
                    'CURRENT-VALUE' : 'Valor Atual',
                    'PAYMENT-VALUE' : 'Valor Recebido',
                    'TOTAL-CURRENT-VALUE' : 'Valor Total a Receber',
                    'INSTALLMENT-TYPE' : 'Tipo',
                    'RECEIVED' : 'Recebido',
                    'RECEIVABLE' : 'A Receber',
                    'PREVISION-DATE' : 'Data Previsão',
                    'PAYMENT-DATE' : 'Data Pagamento',
                    'TOTAL-PAID-VALUE' : 'Valor Total Recebido',
                    'REGISTRIES' : 'Registros',
                },
                'OUTCOMING-PAYMENT' : {
                    'PAID-REPORT' : {
                        'TITLE' : 'Contas Pagas',
                    },
                    'OPEN' : {
                        'TITLE' : 'Contas a Pagar',
                    },
                    'DEVELOPMENTS' : 'Empreendimento',
                    'SUPPLIER' : 'Fornecedor',
                    'REPORT' : 'Relatório',
                    'PAID' : 'Contas Pagas',
                    'TO-PAY' : 'Contas a Pagar',
                    'DESCRIPTION' : 'Descrição',
                    'OCCURRENCE' : 'Ocorrência',
                    'BALANCE' : 'Saldo',
                    'IDENTIFIER' : 'Nota Fiscal',
                    'PAYMENT-VALUE' : 'Valor',
                    'TRANSACTION-AMOUNT' : 'Valor Pago',
                    'STATUS' : 'Status',
                    'TOTAL-PAYMENT-VALUE' : 'Valor Total',
                    'TOTAL-TRANSACTION-AMOUNT' : 'Valor Total Pago',
                    'TOTAL-TO-PAY' : 'Total a Pagar',
                    'TOTAL-BALANCE' : 'Saldo Total',
                    'PREVISION-DATE' : 'Data Previsão',
                    'PAYMENT-DATE' : 'Data Pagamento',
                    'REGISTRIES' : 'Registros',
                    'PREVISION' : 'Previsão'
                },
                'DIMOB' : {
                    'TITLE' : 'Relatório Dimob',
                    'SELL-DATE' : 'Data Venda',
                    'CANCELLATION-DATE' : 'Data Distrato',
                    'CUSTOMER-DOCUMENT' : 'CPF/CNPJ',
                    'DEVELOPMENT' : 'Empreendimento',
                    'CITY' : 'Cidade',
                    'STREET' : 'Rua',
                    'STATE' : 'UF',
                    'POSTAL-CODE' : 'CEP',
                    'PAID-VALUE' : 'Valor Pago',
                    'SELL-VALUE' : 'Valor Total de Venda',
                    'CUSTOMER' : 'Cliente',
                    'UNITY' : 'Unidade'
                },
                'COMMISSION' : {
                    'TITLE' : 'Comissões',
                    'HISTORY-DATE' : 'Data Venda',
                    'PAYMENT-DATE' : 'Data Pagamento',
                    'USER' : 'Usuário',
                    'COMPANY' : 'Empresa',
                    'UNITY' : 'Unidade',
                    'TOWER' : 'Torre',
                    'DEVELOPMENT' : 'Empreendimento',
                    'COMMISSION-VALUES' : 'Comissão',
                    'SELL-VALUES' : 'Venda',
                    'BALANCE' : 'Saldo',
                    'STATUS' : 'Status',
                    'TOTAL-PAYMENT-VALUE' : 'Valor Total',
                    'TOTAL-TRANSACTION-AMOUNT' : 'Valor Total Pago',
                    'TOTAL-BALANCE' : 'Saldo Total',
                    'STATEMENT-DIALOG' : 'Pagamentos' 
                },
                'BALANCE' : {
                    'TITLE' : 'Balanço',
                    'INCOMING' : 'Recebimentos',
                    'OUTCOMING' : 'Pagamento',
                    'DEVELOPMENT' : 'Empreendimento',
                    'VALUE' : 'Valor',
                    'TOTAL' : 'TOTAL',
                    'TOTAL-INCOME' : 'Valor Total Pago',
                    'TOTAL-OUTCOME' : 'Valor Total Recebido'
                },
                'FORESEEN' : {
                    'TITLE' : 'Previsto/Realizado',
                    'INCOMING' : 'Recebimentos',
                    'OUTCOMING' : 'Pagamento',
                    'DEVELOPMENT' : 'Empreendimento',
                    'FORESEEN' : 'Previsto',
                    'TOTAL' : 'TOTAL',
                    'EFFECTIVE' : 'Realizado',
                    'TOTAL-INCOME-FORESEEN' : 'Total Recebido Previsto',
                    'TOTAL-OUTCOME-FORESEEN' : 'Valor Pago Previsto',
                    'TOTAL-INCOME' : 'Total Recebido Realizado',
                    'TOTAL-OUTCOME' : 'Total Pago Realizado'
                },
                'TOTALS' : {
                    'TITLE' : 'Totais',
                    'TYPE' : 'Tipo',
                    'VALUE' : 'Valor',
                    'CURRENT-VALUE' : 'Valor Corrigido',
                    'INCOME' : 'Recebido',
                }
            },
            'SALES' : {
                'DEVELOPMENT' : {
                    'TITLE' : 'Visão Geral',
                    'FILTER' : 'Filtro',
                    'SEARCH-FORM' : {
                        'DEVELOPMENTS' : 'Empreendimentos'
                    },
                    'DASHBOARD-TAB' : {
                        'TITLE' : 'Dashboard',
                    },
                    'UNITY-TAB' : {
                        'TITLE' : 'Unidades',
                        'SEARCH-FORM' : {
                            'ORDER-FIELDS' : {
                                'CREATION-DATE' : 'Data',
                                'DEVELOPMENT' : 'Empreendimento',
                                'UNITY' : 'Unidade',
                                'UNITY-STATUS' : 'Status',
                                'UNITY-SUBSTATUS' : 'Situação',
                                'SIMULATION-TYPE' : 'Financiamento',
                                'COMPANY' : 'Imobiliária',
                                'REALTOR' : 'Corretor'
                            },
                            'ORDER-ASC' : 'Ascendente',
                            'ORDER-DESC' : 'Descendente'
                        },
                        'TABLE' : {
                            'DEVELOPMENT' : 'Empreendimento',
                            'UNITY' : 'Unidade',
                            'CUSTOMER' : 'Cliente',
                            'REALTOR' : 'Corretor',
                            'COMPANY' : 'Imobliária',
                            'UNITY-STATUS' : 'Status',
                            'UNITY-SUBSTATUS' : 'Situação',
                            'UNITY-PRICE' : 'Valor Tabela',
                            'CREATION-DATE' : 'Data',
                            'SELL-VALUE' : 'Valor Venda',
                            'INITIAL-VALUE' : 'Entrada (Espécie + Permutas)',
                            'INITIAL-CASH-VALUE' : 'Entrada Espécie',
                            'INITIAL-EXCHANGE-VALUE' : 'Entrada Permuta',
                            'FINANCED-VALUE' : 'Construtora',
                            'BANK-FINANCED-VALUE' : 'Financiado',
                            'BANK-FINANCED-VALUE-BANK' : 'Associativo',
                            'BANK-FINANCED-VALUE-KEYS' : 'Chaves',
                            'SIMULATION-TYPE' : 'Financiamento',
                            'FLOOR' : 'Pavimento',
                            'TOWER' : 'Torre'
                        }
                    },
                    'TOWER-TAB' : {
                        'TITLE' : 'Torres',
                        'TABLE' : {
                            'TOWER' : 'Torre',
                            'MODULE' : 'Módulo',
                            'UNITY-TOTAL-PRICE' : 'Tabela',
                            'UNITY-HISTORY-EXCHANGE-VALUE' : 'Permutas',
                            'UNITY-HISTORY-SELL-VALUE' : 'Vendas',
                            'UNITY-HISTORY-INITIAL-VALUE' : 'Entrada (Espécie + Permutas)',
                            'UNITY-HISTORY-INITIAL-CASH-VALUE' : 'Entrada Espécie',
                            'UNITY-HISTORY-INITIAL-EXCHANGE-VALUE' : 'Entrada Permuta',
                            'UNITY-HISTORY-FINANCED-VALUE' : 'Construtora',
                            'BANK-FINANCED-VALUE' : 'Banco',
                            'BANK-FINANCED-VALUE-BANK' : 'Associativo',
                            'BANK-FINANCED-VALUE-KEYS' : 'Chaves',
                        }
                    },
                    'MODULE-TAB' : {
                        'TITLE' : 'Módulos',
                        'DEVELOPMENT-VALUES' : 'Empreendimento',
                        'SELL-VALUES' : 'Vendas',
                        'QUANTITY-VALUES' : 'Quantidades',
                        'TABLE' : {
                            'MODULE' : 'Módulo',
                            'UNITY-TOTAL-PRICE' : 'Tabela',
                            'UNITY-HISTORY-EXCHANGE-VALUE' : 'Permutas',
                            'UNITY-HISTORY-SELL-VALUE' : 'Vendas',
                            'UNITY-HISTORY-INITIAL-VALUE' : 'Entrada (Espécie + Permutas)',
                            'UNITY-HISTORY-INITIAL-CASH-VALUE' : 'Entrada Espécie',
                            'UNITY-HISTORY-INITIAL-EXCHANGE-VALUE' : 'Entrada Permuta',
                            'UNITY-HISTORY-FINANCED-VALUE' : 'Construtora',
                            'BANK-FINANCED-VALUE' : 'Banco',
                            'BANK-FINANCED-VALUE-BANK' : 'Associativo',
                            'BANK-FINANCED-VALUE-KEYS' : 'Chaves',
                        }
                    },
                    'PRICE-HISTORY-TAB' : {
                        'TITLE' : 'Variação Tabela',
                        'DATE' : 'Data',
                        'TABLE' : {
                            'MODULE' : 'Módulo',
                            'DEVELOPMENT' : 'Empreendimento',
                            'CURRENT-PRICE' : 'Valor Atual',
                            'LAST-PRICE' : 'Valor Data Selecionada',
                            'DIFFERENCE' : 'Diferença',
                            'TOWER' : 'Torre'
                        }
                    }
                },
                'CUSTOMER-DEVELOPMENT' : {
                    'TITLE' : 'Interesses',
                    'NAME' : 'Nome',
                    'PHONE' : 'Telefone',
                    'EMAIL' : 'E-mail',
                    'DEVELOPMENTS' : 'Empreendimentos',
                },
                'HISTORY' : {
                    'TITLE' : 'Relatório de Vendas',
                    'SEARCH-FORM' : {
                        'DEVELOPMENTS' : 'Empreendimentos',
                        'UNITY-STATUSES' : 'Status',
                        'UNITY-SUBSTATUSES' : 'Situação',
                        'SIMULATION-TYPES' : 'Financiamento',
                        'COMPANIES' : 'Imobiliárias',
                        'REALTORS' : 'Corretores',
                        'START-DATE' : 'Início',
                        'END-DATE' : 'Fim',
                        'ACTIVE-FILTER' : {
                            'LABEL' : 'Ativo',
                            'ACTIVE' : 'Atual',
                            'INACTIVE' : 'Histórico',
                            'ALL' : 'Todos',
                        },
                        'ORDER-FIELDS' : {
                            'CREATION-DATE' : 'Data',
                            'DEVELOPMENT' : 'Empreendimento',
                            'UNITY' : 'Unidade',
                            'UNITY-STATUS' : 'Status',
                            'UNITY-SUBSTATUS' : 'Situação',
                            'SIMULATION-TYPE' : 'Financiamento',
                            'COMPANY' : 'Imobiliária',
                            'REALTOR' : 'Corretor'
                        },
                        'ORDER-ASC' : 'Ascendente',
                        'ORDER-DESC' : 'Descendente'
                    },
                    'TABLE' : {
                        'UNITY-SUBSTATUS-CREATION-DATE' : 'Data Situação',
                        'DEVELOPMENT' : 'Empreendimento',
                        'UNITY' : 'Unidade',
                        'CUSTOMER' : 'Cliente',
                        'REALTOR' : 'Corretor',
                        'COMPANY' : 'Imobliária',
                        'UNITY-STATUS' : 'Status',
                        'UNITY-SUBSTATUS' : 'Situação',
                        'UNITY-PRICE' : 'Valor Tabela',
                        'CREATION-DATE' : 'Data',
                        'SELL-VALUE' : 'Valor Venda Unidade',
                        'TOTAL-SELL-VALUE' : 'Valor Total de Venda',
                        'PERSONALIZATIONS-VALUE' : 'Personalizaçoes e Vagas',
                        'INITIAL-VALUE' : 'Entrada (Espécie + Permutas)',
                        'INITIAL-CASH-VALUE' : 'Entrada Espécie',
                        'INITIAL-EXCHANGE-VALUE' : 'Entrada Permuta',
                        'FINANCED-VALUE' : 'Construtora',
                        'BANK-FINANCED-VALUE' : 'Financiado',
                        'BANK-FINANCED-VALUE-BANK' : 'Associativo',
                        'BANK-FINANCED-VALUE-KEYS' : 'Chaves',
                        'SIMULATION-TYPE' : 'Financiamento',
                        'FLOOR' : 'Pavimento',
                        'TOWER' : 'Torre',
                        'DIFFERENCE' : 'Diferença (R$)',
                        'PERCENT-DIFFERENCE' : 'Diferença (%)',
                        'OTHER-VALUE' : 'Outros'
                    },
                    'CHART' : {
                        'NO-RESULT' : 'Nenhum resultado foi encontrador para os filtros selectionados',
                        'UNITIES-BY-DEVELOPMENT' : 'Unidades Por Empreendimento',
                        'COMPANIES' : 'Imobiliárias',
                        'REALTORS' : 'Corretores',
                        'TOTAL-SELL-VALUE' : 'Valor Total de Vendas',
                        'UNITIES-BY-COMPANY' : 'Unidades Por Imobliária',
                        'VALUE-BY-DEVELOPMENT' : 'Valor Por Empreendimento',
                        'UNITIES-BY-REALTOR' : 'Unidades Por Corretor'
                    }                    
                },
                'UNITY' : {
                      'AVAILABLE' : {
                            'TITLE' : 'Unidades Disponíveis',
                            'UNITY' : 'Unidade',
                            'TOWER' : 'Torre',
                            'FLOOR' : 'Pavimento',
                            'UNITY-TYPE' : 'Tipo',
                            'PRICE' : 'Preço',
                            'NUMBER' : 'Numeração',
                            'TOTAL-AREA' : 'Aréa Total',
                            'PRIVATE-AREA' : 'Área Privativa'
                      }
                }, 
                'PARKING-SPOT' : {
                    'TITLE' : 'Vagas de Garagem',
                    'UNITY' : 'Unidade',
                    'TOWER' : 'Torre',
                    'DESCRIPTION' : 'Descrição',
                    'ROOFING' : 'Coberta',
                    'PARKING-SPOT-TYPE' : 'Tipo da Vaga',
                    'PRIVATE-AREA' : 'Área Privativa',
                    'FLOOR' : 'Pavimento',
                    'UNITY-NUMBER' : 'Numeração',
                    'UNITY-TYPE' : 'Tipo',
                    'UNITY-STATUS' : 'Status'
                },
            }
        },
        
        'PC-TICKET-STATUS' : {
            'OPEN' : 'Aberto',
            'IN-PROGRESS' : 'Em Andamento',
            'FINISHED' : 'Finalizado',
            'UNJUSTIFIED' : 'Improcedente'
        },

        'PC-TICKET-LOG-TYPE' : {
            'COMMENT' : 'Comentário',
            'DOCUMENT' : 'Documento'
        },

        'PC-ACTIVITY-STATUS': {
            'SCHEDULING': 'Agendamento',
            'IN-PROGRESS': 'Em Andamento',
            'FINISHED': 'Finalizado',
            'REJECTED': 'Rejeitado'
        },
        
        'PC-ACTIVITY-SCHEDULE-STATUS': {
            'SCHEDULING': 'Aguardando',
            'SCHEDULED': 'Agendado',
            'REJECTED': 'Rejeitado',
            'STARTED' : 'Iniciado',
            'FINISHED': 'Finalizado',
        },
          
        'POST-CONSTRUCTION' : {
            'TITLE': 'Pós-Obra',

            'HOME'      : {
                'TITLE': 'Home'
            },
            'REQUESTS'  : {
                'TITLE': 'Chamados'
            },

            'APPOINTMENT'   : {
                'TITLE': 'Agenda'
            },
            'MAINTENANCES'   : {
                'TITLE': 'Manutenções'
            },
            'WARRANTIES'    : {
                'TITLE': 'Prazos de Garantia'
            },
            'MATERIALS' : {
                'TITLE': 'Materiais e Serviços'
            },
            'SETTINGS'  : {
                'TITLE': 'Configurações',

                'DOCUMENTS' : {
                    'TITLE' : 'Documentos'
                },
                'SERVICES-WARRANTIES' : {
                    'TITLE': 'Serviços'
                },
                'REQUESTS' : {
                    'TITLE': 'Chamados'
                },
                'APPOINTMENT'   : {
                    'TITLE': 'Agenda'
                },
                'MAINTENANCES'   : {
                    'TITLE': 'Manutenções'
                },
                'MATERIALS'   : {
                    'TITLE': 'Materiais'
                },

            }
        },
        'JAVERS' : {
            'CUSTOMER' : {
                'ADDRESS' : 'Rua',
                'ADDRESSNUMBER' : 'Número',
                'BIRTHDATE' : 'Data Nascimento',
                'CITY' : 'Cidade',
                'COMPANY' : 'Empresa',
                'COMPANYADDRESS' : 'Endereço Empresa',
                'COMPANYJOB' : 'Profissão Empresa',
                'COMPANYPHONENUMBER' : 'Telefone Empresa',
                'COUNTRY' : 'País',
                'CUSTOMERSTATUS' : 'Status',
                'EMAIL' : 'E-mail',
                'INCOME' : 'Income',
                'JOB' : 'Profissão',
                'MARITALSTATUS' : 'Estado Civil',
                'MOBILENUMBER' : 'Celular',
                'NAME' : 'Nome',
                'NEIGHBORHOOD' : 'Bairro',
                'OTHERINFO' : 'Informações Adicionais',
                'PHONENUMBER' : 'Telefone',
                'POSTALCODE' : 'CEP',
                'PRIMARYDOCUMENT' : 'CPF',
                'SCHOOLING' : 'Escolaridade',
                'SECONDARYDOCUMENT' : 'RG',
                'SPOUSEEMAIL' : 'E-mail Cônjuge',
                'SPOUSEJOB' : 'Profissão Cônjuge',
                'SPOUSEMOBILENUMBER' : 'Celular Cônjuge',
                'SPOUSENAME' : 'Nome Cônjuge',
                'SPOUSEPHONENUMBER' : 'Telefone Cônjuge',
                'SPOUSEPRIMARYDOCUMENT' : 'CPF Cônjuge',
                'SPOUSESECONDARYDOCUMENT' : 'RG Cônjuge',
                'STATE' : 'Estado',
                'INTERESTEDTYPOLOGY' : 'Tipologia de Interesse',
                'INTERESTEDDEVELOPMENT' : 'Empreendimento de Interesse',
                'CUSTOMERENTRYMETHOD' : 'Como nos conheceu',
                'REALTOR' : 'Corretor',
                'ADDITIONALINFORMATION' : 'Informação Adicional',
                'ESTIMATEDVALUE' : 'Valor Estimado'
            },
            'LEAD' : {
                'CREATIONDATE' : 'Data Inclusão',
                'CREATEDBY' : 'Incluído Por',
                'ADDITIONALINFORMATION' : 'Informação Adicional',
                'DISTRIBUTIONDATE' : 'Data Distribuição',
                'ESTIMATEDVALUE' : 'Valor Estimado',
                'STREETADDRESS' : 'Rua',
                'ADDRESSNUMBER' : 'Número',
                'BIRTHDATE' : 'Data Nascimento',
                'CITY' : 'Cidade',
                'COMPANY' : 'Empresa',
                'COMPANYADDRESS' : 'Endereço Empresa',
                'COMPANYJOB' : 'Profissão Empresa',
                'COMPANYPHONENUMBER' : 'Telefone Empresa',
                'COUNTRY' : 'País',
                'LEADSTATUS' : 'Status',
                'EMAIL' : 'E-mail',
                'INCOME' : 'Income',
                'JOB' : 'Profissão',
                'MARITALSTATUS' : 'MARITALSTAT',
                'MOBILENUMBER' : 'Celular',
                'NAME' : 'Nome',
                'NEIGHBORHOOD' : 'Bairro',
                'OTHERINFO' : 'Informações Adicionais',
                'PHONENUMBER' : 'Telefone',
                'POSTALCODE' : 'CEP',
                'PRIMARYDOCUMENT' : 'CPF',
                'SCHOOLING' : 'Escolaridade',
                'SECONDARYDOCUMENT' : 'RG',
                'SPOUSEEMAIL' : 'E-mail Cônjuge',
                'SPOUSEJOB' : 'Profissão Cônjuge',
                'SPOUSEMOBILENUMBER' : 'Celular Cônjuge',
                'SPOUSENAME' : 'Nome Cônjuge',
                'SPOUSEPHONENUMBER' : 'Telefone Cônjuge',
                'SPOUSEPRIMARYDOCUMENT' : 'CPF Cônjuge',
                'SPOUSESECONDARYDOCUMENT' : 'RG Cônjuge',
                'STATE' : 'Estado',
                'INTERESTEDTYPOLOGY' : 'Tipologia de Interesse',
                'INTERESTEDDEVELOPMENT' : 'Empreendimento de Interesse',
                'CUSTOMERENTRYMETHOD' : 'Como nos conheceu',
                'REALTOR' : 'Corretor',
                'LANDING-PAGE' : 'Landing Page',
                'MESSAGE' : 'Mensagem',
                'LEADSUBSTATUS' : 'Substatus',
                'LANDINGPAGE' : 'Landing Page',
                'INCOMINGRANGE' : 'Renda Familiar'
            }
        },
        'MENU': {
            'GROUP' : 'Grupo',
            'ITEM' : 'Item'
        },
        'ERROR': {
            'OBJECT_NOT_FOUND' : 'Não encontrado',
            'OBJECT_ALREADY_EXISTS' : 'Já existente',
            'ENGINEERING_BULLETIN_NOT_LAST' : 'Somente a última Medição pode ser cancelada. Caso deseje cancelar esta Medição é necessário cancelar a(s) anterior(es).',
            'ENGINEERING_IDENTIFIER_IN_USE' : 'Identificador já em uso',
            'BANK_BILL_DEFAULTS_NOT_CREATED' : 'É necessário configurar o Beneficiário Padrão para Boletos',
            'MEDIA_FOLDER_NOT_EMPTY' : 'Diretório precisa estar vazio',
            'SUPPLY_LABOR_ORDER_METERING_HAS_PAYMENTS' : 'É necessário remover os pagamentos vinculados à esta medição para poder cancelar.',
            'ENGINEERING_ACTIVITY_HAS_METERING_STARTED' : 'Existe uma medição iniciada para esta Atividade',
            'ENGINEERING_SERVICE_GROUP_HAS_SERVICES' : 'Etapa possui Serviços vinculados',
            'TOWER_HAS_ENGINEERING_ACTIVITIES' : 'Torre possui Atividades cadastradas no Planejamento (Engenharia). Para remover a torre, é necessário antes remover suas atividades',
            'FLOOR_HAS_ENGINEERING_ACTIVITIES' : 'Pavimento possui Atividades cadastradas no Planejamento (Engenharia). Para remover o pavimento, é necessário antes remover suas atividades',
            'UNITY_HAS_ENGINEERING_ACTIVITIES' : 'Unidade possui Atividades cadastradas no Planejamento (Engenharia). Para remover a unidade, é necessário antes remover suas atividades',
            'NFE-KEY-NOT-FOUND' : 'Nota Fiscal não encontrada a partir da Chave digitada',
            'ENGINEERING_BUDGETING_HAS_NO_BASELINES' : 'Orçamento não possuí nenhuma Linha Base ou Programada salva',
            'ENGINEERING_METERING_ACTIVITY_HAS_INITIATED_METERING' : 'Uma ou mais das Atividades selecionadas já possui Medição Iniciada',
            'ENGINEERING_ACTIVITY_START_DATE_IS_BEFORE_DEPENDENCY' : 'Data de Início não cumpre o requisito de uma ou mais dependências',
            'ENGINEERING_SERVICE_HAS_ACTIVITY' : 'Uma ou mais das atividades selecionadas já possue(m) vínculo com este Serviço',
            'ENGINEERING_SERVICE_HAS_ACTIVITY_DEPENDENCY' : 'Uma ou mais das atividades selecionadas já possue(m) vínculo com um dos Serviços de dependência',
            'ENGINEERING_SERVICE_HAS_ACTIVITIES' : 'Existem atividades vinculadas à este Serviço',
            'COMPANY_ALREADY_EXISTS' : 'Já existe uma Imobiliária com este CNPJ',
            'ENGINEERING_ACTIVITY_START_DATE_IS_OFF_DATE' : 'Data Inicial precisa ser dia útil',
            'ENGINEERING_ACTIVITY_HAS_METERINGS' : 'Atividade vinculada à uma ou mais medições',
            'ENGINEERING_ACTIVITY_HAS_PAYMENTS' : 'Atividade vinculada à um ou mais pagamentos',
            'NO-ROLE-ASSIGNED' : 'Não existe perfil vinculado ao seu usuário para esta Empresa',
            'COMPOSITION_COMPOSITION_EXISTS' : 'Composição faz parte de uma ou mais Composições',
            'COMPOSITION_INPUT_EXISTS' : 'Insumo faz parte de uma ou mais Composições',
            'SECTION_INPUT_EXISTS' : 'Insumo faz parte de uma ou mais Etapas de Orçamento(s)',
            'SECTION_COMPOSITION_EXISTS' : 'Composição faz parte de uma ou mais Etapas de Orçamento(s)',
            'INPUT_CODE_ALREADY_EXISTS' : 'Código do Insumo já existe',
            'COMPOSITION_CODE_ALREADY_EXISTS' : 'Código da Composição já existe',
            'LEAD_IN_ATTENDANCE_BY_DIFFERENT_REALTOR' : 'Operação não efetuada: em atendimento por outro corretor',
            'LEAD_HAS_ACTIVE_UNITY_HISTORIES' : 'Lead não pode ser redistribuido pois possui uma negociação em andamento.',
            'TIMEOUT' : 'Tempo excedido. Tente novamente',
            'UNEXPECTED_ERROR' : 'Ocorreu um erro inesperado',
            'SERVICE_NOT_FOUND' : 'Serviço não encontrado',
            'OUT_OF_RANGE' : 'Campo fora do limite',
            'MISSING_REQUIRED_FIELD' : 'Campo obrigatório',
            'INVALID_CREDENTIALS' : 'E-mail e/ou senha inválido(s)',
            'POSTAL_CODE_NOT_FOUND' : 'CEP não encontrado',
            'USER_ALREADY_EXISTS' : 'Usuário já cadastrado',
            'USER_INVALID_PRIMARY_DOCUMENT' : 'CPF inválido para este usuário',
            'STATUS_NOT_RESERVED_IN_HIRING' : 'Unidade deve estar reservada ou em contratação',
            'OPEN_OFFER' : 'Já existe uma proposta em análise',
            'STATUS_NOT_OPEN' : 'Proposta não está em aberto',
            'NOT_FIRST_ACCESS' : 'Primeiro acesso já registrado',
            'EMAIL_NOT_CONFIRMED' : 'E-mail não confirmado',
            'CAMPAIGN_EXISTS_ON_SELECTED_DATES' : 'Já existe uma campanha para o período selecionado',
            'CAMPAIGN_START_DATE_BEFORE_TODAY' : 'Não pode ser antes de hoje',
            'CAMPAIGN_START_DATE_BEFORE_END_DATE' : 'Início não pode ser antes que Fim',
            'RACE_EXISTS_ON_SELECTED_DATES' : 'Já existe uma corrida para o período selecionado',
            'RACE_START_DATE_BEFORE_TODAY' : 'Não pode ser antes de hoje',
            'RACE_START_DATE_BEFORE_END_DATE' : 'Início não pode ser antes que Fim',
            'MILEAGE_RESCUE_WITH_NOT_ENOUGH_BALANCE' : 'SALDO INSUFICIENTE',
            'MILEAGE_PRODUCT_OUT_OF_STOCK' : 'Produto sem estoque suficiente para resgate',
            'CUSTOMER_ALREADY_EXISTS' : 'Cliente com este CPF já existe',
            'UNITY_TYPE_HAS_UNITIES' : 'Este tipo de unidade possui unidades cadastradas',
            'COMPANY_HAS_USERS' : 'Esta empresa possui usuários cadastrados',
            'TOWER_HAS_FLOORS' : 'Esta torre possui pavimentos cadastrados',
            'FLOOR_HAS_UNITIES' : 'Este pavimento possui unidades cadastradas',
            'ROLE_HAS_USERS' : 'Este perfil possui usuários cadastrados',
            'MODULE_HAS_TOWERS' : 'Este módulo possui torres cadastradas',
            'INVALID_EMAIL' : 'Email não cadastrado',
            'RECONDILED_VALUE_GREATHER_THAN_TRANSACTION_AMOUNT' : 'Valor excede valor total da transação',
            'RECONDILED_VALUE_GREATHER_THAN_COMMISSION_VALUE' : 'Valor excede valor total da comissão',
            'SELECTED_BANK_ACCOUNT_DIFFERENT_THAN_FILE' : 'Arquivo não corresponde à conta selecionada',
            'SELECTED_BANK_ACCOUNT_MISSING_BANK_ACCOUNT_ID' : 'Conta selecionada não configurada. Contate a equipe de suporte da Integ Platform.',
            'CATEGORY_HAS_PAYMENTS' : 'A categoria possui pagamentos ativos',
            'CATEGORY_HAS_CHILDREN' : 'A categoria possui outras categorias ativas vinculadas',
            'RECONCILED_VALUE_GREATER_THAN_EXCHANGE_VALUE' : 'Valor conciliado é maior que o valor da Entrada',
            'PERSONALIZATION_DEADLINE_EXPIRED' : 'Data limite expirada',
            'PERSONALIZATION_ALREADY_EXISTS' : 'Personalização já existente na unidade',
            'FILE_HAS_NO_TRANSACTIONS' : 'Arquivo não contém nenhuma transação',
            'OUTCOMING_PAYMENT_HAS_RECONCILED_BANK_STATEMENTS' : 'Pagamento possui conciliações ativas',
            'DEVELOPMENT_MAX_PARTICIPATION_REACHED' : 'Participação não pode ultrapassar os 100%',
            'BANK_LOAN_HAS_RECONCILED_BANK_STATEMENTS' : 'Empréstimo possui pagamentos conciliados',
            'CUSTOMER_HAS_NO_ADDRESS' : 'É necessário configurar o Endereço do cliente para gerar o Boleto',
            'DISCOUNT_EXCEEDS_MAXIMUM' : 'Desconto excede máximo permitido para seu Perfil',
            'USER_CUSTOMER_INVALID_PRIMARY_DOCUMENT' : 'CPF do Usuário e Cliente devem ser o mesmo',
            'EMPLOYEE_ALREADY_EXISTS' : 'CPF do Colaborador Já Cadastrado',
            'SUPPLY_REQUEST_HAS_ORDERS' : 'Solicitação vinculada à Ordem de Compra ativa',
            'INVOICE_HAS_RECONCILED_OUTCOMING_PAYMENTS' : 'A Nota Fiscal possui pagamentos conciliados',
            'ENGINEERING_RECEIPT_HAS_VALIDATED_PAYMENT' : 'Este pagamento já foi validado pelo módulo Financeiro. Para cancelar é necessário antes remover a validação',
            'SUPPLY_LABOR_HAS_RECEIPTS' : 'A ordem de serviço possui pagamentos ativos.',
            'INVOICE_HAS_ACTIVE_PURCHASES' : 'A nota fiscal possui entradas ativas',
            'INVOICE_HAS_DIFFERENT_SUPPLIER' : 'Fornecedor da nota é diferente do fornecedor da conta a pagar',
            'SUPPLIER_HAS_ALLOCATED_EMPLOYEES' : 'O Fornecedor Possui Colaboradores Alocados no Empreendimento.',
            'EMPLOYEE_HAS_ENGINEERING_SERVICE' : 'O Coloborador está alocado em um serviço. Remova a alocação',
            'USER_EMAIL_ALREADY_EXISTS' : 'Já existe um usuário ativo com este email',
            'EMPLOYEE_HAS_ALLOCATED_DEVELOPMENTS' : 'O colaborador está alocado em um empreendimento. Remova a alocação',
            'SUPPLY_LABOR_HAS_MEASURES' : 'O contrato de serviço possui medições ativas',
            'ENGINEERING_ACTIVITY_REACHED_TOTAL_PROGRESS' : 'Atividade não pode ser alterada após sua conclusão',
            'SUPPLY_LABOR_ORDER_HAS_PAYMENTS' : 'O contrato possui pagamentos ativos',
            'SUPPLY_LABOR_ORDER_HAS_METERINGS' : 'O contrato possui medições ativas',
            'SUPPLY_BUDGET_HAS_INVALID_STATUS' : 'Existem movimentações nas cotaçôes!'
        },
        'NAV': {
            'ENGINEERING-RETENTION' : {
                'TITLE' : 'Retenções'
            },
            'ENGINEERING-BULLETIN' : {
                'TITLE' : 'Gestão de Pleitos'
            },
            'ENGINEERING-BULLETIN-LIST' : {
                'TITLE' : 'Boletins'
            },
            'ENGINEERING' : {
                'RESTRICTIONS' : {
                    'TITLE' : 'Restrições',
                    'MANAGE' : 'Restrições',
                    'RESTRICTIONS' : 'Cadastro'
                }
            },
            'DEACTIVATE' : {
                'TITLE' : 'Excluir Conta'
            },
            'LGPD' : {
                'TERM' : 'Termo de Uso'
            },
            'CONFIGURATIONS': 'Configurações',
            'BIRTHDAY' : 'Aniversariantes',
            'HOME-GROUP' : 'Home',
            'DEVELOPMENT-GROUP' : 'Empreendimento',
            'SALES-GROUP' : 'Vendas',
            'MILEAGE-GROUP' : 'Milhas',
            'FINANCIAL-GROUP' : 'Financeiro',
            'REPORTS' : 'Relatórios',
            'SUPPLY' : {
                'SERVICES' : 'Serviços',
                'INVENTORY' : 'Insumos'
            },
            'FINANCIAL' : {
                'OPERATIONS' : 'Operações'
            },
            'FPS' : {
                'TITLE' : 'Físico/Financeiro'
            },
            'SCENARIOS' : {
                'TITLE' : 'Cenários'
            },
            'S-CURVE' : {
                'TITLE' : 'Curva S'
            },
            'ACCOUNTING-GROUP' : {
                'TITLE' : 'Contabilidade'
            },
            'ACCOUNTING-STATEMENT' : {
                'TITLE' : 'Lançamentos Contábeis'
            },
            'PROPERTY' : {
                'TITLE': 'Imobilizado'
            },
            'HUMAN-RESOURCES-GROUP' : 'RH',
            'HELP' : {
                'TITLE' : 'Ajuda'
            },
            'ENGINEERING-SUPPLIER' : {
                'TITLE' : 'Pagamentos'
            },
            'PLANNING' : {
                'TITLE' : 'Planejamento'
            },
            'METERING' : {
                'TITLE' : 'Gestão de Avanço Físico'
            },
            'METERING-PLANNING' : {
                'TITLE' : 'Planejamento de Avanço Físico'
            },
            'ENGINEERING-SERVICE' : {
                'TITLE' : 'Serviços'
            },
            'PROJECT' : {
                'TITLE' : 'Projeto'
            },
            'INPUT' : {
                'TITLE' : 'Insumos'
            },
            'BUDGETING' : {
                'TITLE' : 'Orçamentos'
            },
            'COMPOSITION' : {
                'TITLE' : 'Composições'
            },
            'ENGINEERING-GROUP' : 'Engenharia',
            'ATTENDANCE' : {
                'TITLE' : 'Atendimento'
            },
            'BANK-LOAN' : {
                'TITLE' : 'Empréstimos'
            },
            'BANK-INVESTMENT' : {
                  'TITLE' : 'Aplicações'
            },
            'FINANCIAL-STATEMENT' : {
                'TITLE' : 'Extrato'
            },
            'FINANCIAL-HOME' : {
                'TITLE' : 'Contas Bancárias'
            },
            'FINANCIAL-OUTCOMING' : {
                'TITLE' : 'Contas a Pagar'
            },
            'EXCHANGES' : {
                'TITLE' : 'Permuta/Estoque/Imobilizado'
            },
            'SELL-CANCELLATIONS' : {
                'TITLE' : 'Distratos'
            },
            'INCOMING-PAYMENTS' : {
                'TITLE' : 'Outros Recebimentos'
            },
            'BANK-BILLS' : {
                'TITLE' : 'Boletos'
            },
            'FINANCIAL-INCOMING' : {
                'TITLE' : 'Contas a Receber'
            },
            'HUMAN-RESOURCES-HOME' : {
                'TITLE' : 'Home'
            },                        
            'HR-HOME' : {
                'TITLE' : 'Home'
            },            
            'HR-EMPLOYEE' : {
                'TITLE' : 'Colaboradores'
            },     
            'HR-OCCUPATION' : {
                'TITLE' : 'Cargos'
            },           
            'HR-SUPPLIERS' : {
                'TITLE' : 'Terceirizados'
            },                                    
            'HR-CONFIG' : {
                'TITLE' : 'Configurações'
            },                                    
            'CUSTOMERS' : {
                'TITLE' : 'Clientes'
            },
            'LEAD-GROUP' : {
                'TITLE' : 'Clientes'
            },
            'LEAD-UNQUALIFIED' : { 
                'TITLE' : 'Inativos'
            },
            'LEAD-STANDBY' : {
                'TITLE' : 'Atendimento Futuro'
            },
            'LEAD-CONTACT' : {
                'TITLE' : 'Contatos'
            },
            'LEADS' : {
                'TITLE' : 'Gestão de Clientes'
            },
            'MILEAGE' : {
                'TITLE' : 'Programa de Recompensas',
                'ADMIN-TITLE' : 'Administração',
                'STATEMENT': 'Extrato',
                'RACES': 'Corridas',
                'RESCUE': 'Resgate'
            },
            'MEDIA' : {
                'TITLE' : 'Mídia'
            },
            'NOTIFICATION' : {
                'TITLE' : 'Notificações'
            },
            'DOCUMENTS' : {
                'TITLE' : 'Documentos'
            },
            'PERS.-UNITIES' : {
                'TITLE' : 'Personalizações'
            },
            'SALES'    : {
                'TITLE' : 'Vendas'
            },
            'TOWER'    : {
                'TITLE' : 'Torres'
            },
            'UNITY-TYPE' : {
                'TITLE' : 'Tipos de Unidade'
            },
            'FLOOR' : {
                'TITLE' : 'Pavimentos'
            },
            'UNITY' : {
                'TITLE' : 'Unidades'
            },
            'PARKING-SPOT' : {
                'TITLE' : 'Vagas de Garagem'
            },
            'SUPPLIER' : {
                'TITLE' : 'Terceiros'
            },
            'COMPANY'        : {
                'TITLE': 'Imobiliárias',
                'BADGE': ''
            },
            'REAL-ESTATE'        : {
                'TITLE': 'Imobiliárias',
                'BADGE': ''
            },
            'USER'        : {
                'TITLE': 'Usuários'
            },
            'ROLE'        : {
                'TITLE': 'Perfis de Acesso'
            },
            'CUSTOMER-UNITY-LIST' : {
                'TITLE': 'Cliente -> Minhas Unidades'
            },
            'USER-COMMISSION-LIST' : {
                'TITLE': 'Minhas Comissões'
            },
            'UNITY-LIST' : {
                'TITLE': 'Corretor -> Minhas Unidades'
            },
            'COMPANY-UNITY-LIST' : {
                'TITLE': 'Unidades da Imobiliária'
            },
            'FINANCINGINSTALLMENT' : {
                'TITLE': 'Recebimentos'
            },
            'ADMIN-UNITY-LIST' : {
                 'TITLE': 'Unidades' 
            },
            'PARKINGSPOT-REPORT' : {
                 'TITLE': 'Vagas de Garagem' 
            },
            'DEVELOPMENT-COMPANY' : {
                'TITLE' : 'Empresas'
            },
            'DEVELOPMENT' : {
                'TITLE': 'Empreendimentos'
            },
            'CUSTOMER-DEVELOPMENT': {
                'TITLE' : 'Interesses',
            },
            'AVAILABLE-UNITIES' : {
                'TITLE': 'Unidades Disponíveis'
            },
            'CATEGORY-PREVISION' : {
                 'TITLE' : 'Previsão Orçamentária'
            },
            'CATEGORY-REPORT' : {
                  'TITLE' : 'Previsão Orçamentária'
            },
            'CATEGORY-BUDGETING-REPORT' : {
                  'TITLE' : 'Previsão Orçamentária - NOVO'
            },
            'CATEGORY-ANALYTICS' : {
                  'TITLE' : 'Previsão Analítica'
            },
            'INCOMING-REPORT' : {
                  'TITLE' : 'Contas Recebidas'
            },
            'OPEN-INCOMING-REPORT' : {
                  'TITLE' : 'Contas a Receber'
            },
            'PAYMENT-REPORT' : {
                  'TITLE' : 'Contas a Pagar'
            },
            'PAYMENT-REPORT-TO-PAY' : {
                'TITLE' : 'Contas Pagas'
            },
            'PERSONALIZATION' : {
                'TITLE': 'Personalizações'
            },
            'FINANCIAL-CHART' : {
                 'TITLE' : 'Fluxo de Caixa'
            },
            'COMMISSION-REPORT' : {
                  'TITLE' : 'Comissões'
            },
            'OVERDUE-REPORT' : {
                'TITLE' : 'Inadimplência'
            },
            'DIMOB-REPORT' : {
                  'TITLE' : 'Dimob'
            },
            'CAIXA-REPORT' : {
                  'TITLE' : 'Relatório Caixa'
            },
            'BALANCE-REPORT' : {
                  'TITLE' : 'Balanço'
            },
            'FORESEEN-REPORT' : {
                  'TITLE' : 'Previsto/Realizado'
            },
            'BANK-UNITIES' : {
                  'TITLE' : 'Financiamento Bancário'
            },
            'UNITY-REGISTRY' : {
                  'TITLE' : 'Registro de Unidades'
            },
            'TOTALS-REPORT' : {
                  'TITLE' : 'Totais'
            },
            'EMPLOYEE-RECORDS' : {
                  'TITLE' : 'Controle de Acesso'
            },
            'UNITY-PRICE-HISTORY' : {
                  'TITLE' : 'Variação de Tabela'
            },
            'EMPLOYEE-PRESENCE-REPORT' : {
                'TITLE' : 'Presença na Obra'
            },      
            'SUPPLY-GROUP' :{
                'TITLE' : 'Suprimentos'
            },
            'SUPPLY-REQUEST' : {
                'TITLE' : 'Solicitações de Compra'
            },
            'SUPPLY-BUDGET' : {
                'TITLE' : 'Cotações'
            },
            'SUPPLY-ORDER' : {
                'TITLE' : 'Ordens de Compra'
            },
            'SUPPLY-INVOICE' : {
                'TITLE' : 'Notas Fiscais'
            },
            'SUPPLY-LABOR' : {
                'TITLE' : 'Contrato de Serviço'
            },
            'SUPPLY-PURCHASE' : {
                'TITLE' : 'Entrada de Insumos'
            },
            'SUPPLY-RENT' : {
                'TITLE' : 'Locação de Equipamentos'
            },      
            'SUPPLY-WITHDRAWAL' : {
                'TITLE' : 'Retirada de Insumos'
            },
            'SUPPLY-INVENTORY' : {
                'TITLE' : 'Estoque'
            },
            'SUPPLY-LABOR-ORDERS' : {
                'TITLE' : 'Contrato Simplificado'
            },
            'CAIXA-V2-REPORT' : {
                'TITLE' : 'Relatório Caixa - NOVO' 
            },
            'EMPLOYEE-REQUEST' : {
                'TITLE' : 'Solicitação de Mão de Obra' 
            },
            'EMPLOYEE-ENGINEERING-SERVICE' : {
                'TITLE' : 'Alocação de Serviço'
            },
            'EMPLOYEE-EFFECTIVE-REPORT' : {
                'TITLE' : 'Relatório de Efetividade'
            },
            'FINANCIAL-OPERATIONS-REPORT' : {
                'TITLE' : 'Operações Financeiras'
            },
            'GENERAL'        : {
                'TITLE': 'Geral',
                'BADGE': ''
            },
            'HOME'        : {
                'TITLE': 'Home',
                'BADGE': '25'
            },
            'POST-CONSTRUCTION-GROUP'   : {
                'TITLE': 'Pós-Obra',
            },
            'POST-CONSTRUCTION-HOME'    : {
                'TITLE': 'Home'
            },
            'POST-CONSTRUCTION-TICKETS'    : {
                'TITLE': 'Chamados'
            },
            'POST-CONSTRUCTION-CALENDAR'    : {
                'TITLE': 'Calendário'
            },
            'POST-CONSTRUCTION-TICKETS-CUSTOMER'    : {
                'TITLE': 'Chamados - Cliente'
            },
            'POST-CONSTRUCTION-APPOINTMENT'    : {
                'TITLE': 'Agenda'
            },
            'POST-CONSTRUCTION-MAINTENANCES'    : {
                'TITLE': 'Manutenções'
            },
            'POST-CONSTRUCTION-WARRANTIES'    : {
                'TITLE': 'Prazos de Garantia'
            },
            'POST-CONSTRUCTION-MATERIALS'    : {
                'TITLE': 'Materiais e Serviços'
            },
            'POST-CONSTRUCTION-SETTINGS' : {
                'TITLE': 'Configurações'
            },
            'PC-TICKET-REPORT' : {
                'TITLE': 'Relatório de Chamados'
            }
        }
    }
};
